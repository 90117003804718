import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import { QuestionAttributeDao } from '../../../leaf/model/QuestionAttributeDao';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import PaddingComponent from './PaddingComponent';
import RadiusComponent from './RadiusComponent';

export default function QuestionBlockBuildBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, updateValue, updatePadding }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; updateValue?: QuestionAttributeDao; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        if (updateValue) {
            updateQuestionField(updateValue.question);
            updateAnswerField(updateValue.answer);
            setColorState(updateValue.color);
        } else {
            updateQuestionField('');
            updateAnswerField('');
            setColorState('#000000');
        }
    }, [updateValue]);

    // question text
    const questionFieldRef = useRef('');
    const updateQuestionField = (newValue: string) => { questionFieldRef.current = newValue; };

    // answer text
    const answerFieldRef = useRef('');
    const updateAnswerField = (newValue: string) => { answerFieldRef.current = newValue; };

    // color
    const [colorState, setColorState] = useState('#000000');

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    // radius
    const radiusField = useRef('');
    const onRadiusChange = (v: string) => { radiusField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!questionFieldRef || questionFieldRef.current.length === 0 || !answerFieldRef || answerFieldRef.current.length === 0) {
                toast("Заполните все поля", { type: "error" });
                return;
            }

            saveDirectlyLoading('question', JSON.stringify({ "question": questionFieldRef.current, "answer": answerFieldRef.current, "color": colorState, "radius": radiusField.current }), paddingField.current);
            updateQuestionField('');
            updateAnswerField('');
            setColorState('#000000');
        }} title={'Вопрос и ответ'}>
            <StandartTextFieldWrapper
                placeholder="Введите вопрос"
                handleTextFieldValueChange={updateQuestionField}
                initText={questionFieldRef && questionFieldRef.current.length > 0 ? questionFieldRef.current : null}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Введите ответ"
                handleTextFieldValueChange={updateAnswerField}
                initText={answerFieldRef.current && answerFieldRef.current.length > 0 ? answerFieldRef.current : null}
                multiline
                rows={"3"}
                maxLength={500}
            />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Текст" color={colorState} onSave={setColorState} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
                <RadiusComponent init={updateValue ? updateValue.radius ?? undefined : undefined} onSave={onRadiusChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};
