import TemplateConfig from "../../../config/TemplateConfig";

const WhatsappIcon = ({ background = TemplateConfig.blackColor, color = TemplateConfig.whiteColor }: { background?: string; color?: string }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill={background} />
            <path d="M10 30L11.4058 24.8642C10.5383 23.3608 10.0825 21.6567 10.0833 19.9092C10.0858 14.4458 14.5317 10 19.9942 10C22.645 10.0008 25.1333 11.0333 27.005 12.9067C28.8758 14.78 29.9058 17.27 29.905 19.9183C29.9025 25.3825 25.4567 29.8283 19.9942 29.8283C18.3358 29.8275 16.7017 29.4117 15.2542 28.6217L10 30ZM15.4975 26.8275C16.8942 27.6567 18.2275 28.1533 19.9908 28.1542C24.5308 28.1542 28.2292 24.4592 28.2317 19.9167C28.2333 15.365 24.5525 11.675 19.9975 11.6733C15.4542 11.6733 11.7583 15.3683 11.7567 19.91C11.7558 21.7642 12.2992 23.1525 13.2117 24.605L12.3792 27.645L15.4975 26.8275ZM24.9867 22.2742C24.925 22.1708 24.76 22.1092 24.5117 21.985C24.2642 21.8608 23.0467 21.2617 22.8192 21.1792C22.5925 21.0967 22.4275 21.055 22.2617 21.3033C22.0967 21.5508 21.6217 22.1092 21.4775 22.2742C21.3333 22.4392 21.1883 22.46 20.9408 22.3358C20.6933 22.2117 19.895 21.9508 18.9492 21.1067C18.2133 20.45 17.7158 19.6392 17.5717 19.3908C17.4275 19.1433 17.5567 19.0092 17.68 18.8858C17.7917 18.775 17.9275 18.5967 18.0517 18.4517C18.1775 18.3083 18.2183 18.205 18.3017 18.0392C18.3842 17.8742 18.3433 17.7292 18.2808 17.605C18.2183 17.4817 17.7233 16.2625 17.5175 15.7667C17.3158 15.2842 17.1117 15.3492 16.96 15.3417L16.485 15.3333C16.32 15.3333 16.0517 15.395 15.825 15.6433C15.5983 15.8917 14.9583 16.49 14.9583 17.7092C14.9583 18.9283 15.8458 20.1058 15.9692 20.2708C16.0933 20.4358 17.715 22.9375 20.1992 24.01C20.79 24.265 21.2517 24.4175 21.6108 24.5317C22.2042 24.72 22.7442 24.6933 23.1708 24.63C23.6467 24.5592 24.6358 24.0308 24.8425 23.4525C25.0492 22.8733 25.0492 22.3775 24.9867 22.2742Z" fill={color} />
        </svg>
    );
}

export default WhatsappIcon;