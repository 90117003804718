import { TextField, InputAdornment, InputLabel } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";

const StandartTextField = ({ placeholder, rows = '1', maxLength = 50, endAdornment, startAdornment, onChange, text, multiline = false, password = false, radius, color }: { placeholder: string; rows?: string; maxLength?: number; endAdornment?: any; startAdornment?: any; onChange: any; text: any; multiline?: boolean; password?: boolean; radius?: number; color?: string }) => {
    return (
        <TextField
            type={password ? 'password' : 'text'}
            placeholder={placeholder}
            onChange={onChange}
            value={text}
            fullWidth
            multiline={multiline}
            rows={rows}
            variant="standard"
            style={{ fontWeight: TemplateConfig.regularText }}
            InputProps={{
                disableUnderline: true,
                sx: { borderRadius: radius !== undefined ? (radius === 0 ? 0 : radius / 4) : 4, backgroundColor: TemplateConfig.inputBackgroundColor, color: color ?? TemplateConfig.blackColor },
                endAdornment: endAdornment && <div style={{ paddingRight: 16 }}>{endAdornment}</div>,
                startAdornment: startAdornment
                    ? (<InputAdornment style={{ marginRight: '-15px', marginBottom: 0, paddingLeft: 15 }} position="start" component="div" disablePointerEvents>
                        <InputLabel>{startAdornment}</InputLabel>
                    </InputAdornment>)
                    : null,
            }}
            inputProps={{ maxLength: maxLength, sx: { '&::placeholder': { color: color ?? TemplateConfig.blackColor } }, style: { padding: '12.5px 16px', fontWeight: TemplateConfig.regularText, fontSize: TemplateConfig.mediumFontSize } }}
        />
    );
}

export default StandartTextField;