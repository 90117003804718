import ApplicationConfig from "../../../core/config/ApplicationConfig";
import TemplateConfig from "../../../core/config/TemplateConfig";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { ProfileAttributeDao } from "../model/ProfileAttributeDao";

export default function ProfileAttributeComponent({ item, pageId, attributeId }: { item: ProfileAttributeDao; pageId: string; attributeId: string; }) {
    return (
        <div
            style={{
                background: item.background && item.background.length > 1 ? `url(${ApplicationConfig.thisProductBackendImagesPath}/${pageId}/${attributeId}/${item.background})` : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '235px',
                borderRadius: item.radius ? Number(item.radius) : TemplateConfig.borderRadius,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 0,
            }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                boxSizing: 'border-box',
            }} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 15, paddingRight: 15 }}>
                {
                    item.avatar && item.avatar.length > 0
                        ? <div>
                            <div
                                style={{
                                    width: '120px',
                                    height: '120px',
                                    borderRadius: '50%',
                                    border: '2px solid white',
                                    backgroundImage: `url(${ApplicationConfig.thisProductBackendImagesPath}/${pageId}/${attributeId}/${item.avatar})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                            <SimpleRetirementComponent size={10} />
                        </div>
                        : null
                }

                <div style={{ textAlign: 'center' }}>
                    <SimpleTextComponent
                        multiline
                        lines={1}
                        type='whiteTitle'
                        anotherColor={item.color ?? undefined}
                    >
                        {item.title}
                    </SimpleTextComponent>
                    <SimpleRetirementComponent size={10} />
                    <SimpleTextComponent
                        multiline
                        lines={3}
                        type='whiteDefault'
                        anotherColor={item.color ?? undefined}
                    >
                        {item.description}
                    </SimpleTextComponent>
                </div>
            </div>
            <div></div>
        </div>
    );
}