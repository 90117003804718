import TickTrueIcon from '../../../../core/assets/module/customize/tick-circle-true.svg';
import TickFalseIcon from '../../../../core/assets/module/customize/tick-circle-false.svg';
import SharedListComponent from '../../../shared/ui/SharedListComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';

const TextBlockListElementsComponent = ({ activeItem, items, onSave, open, onDismiss, title }: { activeItem: string; items: string[]; onSave: any; open: boolean; onDismiss: any; title: string }) => {
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={title}>
            <SharedListComponent
                items={
                    items.map((field) => {
                        return {
                            label: field,
                            icon: activeItem === field ? TickTrueIcon : TickFalseIcon,
                            onClick: () => onSave(field)
                        };
                    })
                }
            />
        </StandartBottomSheetComponent>
    )
}

export default TextBlockListElementsComponent;