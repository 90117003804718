import { useRef, useState } from "react";
import { toast } from "react-toastify";
import TemplateConfig from "../../../core/config/TemplateConfig";
import { Activity } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { AppointmentAttributeDao } from "../model/AppointmentAttributeDao";
import RegisterAppointmentService from "../service/RegisterAppointmentService";
import AppointmentContactOptionComponent from "./AppointmentContactOptionComponent";
import AppointmentDateOptionComponent from "./AppointmentDateOptionComponent";
import AppointmentLinkOptionComponent from "./AppointmentLinkOptionComponent";

const AppointmentAttributeComponent = ({ pageId, appointmentData, item, isCustomize = false }: { pageId: string; appointmentData: Activity | null; item: AppointmentAttributeDao; isCustomize?: boolean }) => {
    // appointment data reload
    const [localAppointmentData, setLocalAppointmentData] = useState(appointmentData);
    function reloadAppointmentData() {
        if (!isCustomize) return;
        if (!pagesDataStore.pagesData) return;
        const foundPage = pagesDataStore.pagesData.find(page => page.page_id === pageId);
        if (!foundPage) return;
        const appointment = foundPage.activities.find(actv => actv.type === 'appointment');
        if (!appointment) return;
        setLocalAppointmentData(appointment);
        setActiveLink('');
    }

    // OPTION LINK 
    const [linkOption, setLinkOption] = useState(false);
    const [activeLink, setActiveLink] = useState('');
    function onAppoint() {
        if (!localAppointmentData) {
            toast("Функция записи недоступна", { type: "error" });
            return;
        }
        setLinkOption(true);
    }
    function handleChangeLink(linkId: string) {
        setLinkOption(false);
        setActiveLink(linkId);
        setDateOption(true);
    };

    // OPTION DATE
    const [dateOption, setDateOption] = useState(false);
    const [dateTime, setDateTime] = useState('');
    const [orderTime, setOrderTime] = useState('');
    function handleChangeDate(time: string, orderTime: string) {
        setDateOption(false);
        setDateTime(time);
        setOrderTime(orderTime);
        setContactOption(true);
    }

    // OPTION CONTACT
    const [contactOption, setContactOption] = useState(false);
    const [resetState, setResetState] = useState(1);
    const nameFieldRef = useRef('');
    const telephoneFieldRef = useRef('');
    const handleNameChange = (newValue: string) => { nameFieldRef.current = newValue; };
    const handleTelephoneChange = (newValue: string) => { telephoneFieldRef.current = newValue; };
    async function onRegister() {
        if (!nameFieldRef.current || nameFieldRef.current.length === 0 || !telephoneFieldRef.current || telephoneFieldRef.current.length === 0) {
            toast("Нужно заполнить контактные данные", { type: "error" });
            return;
        }

        setContactOption(false);
        await RegisterAppointmentService({ linkId: activeLink, pageId: pageId, time: dateTime, orderTime: orderTime, name: nameFieldRef.current, telephone: telephoneFieldRef.current });
        setResetState(resetState + 1);
    }


    return (
        <div>
            <RaisedButtonComponent
                backgroundColor={item.background ?? TemplateConfig.blackColor}
                placeholderColor={item.color ?? TemplateConfig.whiteColor}
                placeholder={item.placeholder}
                onClick={() => {
                    onAppoint();
                    reloadAppointmentData();
                }}
            />
            <AppointmentLinkOptionComponent pageId={pageId} activeLink={activeLink} setActiveLink={setActiveLink} open={linkOption} onDismiss={() => setLinkOption(false)} handleChangeLink={handleChangeLink} appointmentData={localAppointmentData!} />
            <AppointmentDateOptionComponent appointmentData={localAppointmentData!} open={dateOption} onDismiss={() => setDateOption(false)} activeLink={activeLink} pageId={pageId} handleChangeDate={handleChangeDate} resetState={resetState} />
            <AppointmentContactOptionComponent open={contactOption} onDismiss={() => setContactOption(false)} onSubmit={onRegister} onName={handleNameChange} onTelephone={handleTelephoneChange} />
        </div>
    );
}

export default AppointmentAttributeComponent;