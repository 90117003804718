import { AudioAttributeDao } from "../model/AudioAttributeDao";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import PlayIcon from "../../../core/assets/module/leaf/PlayIcon";
import PauseIcon from "../../../core/assets/module/leaf/PauseIcon";
import RandomStringUtil from "../../../core/utils/RandomStringUtil";

export default function AudioAttributeComponent({ item, pageId, attributeId }: { item: AudioAttributeDao; pageId: string; attributeId: string; }) {
    const itemId = RandomStringUtil(12);

    return (
        <>
            <style>
                {`
                    .rhap_container {
                        background-color: transparent;
                        box-shadow: none;
                        padding: 0px;
                    }
                    .rhap_additional-controls, .rhap_volume-controls {
                        display: none;
                    }
                    .rhap_controls-section {
                        flex: none;
                    }
                    .${itemId} .rhap_time {
                        color: ${item.color};
                        font-weight: 500;
                    }
                    .${itemId} .rhap_progress-indicator {
                        background: ${item.color};
                        box-shadow: none;
                    }
                    .${itemId} .rhap_progress-filled {
                        background: ${item.color};  
                    }
                    .${itemId} .rhap_download-progress {
                        background: ${item.color};
                        opacity: 0.4;
                    }
                    .rhap_time {
                        display: none;
                    }
                `}
            </style>
            <div className={itemId}>
                <AudioPlayer
                    layout='horizontal-reverse'
                    showJumpControls={false}
                    customIcons={
                        {
                            play: (<PlayIcon color={item.color} />),
                            pause: (<PauseIcon color={item.color} />)
                        }
                    }
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    src={`${ApplicationConfig.thisProductBackendImagesPath}/${pageId}/${attributeId}/${item.path}`}

                />
            </div>
        </>
    );
}