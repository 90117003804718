import { action, makeObservable, observable } from 'mobx';
import { OrdersDataResponse, OrdersFromPageId } from '../model/OrdersDataResponse';

class OrdersDataStore {
    ordersData: OrdersDataResponse = { elements: [] };

    constructor() {
        makeObservable(this, {
            ordersData: observable,
            pushData: action,
            removeData: action,
        });
    }

    pushData(value: OrdersFromPageId) {
        this.ordersData.elements.push(value);
    }

    insertOrderToPage(value: OrdersFromPageId, pageId: string) {
        if (this.ordersData === null) return;
        const updatedOrdersData = this.ordersData.elements.map(item => {
            if (item.pageId === pageId) return {
                pageId: pageId,
                orders: {
                    new: item.orders.new.concat(value.orders.new),
                    "in process": item.orders['in process'].concat(value.orders['in process']),
                    success: item.orders.success.concat(value.orders.success),
                    error: item.orders.error.concat(value.orders.error)
                }
            };
            return item;
        });

        this.ordersData.elements = updatedOrdersData;
    }

    replaceData(pageId: string, value: OrdersFromPageId) {
        if (this.ordersData === null) return;
        const updatedOrdersData = this.ordersData.elements.map(item => {
            if (item.pageId === pageId) return value;
            return item;
        });

        this.ordersData.elements = updatedOrdersData;
    }

    removeData() {
        this.ordersData = { elements: [] };
    }
}

const ordersDataStore = new OrdersDataStore();
export default ordersDataStore;
