import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import GlobalAuthVerifyService from "../module/authenticate/service/GlobalAuthVerifyService";
import TemplateConfig from "./config/TemplateConfig";
import RouteComponent from "./route/RouteComponent";
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from "@react-oauth/google";

export default function App() {
  const [authVerified, setAuthVerified] = useState(false);

  useEffect(() => {
    async function verifyAuth() {
      await GlobalAuthVerifyService();
      setAuthVerified(true);
    }

    verifyAuth();
  }, []);

  const InitLoader = styled('div')({
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '9999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  if (!authVerified) return (
    <InitLoader><CircularProgress /></InitLoader>
  );
  return (
    <HelmetProvider>
      <ThemeProvider theme={TemplateConfig.theme}>
        <GoogleOAuthProvider clientId="92759732782-18aelhn0h3nhrfs4sg003c9bjr88bakc.apps.googleusercontent.com">
          <RouteComponent />
        </GoogleOAuthProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

