import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { PaddingDao } from "../../../leaf/model/PaddingDao";
import SimpleRetirementComponent from "../../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../../shared/ui/StandartTextFieldWrapper";
import TopIcon from '../../../../core/assets/module/attribute/top.svg';
import RightIcon from '../../../../core/assets/module/attribute/right.svg';
import BottomIcon from '../../../../core/assets/module/attribute/bottom.svg';
import LeftIcon from '../../../../core/assets/module/attribute/left.svg';
import { InputAdornment } from "@mui/material";

const PaddingEditComponent = ({ open, onDismiss, onSave, init }: { open: boolean; onDismiss: any; onSave: any; init?: PaddingDao }) => {
    // init
    useEffect(() => {
        if (open) {
            const newValuevalue = init ?? {
                top: "8",
                bottom: "8",
                left: "24",
                right: "24"
            };
            onChangeValue(newValuevalue);
            setReload(!reload);
        }
        // eslint-disable-next-line 
    }, [init, open]);
    const [reload, setReload] = useState(false);
    const value = useRef<PaddingDao>({});
    const onChangeValue = (v: PaddingDao) => { value.current = v; };
    const onTopChangeValue = (v: string) => { value.current.top = v; };
    const onRightChangeValue = (v: string) => { value.current.right = v; };
    const onBottomChangeValue = (v: string) => { value.current.bottom = v; };
    const onLeftChangeValue = (v: string) => { value.current.left = v; };

    // function
    function valueIsCorrect(value: string | undefined) {
        if (!value || value.length < 1 || Number(value) < 0 || Number(value) > 1000) return false;
        return true;
    }

    // widget
    const InputBlock = (init: string | undefined, onChange: any, title: string, icon: string) => {
        return (
            <StandartTextFieldWrapper
                initText={init}
                handleTextFieldValueChange={onChange}
                placeholder={title}
                filter={/[^0-9]/g}
                endAdornment={<InputAdornment position="end">
                    <img style={{ paddingRight: 0 }} src={icon} alt="padding arrow icon" />
                </InputAdornment>}
            />
        );
    }
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!valueIsCorrect(value.current.top) || !valueIsCorrect(value.current.right) || !valueIsCorrect(value.current.bottom) || !valueIsCorrect(value.current.left)) {
                toast("Введите корректные значения", { type: "error" });
                return;
            }

            onSave(JSON.stringify(value.current));
            onChangeValue({});
            onDismiss();
        }} title="Изменить отступ">
            {InputBlock(value.current.top, onTopChangeValue, "Вверх", TopIcon)}
            <SimpleRetirementComponent size={10} />
            {InputBlock(value.current.right, onRightChangeValue, "Справа", RightIcon)}
            <SimpleRetirementComponent size={10} />
            {InputBlock(value.current.bottom, onBottomChangeValue, "Вниз", BottomIcon)}
            <SimpleRetirementComponent size={10} />
            {InputBlock(value.current.left, onLeftChangeValue, "Слева", LeftIcon)}
        </StandartBottomSheetComponent>
    );
}

export default PaddingEditComponent;