import { List, ListItem, ListItemIcon, ListItemSecondaryAction } from "@mui/material";
import SimpleRetirementComponent from "./SimpleRetirementComponent";
import SimpleTextComponent from "./SimpleTextComponent";

const SharedListComponent = ({ items, action = false }: { items: { label: string, icon?: any, onClick: any, action?: any }[]; action?: boolean }) => {
    return (
        <List style={{ padding: 0 }}>
            {
                items.map((field: any, index: number) => (
                    <ListItem key={index} button style={{ paddingLeft: 0 }} onClick={() => field.onClick()}>
                        {
                            field.icon && <>
                                <ListItemIcon style={{ minWidth: 20 }}>
                                    <img src={field.icon} alt="shared list item icon" width={20} />
                                </ListItemIcon>
                                <SimpleRetirementComponent size={16} />
                            </>
                        }
                        <div style={{ maxWidth: 380 }}>
                            <SimpleTextComponent type='hardMedium'>
                                {field.label}
                            </SimpleTextComponent>
                        </div>
                        {
                            action
                                ? <ListItemSecondaryAction style={{ position: 'absolute', right: 0 }}>
                                    {field.action}
                                </ListItemSecondaryAction>
                                : null
                        }
                    </ListItem>
                ))
            }
        </List>
    );
}

export default SharedListComponent;