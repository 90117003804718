import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import AccessTokenVerifyService from "./AccessTokenVerifyService";
export default async function PasswordService(password: string, setPasswordState: any) {
    //check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    //create params
    const params = {
        "type": "account",
        "subType": "updatePassword",
        "accessToken": accessToken,
        "password": password
    };

    //request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (result.status === 'succes') {
        toast("Пароль успешно обновлен", { type: "success" });
        setPasswordState(false);
    }

    return result;
}