import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { PricelistItem } from "../../../leaf/model/PricelistAttributeDao";
import SimpleRetirementComponent from "../../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../../shared/ui/StandartTextFieldWrapper";

const PricelistItemEditComponent = ({ open, onDismiss, onSave, updateValue }: { open: boolean; onDismiss: any; onSave: any; updateValue?: PricelistItem }) => {
    // update value
    useEffect(() => {
        if (updateValue) {
            updateTitleField(updateValue.title);
            updateValueField(updateValue.value ?? '');
        } else {
            updateTitleField('');
            updateValueField('');
        }
    }, [updateValue, open]);

    // title text
    const titleFieldRef = useRef('');
    const updateTitleField = (newValue: string) => { titleFieldRef.current = newValue; };

    // value text
    const valueFieldRef = useRef('');
    const updateValueField = (newValue: string) => { valueFieldRef.current = newValue; };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!titleFieldRef || titleFieldRef.current.length === 0) {
                toast("Заполните заголовок", { type: "error" });
                return;
            }

            onSave({
                title: titleFieldRef.current,
                value: valueFieldRef.current,
                update: updateValue,
                id: updateValue ? updateValue?.id : ''
            });
            updateTitleField('');
            updateValueField('');
            onDismiss();
        }} title={'Поле прайс лист'}>
            <StandartTextFieldWrapper
                placeholder="Введите заголовок"
                handleTextFieldValueChange={updateTitleField}
                initText={titleFieldRef && titleFieldRef.current.length > 0 ? titleFieldRef.current : null}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Введите цену"
                handleTextFieldValueChange={updateValueField}
                initText={valueFieldRef.current && valueFieldRef.current.length > 0 ? valueFieldRef.current : null}
                filter={/[^0-9]/g}
            />
        </StandartBottomSheetComponent>
    );
}

export default PricelistItemEditComponent;