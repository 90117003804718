import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
import EditIcon from '../../../../core/assets/module/configuration/edit.svg';
import DeleteIcon from '../../../../core/assets/module/configuration/delete.svg';
import DuplicateIcon from '../../../../core/assets/module/configuration/duplicate.svg';
import SharedListComponent from "../../../shared/ui/SharedListComponent";

const AppointmentEditMenuComponent = ({ open, onDismiss, onEdit, onDelete, onClone }: { open: boolean; onDismiss: any; onEdit: any; onDelete: any; onClone?: any }) => {
    // init
    const items = [
        { label: "Редактировать", icon: EditIcon, onClick: onEdit },
        onClone && { label: "Дублировать", icon: DuplicateIcon, onClick: onClone },
        { label: "Удалить", icon: DeleteIcon, onClick: onDelete }
    ];

    return (
        <StandartBottomSheetComponent title="Действие" open={open} onDismiss={onDismiss}>
            <SharedListComponent items={items} />
        </StandartBottomSheetComponent>
    )
}

export default AppointmentEditMenuComponent;