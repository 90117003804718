import UrlLauncherUtil from "../../../core/utils/UrlLauncherUtil";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { MenuAttributeDao } from "../model/MenuAttributeDao";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import ArrowDownLocale from "../../../core/assets/module/customize/ArrowDownLocale";

const MenuAttributeComponent = ({ item }: { item: MenuAttributeDao }) => {
    const nickname = window.location.pathname.split('/').pop();
    function onLocaleChange(link: string) {
        UrlLauncherUtil({ url: `${ApplicationConfig.fullApplicationLink}/${link}` });
    }

    const MenuItem = (link: string, name: string) => {
        return (
            <SimpleTextComponent type="titleBold">
                <LinkWrapperComponent onClick={() => UrlLauncherUtil({ url: link })} underline color={item.color}>
                    {name}
                </LinkWrapperComponent>
            </SimpleTextComponent>
        );
    }
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
            {
                item.locale && (item.kz || item.ru || item.en)
                    ? <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <select
                            style={{
                                border: 'none',
                                fontSize: 16,
                                fontWeight: 600,
                                color: item.color,
                                backgroundColor: 'unset'
                            }}
                            onChange={(e: any) => onLocaleChange(e.target.value)}
                            defaultValue={nickname}
                        >
                            {item.kz && <option value={item.kz}>{'KZ'}</option>}
                            {item.ru && <option value={item.ru}>{'RU'}</option>}
                            {item.en && <option value={item.en}>{'EN'}</option>}
                        </select>
                        <ArrowDownLocale color={item.color} />
                    </div>
                    : <div />
            }
            <div style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                {item.link1 && item.name1 && item.link1.length > 0 && item.name1.length > 0 && <div style={{ paddingRight: 16 }}>
                    {MenuItem(item.link1, item.name1)}
                </div>}
                {item.link2 && item.link2.length > 0 && item.name2 && item.name2.length > 0 && MenuItem(item.link2, item.name2)}
            </div>
        </div>
    );
};

export default MenuAttributeComponent;