import { addDays, addMonths, addWeeks, endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek, subDays, subMonths, subWeeks } from "date-fns";
import { useEffect, useState } from "react";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { ru } from 'date-fns/locale';
import ArrowRightIcon from '../../../core/assets/module/configuration/arrow-right-24.svg';
import ArrowLeftIcon from '../../../core/assets/module/configuration/arrow-left-24.svg';
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import { styled } from "@mui/material";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import TemplateConfig from "../../../core/config/TemplateConfig";
import GetAnalyticsDataService from "../service/GetAnalyticsDataService";
import { Analytics } from "../model/AnalyticsDataResponse";
import analyticsDataStore from "../store/AnalyticsDataStore";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
const PERIOD_DAY = 'День';
const PERIOD_WEEK = 'Неделя';
const PERIOD_MONTH = 'Месяц';

export default function AnalyticsBottomSheetComponent({ open, onDismiss, pageId }: { open: boolean; onDismiss: any; pageId: string }) {
    //init
    //periods
    const periods = [PERIOD_DAY, PERIOD_WEEK, PERIOD_MONTH];
    const [activePeriod, setActivePeriod] = useState(PERIOD_DAY);
    //day
    const [startDate, setStartDate] = useState(format(Date.now(), "yyyy-MM-dd"));
    const [endDate, setEndDate] = useState(format(Date.now(), "yyyy-MM-dd"));
    //analytics data
    const [viewCount, setViewCount] = useState('0');
    const [clickCount, setClickCount] = useState('0');

    //calculate
    async function setAnalyticsData(startDate: Date, endDate: Date) {
        const foundItem = analyticsDataStore.analyticsData.elements.find(item => item.dateStart === format(startDate, "yyyy-MM-dd") && item.dateEnd === format(endDate, "yyyy-MM-dd"));
        if (foundItem) {
            setViewCount(foundItem.analytics.view_count);
            setClickCount(foundItem.analytics.click_count);
            return;
        }

        var result = await GetAnalyticsDataService(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"), pageId);
        if (!result) return;
        setViewCount((result.analytics as Analytics).view_count);
        setClickCount((result.analytics as Analytics).click_count);
    }
    useEffect(() => {
        if (open && !analyticsDataStore.analyticsData.elements.find(item => item.dateStart === pageId)) {
            setAnalyticsData(new Date(), new Date());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    function defineDate(date: Date, period: string, today: boolean, next: boolean) {
        switch (period) {
            case 'День':
                return { newStartDate: startOfDay(today ? date : next ? addDays(date, 1) : subDays(date, 1)), newEndDate: endOfDay(today ? date : next ? addDays(date, 1) : subDays(date, 1)) };
            case 'Неделя':
                return { newStartDate: startOfWeek(today ? date : next ? addWeeks(date, 1) : subWeeks(date, 1), { weekStartsOn: 1 }), newEndDate: endOfWeek(today ? date : next ? addWeeks(date, 1) : subWeeks(date, 1), { weekStartsOn: 1 }) };
            default:
                return { newStartDate: startOfMonth(today ? date : next ? addMonths(date, 1) : subMonths(date, 1)), newEndDate: endOfMonth(today ? date : next ? addMonths(date, 1) : subMonths(date, 1)) };
        }
    }
    function handleHistoryChange({ period = activePeriod, today = false, next = true }: {
        period?: string, today?: boolean, next?: boolean
    }) {
        setActivePeriod(period);
        const { newStartDate, newEndDate } = defineDate(today ? new Date() : new Date(startDate), period, today, next);
        const newStartFormatted = format(newStartDate, "yyyy-MM-dd");
        const newEndFormatted = format(newEndDate, "yyyy-MM-dd");
        setStartDate(newStartFormatted);
        setEndDate(newEndFormatted);
        setAnalyticsData(new Date(newStartFormatted), new Date(newEndFormatted));
    }

    //widget
    const Buttons = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                    periods.map((item, index) => (
                        <div key={index} style={{ display: 'flex' }}>
                            <SimpleRetirementComponent size={5} />
                            <RaisedButtonComponent backgroundColor={activePeriod === item ? TemplateConfig.purpleColor : TemplateConfig.greyBorderColor} onClick={() => { handleHistoryChange({ period: item, today: true }); }} placeholder={item} />
                            <SimpleRetirementComponent size={5} />
                        </div>
                    ))
                }
            </div>
        );
    }
    const ArrowIcons = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LinkWrapperComponent onClick={() => handleHistoryChange({ next: false })}>
                    <img src={ArrowLeftIcon} alt="arrow left icon" />
                </LinkWrapperComponent>
                <span style={{ padding: '0px 16px' }}>
                    <SimpleTextComponent type='lightTitle'>
                        {format(Date.parse(startDate), 'MMM dd', { locale: ru })}
                        {activePeriod === PERIOD_DAY ? '' : ' - '}
                        {activePeriod === PERIOD_DAY ? null : format(Date.parse(endDate), 'MMM dd', { locale: ru })}
                    </SimpleTextComponent>
                </span>
                <LinkWrapperComponent onClick={() => handleHistoryChange({})}>
                    <img src={ArrowRightIcon} alt="arrow right icon" />
                </LinkWrapperComponent>
            </div>
        );
    }
    const AnalyticsCards = () => {
        //style
        const ResultSecureElement = styled('div')({
            border: 'none',
            backgroundColor: TemplateConfig.inputBackgroundColor,
            borderRadius: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: TemplateConfig.shortPadding
        });

        //elements
        const resultElements = [
            { "label": "Просмотр страницы", "value": viewCount ?? '0' },
            { "label": "Клики на ссылки", "value": clickCount ?? '0' }
        ];

        return (
            <div>
                {
                    resultElements.map((field, index) => (
                        <div key={index}>
                            <ResultSecureElement>
                                <SimpleTextComponent type='lightTitle'>
                                    {field.label}
                                </SimpleTextComponent>
                                <SimpleTextComponent type='lightTitle'>
                                    {field.value}
                                </SimpleTextComponent>
                            </ResultSecureElement>
                            {index === (resultElements.length - 1) ? null : <SimpleRetirementComponent size={10} />}
                        </div>
                    ))
                }
            </div>
        );
    }

    //return
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={'Аналитика'}>
            <Buttons />
            <SimpleRetirementComponent />
            <ArrowIcons />
            <SimpleRetirementComponent />
            <AnalyticsCards />
        </StandartBottomSheetComponent>
    );
}