import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import RandomStringUtil from '../../../../core/utils/RandomStringUtil';
import { PricelistAttributeDao, PricelistItem } from '../../../leaf/model/PricelistAttributeDao';
import RaisedButtonComponent from '../../../shared/ui/RaisedButtonComponent';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import PricelistItemEditComponent from './PricelistItemEditComponent';
import MoreBlackIcon from '../../../../core/assets/module/customize/more.svg';
import SimpleTextComponent from '../../../shared/ui/SimpleTextComponent';
import LinkWrapperComponent from '../../../shared/ui/LinkWrapperComponent';
import AppointmentEditMenuComponent from './AppointmentEditMenuComponent';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import PaddingComponent from './PaddingComponent';

export default function PricelistBlockBuildBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, updateValue, updatePadding }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; updateValue?: PricelistAttributeDao; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        if (updateValue) {
            updateTitleField(updateValue.title);
            updateValueField(updateValue.value ?? '');
            setItems(updateValue.items)
            setColorState(updateValue.color);
        } else {
            updateTitleField('');
            updateValueField('');
            setItems([]);
            setColorState('#000000');
        }
    }, [updateValue]);

    // title text
    const titleFieldRef = useRef('');
    const updateTitleField = (newValue: string) => { titleFieldRef.current = newValue; };

    // value text
    const valueFieldRef = useRef('');
    const updateValueField = (newValue: string) => { valueFieldRef.current = newValue; };

    // items
    const [itemAddState, setItemAddState] = useState(false);
    const [itemEditMenuState, setItemEditMenuState] = useState(false);
    const [activeItem, setActiveItem] = useState<PricelistItem | null>(null);
    const [items, setItems] = useState<{ id: string, title: string, value?: string }[]>([]);
    function onSave({
        title,
        value,
        update,
        id
    }: {
        title: string;
        value?: string;
        update: boolean;
        id?: string
    }) {
        const newItems = [...items];

        if (update) {
            const updatedItems = newItems.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        title: title,
                        value: value
                    };
                }
                return item;
            });

            setItems(updatedItems);
            return;
        } else {
            newItems.push({
                id: RandomStringUtil(36),
                title: title,
                value: value
            });
            setItems(newItems);
        }
    }
    function onDelete(id: string) {
        const newItems = [...items];
        const updatedItems = newItems.filter(item => item.id !== id);
        setItems(updatedItems);
    }

    // color
    const [colorState, setColorState] = useState('#000000');

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!titleFieldRef || titleFieldRef.current.length === 0) {
                toast("Заполните заголовок", { type: "error" });
                return;
            }

            if (items.length === 0) {
                toast("Добавьте минимум одно поле", { type: "error" });
                return;
            }

            saveDirectlyLoading('pricelist', JSON.stringify({ "title": titleFieldRef.current, "value": valueFieldRef.current, "color": colorState, "items": items }), paddingField.current);
            updateTitleField('');
            updateValueField('');
            setItems([]);
            setColorState('#000000');
        }} title={'Прайс лист'}>
            <StandartTextFieldWrapper
                placeholder="Введите заголовок"
                handleTextFieldValueChange={updateTitleField}
                initText={titleFieldRef && titleFieldRef.current.length > 0 ? titleFieldRef.current : null}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Введите цену"
                handleTextFieldValueChange={updateValueField}
                initText={valueFieldRef.current && valueFieldRef.current.length > 0 ? valueFieldRef.current : null}
                filter={/[^0-9]/g}
            />
            <SimpleRetirementComponent size={10} />
            <RaisedButtonComponent
                placeholder='Добавить поле'
                onClick={() => {
                    setActiveItem(null);
                    setItemAddState(true);
                }}
            />
            {
                items.length > 0
                    ? <div>
                        <SimpleRetirementComponent size={15} />
                        {
                            items.map((field, index) => (
                                <div key={index}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <SimpleTextComponent>
                                            {field.title}
                                        </SimpleTextComponent>
                                        <LinkWrapperComponent onClick={() => {
                                            setActiveItem(field);
                                            setItemEditMenuState(true);
                                        }}>
                                            <img width="18px" src={MoreBlackIcon} alt="more icon" />
                                        </LinkWrapperComponent>
                                    </div>
                                    {
                                        index === items.length - 1
                                            ? null
                                            : <SimpleRetirementComponent size={10} />
                                    }
                                </div>
                            ))
                        }
                    </div>
                    : null
            }
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Цвет" color={colorState} onSave={setColorState} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
            </div>
            <PricelistItemEditComponent open={itemAddState} onDismiss={() => setItemAddState(false)} onSave={onSave} updateValue={activeItem ?? undefined} />
            <AppointmentEditMenuComponent
                open={itemEditMenuState}
                onDismiss={() => setItemEditMenuState(false)}
                onDelete={() => {
                    onDelete(activeItem!.id);
                    setItemEditMenuState(false);
                }}
                onEdit={() => {
                    setItemEditMenuState(false);
                    setItemAddState(true);
                }}
            />
        </StandartBottomSheetComponent>
    );
};
