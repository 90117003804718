import SharedListComponent from "../../../shared/ui/SharedListComponent";
import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
// icons
import FormNameIcon from '../../../../core/assets/module/customize/form-name.svg';
import FormMailIcon from '../../../../core/assets/module/customize/form-mail.svg';
import FormTelephoneIcon from '../../../../core/assets/module/customize/form-telephone.svg';
import FormTextIcon from '../../../../core/assets/module/customize/form-text.svg';
import FormCheckboxIcon from '../../../../core/assets/module/customize/form-checkbox.svg';

const FormAddFieldComponent = ({ open, onDismiss, onSave }: { open: boolean; onDismiss: any; onSave: any }) => {
    const items = [
        { label: "Имя", icon: FormNameIcon, onClick: () => onSave("name") },
        { label: "Эл. почта", icon: FormMailIcon, onClick: () => onSave("mail") },
        { label: "Телефон", icon: FormTelephoneIcon, onClick: () => onSave("telephone") },
        { label: "Текст", icon: FormTextIcon, onClick: () => onSave("text") },
        { label: "Чекбокс", icon: FormCheckboxIcon, onClick: () => onSave("checkbox") }
    ];

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Выберите тип поля">
            <SharedListComponent items={items} />
        </StandartBottomSheetComponent>
    );
}

export default FormAddFieldComponent;