import { styled } from "@mui/material";
import { Attribute, PagesDataResponseImpl } from '../../configuration/model/PagesDataResponse';
import AttributeWrapperComponent from '../../leaf/ui/AttributeWrapperComponent';
import ButtonAttributeComponent from '../../leaf/ui/ButtonAttributeComponent';
import DividerAttributeComponent from '../../leaf/ui/DividerAttributeComponent';
import FormAttributeComponent from '../../leaf/ui/FormAttributeComponent';
import ImageAttributeComponent from '../../leaf/ui/ImageAttributeComponent';
import TextAttributeComponent from '../../leaf/ui/TextAttributeComponent';
import ProfileAttributeComponent from '../../leaf/ui/ProfileAttributeComponent';
import SocialAttributeComponent from '../../leaf/ui/SocialAttributeComponent';
import TimerAttributeComponent from '../../leaf/ui/TimerAttributeComponent';
import DraggableItemLeftRow from './DraggableItemLeftRow';
import DraggableItemIcon from '../../../core/assets/module/customize/drag.svg';
import SliderAttributeComponent from "../../leaf/ui/SliderAttributeComponent";
import MapAttributeComponent from "../../leaf/ui/MapAttributeComponent";
import React from "react";
import YoutubeAttributeComponent from "../../leaf/ui/YoutubeAttributeComponent";
import AppointmentAttributeComponent from "../../leaf/ui/AppointmentAttributeComponent";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import { SortableHandle } from 'react-18-sortable-hoc';
import QuestionAttributeComponent from "../../leaf/ui/QuestionAttributeComponent";
import RowAttributeComponent from "../../leaf/ui/RowAttributeComponent";
import PricelistAttributeComponent from "../../leaf/ui/PricelistAttributeComponent";
import AudioAttributeComponent from "../../leaf/ui/AudioAttributeComponent";
import MenuAttributeComponent from "../../leaf/ui/MenuAttributeComponent";

const DraggableItemComponent = React.memo(({ index, item, choosedPage, setItems }: { index: number; item: Attribute; choosedPage: PagesDataResponseImpl; setItems: any }) => {
    //style
    const DraggableItemComponentWrapper = styled('div')({
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
    });

    //widget
    const foundPage = pagesDataStore.pagesData ? pagesDataStore.pagesData.find(page => page.page_id === choosedPage.page_id) : null;
    const appointmentData = foundPage ? foundPage.activities.find((activity) => activity.type === 'appointment') : null;

    // draggable
    const DragHandle = SortableHandle(() => <img style={{ textAlign: 'right', cursor: 'grab' }} src={DraggableItemIcon} width="18px" alt="draggable item icon" />);

    return (
        <DraggableItemComponentWrapper>
            <DraggableItemLeftRow setItems={setItems} item={item} choosedPage={choosedPage} />
            <AttributeWrapperComponent padding={item.padding ? JSON.parse(item.padding) : undefined}>
                {(() => {
                    switch (item.type) {
                        case 'text':
                            return <TextAttributeComponent item={JSON.parse(item.value!)} />;
                        case 'divider':
                            return <DividerAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={choosedPage.page_id} />;
                        case 'button':
                            return <ButtonAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={choosedPage.page_id} />;
                        case 'image':
                            return <ImageAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={choosedPage.page_id} customize={true} />;
                        case 'profile':
                            return <ProfileAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={choosedPage.page_id} />;
                        case 'social':
                            return <SocialAttributeComponent item={JSON.parse(item.value!)} pageId={choosedPage.page_id} />;
                        case 'timer':
                            return <TimerAttributeComponent item={JSON.parse(item.value!)} />;
                        case 'form':
                            return <FormAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} />;
                        case 'slider':
                            return <SliderAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={choosedPage.page_id} />;
                        case 'map':
                            return <MapAttributeComponent item={JSON.parse(item.value!)} />;
                        case 'youtube':
                            return <YoutubeAttributeComponent item={JSON.parse(item.value!)} />;
                        case 'appointment':
                            return <AppointmentAttributeComponent isCustomize item={JSON.parse(item.value!)} pageId={choosedPage.page_id} appointmentData={appointmentData ?? null} />;
                        case 'question':
                            return <QuestionAttributeComponent item={JSON.parse(item.value!)} />;
                        case 'row':
                            return <RowAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={choosedPage.page_id} />;
                        case 'pricelist':
                            return <PricelistAttributeComponent item={JSON.parse(item.value!)} />;
                        case 'audio':
                            return <AudioAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={choosedPage.page_id} />;
                        case 'banner':
                            return <ImageAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={choosedPage.page_id} customize={true} />;
                        case 'menu':
                            return <MenuAttributeComponent item={JSON.parse(item.value!)} />;
                        default:
                            return <p>{item.type}</p>;
                    }
                })()}
            </AttributeWrapperComponent>
            <DragHandle />
        </DraggableItemComponentWrapper>
    );
});

export default DraggableItemComponent;
