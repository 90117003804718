import { useState } from "react";
import ArrowDownIcon from "../../../core/assets/module/leaf/ArrowDownIcon";
import ArrowUpIcon from "../../../core/assets/module/leaf/ArrowUpIcon";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { QuestionAttributeDao } from "../model/QuestionAttributeDao";

export default function QuestionAttributeComponent({ item }: { item: QuestionAttributeDao }) {
    // open state
    const [openState, setOpenState] = useState(false);

    return (
        <div style={{
            backgroundColor: TemplateConfig.inputBackgroundColor,
            padding: TemplateConfig.shortPadding,
            borderRadius: item.radius ? Number(item.radius) : undefined
        }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <SimpleTextComponent type='title' anotherColor={item.color}>
                    {item.question}
                </SimpleTextComponent>
                <div style={{ marginBottom: -4 }}>
                    <LinkWrapperComponent onClick={() => setOpenState(!openState)}>
                        {
                            openState
                                ? <ArrowUpIcon color={item.color} />
                                : <ArrowDownIcon color={item.color} />
                        }
                    </LinkWrapperComponent>
                </div>
            </div>
            {
                openState
                    ? <div>
                        <SimpleRetirementComponent size={10} />
                        <SimpleTextComponent anotherColor={item.color}>
                            {item.answer}
                        </SimpleTextComponent>
                    </div>
                    : null
            }
        </div>
    );
}