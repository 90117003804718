import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ApplicationConfig from '../../../../core/config/ApplicationConfig';
import ParseFileUtil from '../../../../core/utils/ParseFileUtil';
import { Attribute, PagesDataResponseImpl } from '../../../configuration/model/PagesDataResponse';
import { AudioAttributeDao } from '../../../leaf/model/AudioAttributeDao';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import CustomMuiUniversalFileInputComponent from '../../../shared/ui/CustomMuiUniversalFileInputComponent';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import PaddingComponent from './PaddingComponent';

export default function AudioBlockBuildBottomSheetComponent({ open, onDismiss, saveAudio, updateValue, item, choosedPage, updatePadding }: { open: boolean; onDismiss: any; saveAudio: any; updateValue?: AudioAttributeDao; item?: Attribute; choosedPage?: PagesDataResponseImpl; updatePadding?: PaddingDao }) {
    // update data
    useEffect(() => {
        async function fetchData() {
            if (updateValue) {
                setColorState(updateValue.color);
                const audioLink = `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage?.page_id}/${item?.id}/`;
                setAudiofile(updateValue.path && updateValue.path.length > 1 ? await ParseFileUtil(`${audioLink}${updateValue.path}`) : null);
            } else {
                setColorState('#000000');
                setAudiofile(null);
            }
        }

        fetchData();
    }, [updateValue, choosedPage, item]);

    // color, background
    const [colorState, setColorState] = useState('#000000');

    // image
    const [audiofile, setAudiofile] = useState<File | null>(null);
    const handleAudiofileChange = (file: File | null, inputKey: string) => setAudiofile(file);

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={!audiofile ? null : () => {
            if (!audiofile) {
                toast("Заполните поле", { type: "error" });
                return;
            }

            saveAudio(colorState, audiofile, paddingField.current);
            setAudiofile(null);
            setColorState('#000000');
        }} title={'Проигрыватель'}>
            <CustomMuiUniversalFileInputComponent
                value={audiofile}
                placeholder={'Загрузите файл'}
                onChange={handleAudiofileChange}
                format='.mp3'
                initValue={audiofile && audiofile.name.length > 0 ? audiofile.name : ''}
            />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Проигрыватель" color={colorState} onSave={setColorState} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};
