import { styled } from "@mui/material";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import ArrowBottomIcon from '../../../core/assets/module/customize/arrow-down.svg';

export default function InitalItemComponent() {
    //style
    const CenterBlock = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center',
        textAlign: 'center',
        paddingBottom: 50,
    });

    const AnimatedArrowIcon = styled('img')`
        animation: moveUpDown 2s linear infinite;
        transform-origin: center;
        @keyframes moveUpDown {
        0% { transform: translateY(0); } 50% { transform: translateY(-10px); } 100% { transform: translateY(0); }
        }
    `;

    return (
        <CenterBlock>
            <SimpleTextComponent type='header'>Нажмите, чтобы начать</SimpleTextComponent>
            <SimpleRetirementComponent />
            <SimpleRetirementComponent />
            <AnimatedArrowIcon src={ArrowBottomIcon} alt="arrow bottom icon" />
        </CenterBlock>
    );
}