import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { PagesDataResponseImpl } from "../model/PagesDataResponse";
import GetPagesDataService from "./GetPagesDataService";

export default async function PageDuplicateService({ page }: { page: PagesDataResponseImpl }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return false;

    const params = {
        "type": "page",
        "subType": "clonePage",
        "accessToken": accessToken,
        "pageId": page.page_id,
        "domain": page.domain
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: "Достигнут лимит веб-сайтов"
        }
    );
    if (!result) return false;

    // success
    await GetPagesDataService();
    toast("Веб-сайт успешно дублирован", { type: "success" });
}