import TemplateConfig from "../../../../config/TemplateConfig";

const BackgroundIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.01012 18.0301L2.00012 14.0201C0.660117 12.6801 0.660117 11.3501 2.00012 10.0101L8.68012 3.33008L16.0301 10.6801C16.4001 11.0501 16.4001 11.6501 16.0301 12.0201L10.0101 18.0401C8.69012 19.3601 7.35012 19.3601 6.01012 18.0301Z" stroke={color} stroke-width="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.3501 3L9.6901 4.33997" stroke={color} stroke-width="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.85 15.9099C17.85 15.9099 16 17.9199 16 19.1499C16 20.1699 16.83 20.9999 17.85 20.9999C18.87 20.9999 19.7 20.1699 19.7 19.1499C19.7 17.9199 17.85 15.9099 17.85 15.9099Z" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.2943 10.1401L27.8802 13.5542C27.477 13.9574 26.8173 13.9574 26.4141 13.5542L23 10.1401" stroke={color} stroke-width="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default BackgroundIcon;