import { styled } from '@mui/material';
import SimpleTextComponent from './SimpleTextComponent';
import SimpleRetirementComponent from './SimpleRetirementComponent';
import CloseIcon from '../../../core/assets/module/shared/close.svg';
import TickIcon from '../../../core/assets/module/shared/tick.svg';
import LinkWrapperComponent from './LinkWrapperComponent';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useRef, useEffect } from 'react';

const StandartBottomSheetComponent = ({
    open,
    onDismiss,
    onSubmit,
    children,
    title,
    description,
    zIndex = 2
}: {
    open: boolean;
    onDismiss: any;
    onSubmit?: any;
    children: any;
    title: string;
    description?: string;
    zIndex?: number
}) => {
    // focus
    const dummyElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open && dummyElementRef.current) {
            dummyElementRef.current.focus();
        }
    }, [open]);

    // style
    const SheetHeaderStyled = styled('div')({
        display: 'grid',
        gridTemplateColumns: '30px 1fr 30px',
        alignItems: 'center',
        justifyContent: 'center',
        height: 24
    });

    // widget
    return (
        <BottomSheet
            scrollLocking={!/iPhone|iPad|iPod/i.test(navigator.userAgent)}
            autoFocus={false}
            open={open}
            style={{ zIndex: zIndex }}
            onDismiss={onDismiss}
            header={
                <SheetHeaderStyled>
                    <LinkWrapperComponent onClick={() => onDismiss(false)}>
                        <img style={{ textAlign: 'left' }} src={CloseIcon} alt="close icon" width={24} />
                    </LinkWrapperComponent>
                    <div style={{ textAlign: 'center' }}>
                        <SimpleTextComponent type='hardMedium'>
                            {title}
                        </SimpleTextComponent>
                    </div>
                    {
                        onSubmit != null
                            ? <LinkWrapperComponent onClick={onSubmit}>
                                <img style={{ textAlign: 'right' }} src={TickIcon} alt="tick icon" />
                            </LinkWrapperComponent>
                            : null
                    }
                </SheetHeaderStyled>
            }
        >
            <div>
                <div tabIndex={0} ref={dummyElementRef} style={{ width: 0, height: 0, overflow: 'hidden' }} />
                {
                    description
                        ? (<div style={{ textAlign: 'center' }}>
                            <SimpleTextComponent type='paragraph'>
                                {description}
                            </SimpleTextComponent>
                            <SimpleRetirementComponent />
                        </div>)
                        : null
                }
                {children}
            </div>
        </BottomSheet>
    );
}

export default StandartBottomSheetComponent;