import { addDays, addMinutes, format, set } from "date-fns";
import { useEffect, useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import { Activity } from "../../configuration/model/PagesDataResponse";
import { AppointmentValueResponse } from "../../customize/model/AppointmentValueResponse";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import SwipeableComponent from "../../shared/ui/SwipeableComponent";
import GetAppointmentDateService from "../service/GetAppointmentDateService";
import { ru } from 'date-fns/locale';
import { toast } from "react-toastify";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { Card, CardContent } from "@mui/material";

const AppointmentDateOptionComponent = ({ open, onDismiss, activeLink, pageId, handleChangeDate, appointmentData, resetState }: { open: boolean; onDismiss: any; activeLink: string; pageId: string; handleChangeDate: any; appointmentData: Activity | null; resetState: number }) => {
    // init
    const appointmentValue = appointmentData ? JSON.parse(appointmentData.value) as AppointmentValueResponse : { links: [], items: [], category: [] };

    useEffect(() => {
        setAvailableInterval([]);
        setActiveDate(null);
    }, [activeLink, resetState]);

    // date
    const currentDate = new Date();
    const next60Days: Date[] = [];
    for (let i = 0; i < 60; i++) {
        const nextDate = addDays(currentDate, i);
        next60Days.push(nextDate);
    }
    const [activeDate, setActiveDate] = useState<Date | null>(null);
    async function handleDateChange(date: Date) {
        const foundLink = appointmentValue.links.find((item) => item.id === activeLink);
        const foundCategory = appointmentValue.category.find((item) => item.id === foundLink!.category);
        if (!foundCategory!.schedule.weekday[date.getDay() - 1]) {
            toast("Нет свободных окошек", { type: "error" });
            return;
        }

        const result = await GetAppointmentDateService({ pageId: pageId, linkId: activeLink, time: format(date, 'dd-MM-yyyy') });
        if (!result || !result.data) return;
        setAvailableInterval(result.data.availableInterval);
        setActiveDate(date);
    }
    function getWeekDay(date: Date) {
        let days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'Сб'];

        return days[date.getDay()];
    }

    // time
    const [availableInterval, setAvailableInterval] = useState<string[]>([]);
    const IntervalBlock = () => {
        return (
            !activeDate
                ? null
                : <div>
                    <SimpleRetirementComponent />
                    {
                        !availableInterval || availableInterval.length === 0
                            ? <SimpleTextComponent>Нет свободных окошек.</SimpleTextComponent>
                            : <Card variant="outlined" style={{ borderRadius: TemplateConfig.borderRadius, borderColor: TemplateConfig.greyBorderColor }}>
                                <CardContent style={{ padding: 16 }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 10 }}>
                                        {
                                            availableInterval.map((field, index) => (
                                                <div key={index} style={{ whiteSpace: 'nowrap' }}>
                                                    <RaisedButtonComponent
                                                        customPadding="5px 8px 5px 8px"
                                                        customHeight={30}
                                                        backgroundColor={TemplateConfig.greyBorderColor}
                                                        placeholder={field}
                                                        onClick={() => {
                                                            const foundLink = appointmentValue.links.find((item) => item.id === activeLink);
                                                            const foundCategory = appointmentValue.category.find((item) => item.id === foundLink!.category);

                                                            let calculateTime = set(activeDate!, { hours: Number(foundCategory!.schedule.startTime.split(':')[0]), minutes: Number(foundCategory!.schedule.startTime.split(':')[1]), seconds: 0 });
                                                            while (format(calculateTime, 'HH:mm') !== field) {
                                                                calculateTime = addMinutes(calculateTime, 5);
                                                            }

                                                            handleChangeDate(format(activeDate!, 'dd-MM-yyyy'), format(calculateTime, 'dd-MM-yyyy HH:mm'));
                                                        }}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                    }
                </div >
        );
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={'Дата и время'}>
            <SwipeableComponent>
                {
                    next60Days.map((field, index) => (
                        <div key={index} style={{ marginRight: 10, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                            <RaisedButtonComponent
                                backgroundColor={!activeDate ? TemplateConfig.greyBorderColor : format(activeDate, "yyyy-MM-dd") === format(field, "yyyy-MM-dd") ? TemplateConfig.purpleColor : TemplateConfig.greyBorderColor}
                                placeholder={`${format(field, "d MMM", { locale: ru }).replace(".", "")}, ${getWeekDay(field)}`}
                                onClick={() => handleDateChange(field)}
                            />
                        </div>
                    ))
                }
            </SwipeableComponent>
            <IntervalBlock />
        </StandartBottomSheetComponent>
    )
}

export default AppointmentDateOptionComponent;