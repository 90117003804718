import ApplicationConfig from "../../../core/config/ApplicationConfig";
import UrlLauncherUtil from "../../../core/utils/UrlLauncherUtil";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { PayboxPurchaseResponse } from "../model/PayboxPurchaseResponse";

export default async function PayboxPurchaseService({ productId, promoCode }: { productId: string; promoCode: string; }) {
    //check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    //request
    const params = {
        "type": "market",
        "subType": "initPayboxPurchase",
        "accessToken": accessToken,
        "productId": productId,
        "promoCode": promoCode
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: "Ошибка! Оплата не прошла"
        }
    );

    //check result
    if (!result || !result.data || !result.data.paybox_redirect_url) return;

    //open link
    UrlLauncherUtil({ url: (result.data as PayboxPurchaseResponse).paybox_redirect_url });
}