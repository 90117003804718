import { action, makeObservable, observable } from 'mobx';
import { AnalyticsDataResponse, AnalyticsItem } from '../model/AnalyticsDataResponse';

class AnalyticsDataStore {
    analyticsData: AnalyticsDataResponse = { elements: [] };

    constructor() {
        makeObservable(this, {
            analyticsData: observable,
            pushData: action,
            removeData: action,
        });
    }

    pushData(value: AnalyticsItem) {
        this.analyticsData.elements.push(value);
    }

    removeData() {
        this.analyticsData = { elements: [] };
    }
}

const analyticsDataStore = new AnalyticsDataStore();
export default analyticsDataStore;
