import { styled } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import ConfigurationHeaderComponent from "./ConfigurationHeaderComponent";

const ConfigurationWrapperComponent = ({ children }: { children: any }) => {
    const Wrapper = styled('div')({
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    });


    return (
        <Wrapper>
            <ConfigurationHeaderComponent />
            <div style={{ padding: `0px ${TemplateConfig.longPadding}px` }}>
                {children}
            </div>
            <SimpleRetirementComponent />
        </Wrapper>
    );
}

export default ConfigurationWrapperComponent;