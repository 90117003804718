import UrlLauncherUtil from "../../../core/utils/UrlLauncherUtil";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { ButtonAttributeDao } from "../../leaf/model/ButtonAttributeDao";
import ApplicationConfig from "../../../core/config/ApplicationConfig";

export default function ButtonAttributeComponent({ item, pageId, attributeId }: { item: ButtonAttributeDao; pageId: string; attributeId: string; }) {
    return (
        <RaisedButtonComponent
            onClick={() => UrlLauncherUtil({ url: item.url, newTab: true })}
            placeholder={item.placeholder}
            placeholderColor={item.color}
            backgroundColor={item.background}
            icon={item.image && item.image.length > 0 ? `${ApplicationConfig.thisProductBackendImagesPath}/${pageId}/${attributeId}/${item.image}` : undefined}
            radius={item.radius ? Number(item.radius) : undefined}
            isAnimation={true}
        />
    );
}