import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { PagesDataResponseImpl } from "../model/PagesDataResponse";
import pagesDataStore from "../store/PagesDataStore";

export default async function DeletePageService({ page }: { page: PagesDataResponseImpl }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "type": "page",
        "subType": "delete",
        "accessToken": accessToken,
        "pageId": page.page_id,
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    //if error
    if (result === null) return;

    //delete from store
    pagesDataStore.deletePage(page);

    //notify
    toast("Веб-сайт успешно удален", {
        type: "success"
    });
}