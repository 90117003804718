import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function GetPromoDataService(promoCode: String) {
    // request
    const params = {
        "module": "partner",
        "process": "promoCodeData",
        "promoCode": promoCode
    };
    var result = await GlobalRequestTemplate(
        {
            link: "https://id.ume.kz/request/api/v1/main.php",
            params: params,
            errorText: "Промокод не найден. Попробуйте другой"
        }
    );

    // check result
    if (!result || !result.data || !result.data.promo_code_procent) return undefined;
    return result.data.promo_code_procent;
}