import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function GetPublicDataService({ nickname }: { nickname?: string }) {
    if (nickname === null || nickname?.length === 0) {
        return;
    }

    const params = {
        "type": "page",
        "subType": "publicGet",
        "nickname": nickname,
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    return result;
}