import SharedListComponent from "../../shared/ui/SharedListComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const PageSettingsComponent = ({ open, onDismiss, elements }: { open: boolean; onDismiss: any; elements: any }) => {
    return (
        <StandartBottomSheetComponent title="Настройки" open={open} onDismiss={onDismiss}>
            <SharedListComponent items={elements} />
        </StandartBottomSheetComponent>
    );
}

export default PageSettingsComponent;