import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function AppointmentUpdateService({ appointmentValue, pageId }: { appointmentValue: string; pageId: string }) {
    // auth check
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // create form
    const formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('pageId', pageId);
    formData.append('activityType', "appointment");
    formData.append('activityValue', appointmentValue);

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendActivityUpdatePath,
            type: "post",
            formData: formData
        }
    );
    return result;
}