import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApplicationConfig from "../../../../core/config/ApplicationConfig";
import TemplateConfig from "../../../../core/config/TemplateConfig";
import ParseFileUtil from "../../../../core/utils/ParseFileUtil";
import RandomStringUtil from "../../../../core/utils/RandomStringUtil";
import { PagesDataResponseImpl } from "../../../configuration/model/PagesDataResponse";
import CustomMuiFileInputComponent from "../../../shared/ui/CustomMuiFileInputComponent";
import RaisedButtonComponent from "../../../shared/ui/RaisedButtonComponent";
import SimpleRetirementComponent from "../../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
import StandartTextField from "../../../shared/ui/StandartTextField";
import SwipeableComponent from "../../../shared/ui/SwipeableComponent";
import { Item } from "../../model/AppointmentValueResponse";
import AppointmentItemsUpdateService from "../../service/AppointmentItemsUpdateService";

const AppointmentItemAddComponent = ({ open, onDismiss, choosedPage, reload, appointmentValue, updateItem, updateItemIndex, state, activeCategoryId, handleActiveIdsChange }: { open: boolean; onDismiss: any, choosedPage: PagesDataResponseImpl; reload: any; appointmentValue: any; updateItem?: Item | null; updateItemIndex?: number | null; state: number; activeCategoryId: string; handleActiveIdsChange: any }) => {
    useEffect(() => {
        async function fetchData() {
            if (updateItem) {
                setItemName(updateItem.name);
                setPriceName(updateItem.price ?? '');
                setDiscountName(updateItem.discount ?? '');
                setActiveDuration(updateItem.length);

                // image update
                const imageLink = `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage?.page_id}/${updateItem.id}/`;
                const updateMap: (File | null)[] = [];
                for (const image of updateItem.images) {
                    updateMap.push(await ParseFileUtil(`${imageLink}${image}`));
                }
                if (updateMap.length < 5) updateMap.push(null);
                setItemImages(updateMap);
            } else {
                setItemName('');
                setPriceName('');
                setDiscountName('');
                setActiveDuration('60');
            }
        }

        fetchData();
    }, [updateItem, state, choosedPage.page_id]);

    // item name
    const [itemName, setItemName] = useState('');

    // item price
    const [priceName, setPriceName] = useState('');
    function onPriceChange(value: string) {
        const formattedValue = value.replace(/[^0-9]/g, '');
        setPriceName(formattedValue);
    }

    // discount
    const [discountName, setDiscountName] = useState('');
    function onDiscountChange(value: string) {
        const formattedValue = value.replace(/[^0-9]/g, '');
        setDiscountName(formattedValue);
    }

    // duration
    const durations = [
        { label: "10 мин", value: '10' },
        { label: "30 мин", value: '30' },
        { label: "Час", value: '60' },
        { label: "2 часа", value: '120' },
        { label: "3 часа", value: '180' },
        { label: "5 часов", value: '300' },
        { label: "10 часов", value: '600' },
        { label: "Круглосуточно", value: '1380' }
    ];
    const [activeDuration, setActiveDuration] = useState('60');

    // IMAGE SECTION
    const [itemImages, setItemImages] = useState<(File | null)[]>([null]);
    function handleImageLoader(file: File | null, inputKey: string) {
        // replace value
        const cloneItemImages = [...itemImages];
        cloneItemImages[Number(inputKey)] = file;
        if (!file) cloneItemImages.splice(Number(inputKey), 1);

        // insert new row
        if (cloneItemImages[cloneItemImages.length - 1] != null && cloneItemImages.length < 5) cloneItemImages.push(null);

        //set to global
        setItemImages(cloneItemImages);
    }
    function getImageContainer(i: number) {
        return (
            <div key={i}>
                <CustomMuiFileInputComponent
                    value={itemImages[i]}
                    placeholder="Загрузите картинку"
                    onChange={handleImageLoader}
                    inputKey={i.toString()}
                    aspectRatio={1}
                    initValue={itemImages[i] ? itemImages[i]!.name : ''}
                />
                {
                    i === (itemImages.length - 1)
                        ? null
                        : <SimpleRetirementComponent size={10} />
                }
            </div>
        );
    }

    return (
        <StandartBottomSheetComponent title="Услуга" open={open} onDismiss={onDismiss} onSubmit={() => {
            if (itemName.length === 0) {
                toast("Заполните имя услуги", { type: "error" });
                return;
            }

            AppointmentItemsUpdateService({
                handleActiveIdsChange: handleActiveIdsChange,
                activeCategoryId: activeCategoryId,
                reload: reload,
                action: updateItemIndex !== null && updateItem ? 'update' : 'insert',
                choosedPage: choosedPage,
                item: {
                    id: updateItemIndex !== null && updateItem ? updateItem.id : RandomStringUtil(36),
                    name: itemName,
                    length: activeDuration,
                    price: priceName && priceName.length === 0 ? null : priceName,
                    discount: discountName && discountName.length === 0 ? null : discountName,
                    images: []
                },
                images: itemImages
            });

            onDismiss();
        }}>
            <StandartTextField
                placeholder="Наименование"
                maxLength={100}
                text={itemName}
                onChange={(event: any) => !event ? null : setItemName(event.target.value)}
            />
            <SimpleRetirementComponent size={10} />
            <div style={{ display: 'flex' }}>
                <StandartTextField
                    placeholder="Цена"
                    maxLength={100}
                    text={priceName}
                    onChange={(event: any) => !event ? null : onPriceChange(event.target.value)}
                />
                <SimpleRetirementComponent />
                <StandartTextField
                    placeholder="Цена со скидкой"
                    maxLength={100}
                    text={discountName}
                    onChange={(event: any) => !event ? null : onDiscountChange(event.target.value)}
                />
            </div>
            <SimpleRetirementComponent size={10} />
            <SwipeableComponent>
                {
                    durations.map((field, index) => (
                        <div key={index} style={{ marginRight: 10, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                            <RaisedButtonComponent
                                backgroundColor={activeDuration === field.value ? TemplateConfig.purpleColor : TemplateConfig.greyBorderColor}
                                placeholder={field.label}
                                onClick={() => setActiveDuration(field.value)}
                                miniButton
                            />
                        </div>
                    ))
                }
            </SwipeableComponent>
            <SimpleRetirementComponent size={10} />
            {
                (() => {
                    const itemElements: any = [];
                    for (let i = 0; i < itemImages.length; i++) {
                        itemElements.push(getImageContainer(i))
                    }
                    return itemElements;
                })()
            }
        </StandartBottomSheetComponent>
    );
}

export default AppointmentItemAddComponent;