import TemplateConfig from "../../../config/TemplateConfig";

const TelephoneIcon = ({ background = TemplateConfig.blackColor, color = TemplateConfig.whiteColor }: { background?: string; color?: string }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.00012207" width="40" height="40" rx="8" fill={background} />
            <path d="M27.8475 27.171C27.7913 27.4901 27.6626 27.8055 27.4556 28.098C27.2487 28.3905 27.0006 28.6575 26.6935 28.8958C26.1748 29.2978 25.6352 29.5591 25.0555 29.6854C24.4846 29.8132 23.8882 29.8268 23.2678 29.7174C22.3637 29.558 21.4351 29.1749 20.4925 28.5609C19.5498 27.9469 18.6336 27.1822 17.7529 26.2684C16.8649 25.3441 16.0442 24.3495 15.2834 23.274C14.533 22.1913 13.8791 21.0798 13.3216 19.9397C12.7729 18.8012 12.3661 17.6876 12.1173 16.611C11.87 15.5256 11.8276 14.522 11.9902 13.6002C12.0965 12.9975 12.3044 12.4402 12.6109 11.9459C12.9189 11.4427 13.3433 11.0058 13.8912 10.6454C14.5569 10.1871 15.2257 10.0217 15.8816 10.1374C16.1298 10.1811 16.3686 10.278 16.5714 10.4235C16.7831 10.5705 16.9588 10.766 17.0777 11.0245L18.623 14.2854C18.7434 14.5351 18.8227 14.7593 18.8681 14.9683C18.915 15.1685 18.9265 15.3625 18.8969 15.5309C18.8593 15.7436 18.7598 15.9454 18.5998 16.1274C18.4486 16.3109 18.2427 16.4939 17.9925 16.6691L17.1954 17.2505C17.0807 17.3309 17.0161 17.4383 16.9911 17.5801C16.9786 17.651 16.9765 17.7146 16.9817 17.7886C16.9958 17.8642 17.013 17.9221 17.0214 17.9784C17.1293 18.299 17.3369 18.7286 17.6456 19.2582C17.9632 19.7894 18.3058 20.3342 18.6838 20.8852C19.0796 21.4394 19.4639 21.9549 19.8634 22.4366C20.2555 22.9079 20.5897 23.241 20.8629 23.4537C20.9041 23.4792 20.9526 23.5152 21.01 23.5527C21.0762 23.5918 21.1455 23.6131 21.2253 23.6272C21.376 23.6538 21.5006 23.6209 21.6153 23.5406L22.4061 22.9946C22.6667 22.8121 22.9092 22.6812 23.1318 22.6108C23.3575 22.5227 23.5723 22.4966 23.7939 22.5357C23.9623 22.5654 24.1333 22.6321 24.3142 22.7462C24.4952 22.8604 24.6787 23.0115 24.8737 23.2013L27.4402 25.8014C27.6425 26.0016 27.7692 26.2159 27.8276 26.4547C27.8772 26.6919 27.8913 26.9228 27.8475 27.171Z" stroke={color} stroke-width="1.5" strokeMiterlimit="10" fill={background} />
        </svg>
    );
}

export default TelephoneIcon;