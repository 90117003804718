import LinkIcon from '../../../core/assets/module/leaf/link.svg';

export default function ClickableAttributeComponent({ url, children, icon = true, anotherFunction }: { url?: string; children: any; icon?: boolean; anotherFunction?: any }) {
    if (url === null || url!.length === 0) {
        return (
            <div>
                {children}
            </div>
        );
    }

    return (
        <a
            onClick={() => anotherFunction != null ? anotherFunction() : null}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
                position: 'relative'
            }}
        >
            {children}
            {
                icon
                    ? (
                        <img src={LinkIcon} alt="draggable item icon" style={{ position: 'absolute', bottom: '10px', right: '15px' }} />
                    )
                    : null
            }
        </a>
    );
}