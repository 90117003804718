import { styled } from "@mui/material";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import TemplateConfig from "../../../core/config/TemplateConfig";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { RowAttributeDao } from "../model/RowAttributeDao";

export default function RowAttributeComponent({ item, pageId, attributeId }: { item: RowAttributeDao; pageId: string; attributeId: string; }) {
    // style
    const RowWrapper = styled('div')({
        minHeight: TemplateConfig.buttonHeight,
        borderRadius: item.radius ? Number(item.radius) : undefined,
        backgroundColor: TemplateConfig.inputBackgroundColor,
        padding: TemplateConfig.shortPadding,
        display: 'flex'
    });

    return (
        <RowWrapper>
            <img
                src={`${ApplicationConfig.thisProductBackendImagesPath}/${pageId}/${attributeId}/${item.image}`}
                alt="Изображение"
                style={{
                    width: '48px',
                    height: '48px',
                    borderRadius: TemplateConfig.miniBorderRadius,
                }}
            />
            <div style={{
                paddingLeft: 10,
                maxWidth: (window.innerWidth > TemplateConfig.mobileMaxWidth ? TemplateConfig.mobileMaxWidth : window.innerWidth) - 195,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}>
                <SimpleTextComponent anotherColor={item.color} type='title'>
                    {item.title}
                </SimpleTextComponent>
                <SimpleRetirementComponent size={5} />
                <SimpleTextComponent anotherColor={item.color}>
                    {item.value}
                </SimpleTextComponent>
            </div>
        </RowWrapper>
    );
}