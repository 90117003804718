import { Card } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextButtonComponent from "../../shared/ui/SimpleTextButtonComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { SharedCardItemModel } from "../model/SharedCardItemModel";

const SharedCardItemComponent = ({ title, children, buttonText, buttonAction }: { title?: string; children: SharedCardItemModel[]; buttonText?: string; buttonAction?: any }) => {
    return (
        <Card
            variant="outlined"
            style={{
                borderRadius: TemplateConfig.borderRadius,
                borderColor: TemplateConfig.greyBorderColor,
                padding: TemplateConfig.shortPadding
            }}
        >
            {title && <div>
                <div style={{ borderBottom: `1px solid ${TemplateConfig.greyBorderColor}`, paddingBottom: 10, textAlign: 'center' }}><SimpleTextComponent type='title'>{title}</SimpleTextComponent></div>
                <SimpleRetirementComponent size={20} />
            </div>}
            {
                children.map((field, index) => (
                    <div key={index}>
                        {
                            field.hide
                                ? null
                                : (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {field.icon && <>
                                                <img src={field.icon} alt="field icon" width={20} />
                                                <SimpleRetirementComponent size={16} />
                                            </>}
                                            <div style={{ wordBreak: 'break-word' }}>
                                                <div style={{ paddingRight: 5 }}>
                                                    <SimpleTextComponent
                                                        type="paragraph"
                                                        multiline
                                                    >
                                                        {field.label}
                                                    </SimpleTextComponent>
                                                </div>
                                                {field.subtitle ? <SimpleTextComponent type="paragraph">
                                                    {field.subtitle}
                                                </SimpleTextComponent> : null}
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            {

                                                field.clickable
                                                    ? (
                                                        <SimpleTextButtonComponent type="boldParagraph" placeholder={field.value} onClick={field.onClick} />
                                                    )
                                                    : field.valueComponent
                                                    ?? (
                                                        <SimpleTextComponent
                                                            type="boldParagraph"
                                                            multiline
                                                        >
                                                            {field.value}
                                                        </SimpleTextComponent>
                                                    )
                                            }
                                        </div>
                                    </div>
                                )
                        }
                        {(!field.last && !field.hide) && <SimpleRetirementComponent size={10} />}
                    </div>
                ))
            }
            {buttonAction && buttonText && <div>
                <SimpleRetirementComponent size={20} />
                <RaisedButtonComponent
                    placeholder={buttonText}
                    onClick={buttonAction}
                />
            </div>}
        </Card>
    );
}

export default SharedCardItemComponent;