import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function GetAppointmentDateService({ linkId, pageId, time }: { linkId: string; pageId: string; time: string }) {
    const params = {
        "type": "page",
        "subType": "getAppointment",
        "linkId": linkId,
        "pageId": pageId,
        "time": time
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: "Нет свободных окошек"
        }
    );

    return result;
}