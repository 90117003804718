import { useRef } from "react";
import { toast } from "react-toastify";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../shared/ui/StandartTextFieldWrapper";
import { PagesDataResponseImpl } from "../model/PagesDataResponse";
import PageTransferService from "../service/PageTransferService";

const PageTransferComponent = ({ open, onDismiss, page }: { open: boolean; onDismiss: any; page: PagesDataResponseImpl }) => {
    // recepientEmail
    const emailField = useRef('');
    const onEmailChange = (newValue: string) => { emailField.current = newValue };
    async function execTransfer() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailField.current || emailField.current.length < 3 || !emailRegex.test(emailField.current)) {
            toast("Неверный формат", { type: "error" });
            return;
        }

        const result = await PageTransferService({ page: page, recipientEmail: emailField.current });
        if (result) onDismiss();
    }

    return (
        <StandartBottomSheetComponent title="Передать сайт" open={open} onDismiss={onDismiss} onSubmit={execTransfer}>
            <StandartTextFieldWrapper
                placeholder="Введите адрес эл. почты"
                handleTextFieldValueChange={onEmailChange}
            />
        </StandartBottomSheetComponent>
    );
}

export default PageTransferComponent;