import { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import ConfigurationWrapperComponent from "../../configuration/ui/ConfigurationWrapperComponent";
import SharedCardItemComponent from "../../market/ui/SharedCardItemComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import PasswordService from "../service/PasswordService";
import customerDataStore from "../store/CustomerDataStore";
import PasswordComponent from "./PasswordComponent";
import PasswordIcon from "../../../core/assets/module/input/password.svg";
import MailIcon from "../../../core/assets/module/input/mail.svg";
import ProfileIcon from "../../../core/assets/module/configuration/profile.svg";
import DataComponent from "./DataComponent";

const ProfileComponent = () => {
    // data
    const [dataState, setDataState] = useState(false);

    // password
    const [passwordState, setPasswordState] = useState(false);
    const passwordField = useRef('');
    const onPasswordChange = (newValue: string) => { passwordField.current = newValue };
    const rePasswordField = useRef('');
    const onRePasswordChange = (newValue: string) => { rePasswordField.current = newValue };
    function onUpdatePassword() {
        if (!passwordField.current || passwordField.current.length < 3) {
            toast("Пароль должен содержать мин. 6 символов", { type: "error" });
            return;
        }
        if (passwordField.current !== rePasswordField.current) {
            toast("Пароли не совпадают", { type: "error" });
            return;
        }
        PasswordService(passwordField.current, setPasswordState);
    }
    return (
        <ConfigurationWrapperComponent>
            <Helmet title={'Профиль'} />
            <SimpleRetirementComponent size={30} />
            <div style={{ textAlign: 'center' }}>
                <SimpleTextComponent type="header">Профиль</SimpleTextComponent>
            </div>
            <SimpleRetirementComponent size={30} />
            <SharedCardItemComponent
                title="Личные данные"
                buttonText="Редактировать"
                buttonAction={() => setDataState(true)}
            >
                {
                    [
                        { label: "Имя", value: customerDataStore.customerData!.customer.forename ?? 'Не указано', icon: ProfileIcon },
                        { label: "Фамилия", value: customerDataStore.customerData!.customer.surname ?? 'Не указано', icon: ProfileIcon },
                        { label: "Эл. почта", value: customerDataStore.customerData!.customer.email, icon: MailIcon, last: true },
                    ]
                }
            </SharedCardItemComponent>
            <SimpleRetirementComponent size={10} />
            <SharedCardItemComponent
                title="Безопасность"
                buttonText="Изменить"
                buttonAction={() => setPasswordState(true)}
            >
                {
                    [
                        { label: "Пароль", value: "******", last: true, icon: PasswordIcon },
                    ]
                }
            </SharedCardItemComponent>
            <PasswordComponent open={passwordState} onDismiss={() => setPasswordState(false)} onSubmit={onUpdatePassword} savePassword={onPasswordChange} saveRePassword={onRePasswordChange} />
            <DataComponent open={dataState} onDismiss={() => setDataState(false)} />
        </ConfigurationWrapperComponent>
    );
}

export default ProfileComponent;