import { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { toast } from 'react-toastify';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import { TimerAttributeDao } from '../../../leaf/model/TimeAttributeDao';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import PaddingComponent from './PaddingComponent';

export default function TimerlockBuildBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, updateValue, updatePadding }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; updateValue?: TimerAttributeDao; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        updateValue ? setTimeState(new Date(updateValue.time)) : setTimeState(setHours(setMinutes(new Date(), 30), 16));
        if (updateValue) setColorState(updateValue.color ?? '#000000');
    }, [updateValue]);

    const [timeState, setTimeState] = useState<Date | null>(
        setHours(setMinutes(new Date(), 30), 16)
    );

    // color
    const [colorState, setColorState] = useState('#000000');

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (timeState === null) {
                toast("Заполните поле", { type: "error" });
                return;
            }
            saveDirectlyLoading('timer', JSON.stringify({ "time": timeState.toISOString(), "color": colorState }), paddingField.current);
            setTimeState(setHours(setMinutes(new Date(), 30), 16));
            setColorState('#000000');
        }} title={'Таймер'}>
            <DatePicker
                selected={timeState}
                onChange={(date) => setTimeState(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="LLLL d, yyyy H:mm"
                calendarStartDay={1}
                locale={ru}
                className="date-picker-custom-style full-width"
                timeCaption='Время'
                popperPlacement="top-start"
            />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Время" color={colorState} onSave={setColorState} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};