import styled from "@emotion/styled";
import { useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import TelephoneFormateUtil from "../../../core/utils/TelephoneFormateUtil";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import StandartTextField from "../../shared/ui/StandartTextField";
import { CheckboxOption, FormAttributeDao } from "../model/FormAttributeDao";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import FormAttributeSendService from "../service/FormAttributeSendMailService";
import { toast } from "react-toastify";
import TickTrueIcon from "../../../core/assets/module/customize/tick-black-false.svg";
import TickFalseIcon from "../../../core/assets/module/customize/tick-black-true.svg";

export default function FormAttributeComponent({ item, attributeId }: { item: FormAttributeDao; attributeId: string }) {
    // fields
    const [inputValues, setInputValues] = useState<{ id: string, title: string, value: string }[]>([]);
    function onSetInputValue(id: string, title: string, value: string) {
        const newValues = [...inputValues.filter(item => item.id !== id)];
        newValues.push({
            id: id,
            title: title,
            value: title === 'Поле `Телефон`'
                ? TelephoneFormateUtil({ value: value })
                : value
        });
        setInputValues(newValues);
    }

    // checkbox
    const [optionAnswers, setOptionAnswers] = useState<{ id: string, title: string, value: CheckboxOption[] }[]>([]);
    function onSetOptionAnswers(id: string, title: string, value: CheckboxOption) {
        const newValues = [...optionAnswers];
        const foundItem = newValues.find(item => item.id === id);

        if (!foundItem) {
            newValues.push({
                id: id,
                title: title,
                value: [value]
            });
        } else {
            const foundItemIndex = newValues.findIndex(item => item.id === id);

            if (foundItem.value.includes(value))
                newValues[foundItemIndex].value = foundItem.value.filter(item => item !== value);
            else
                newValues[foundItemIndex].value.push(value);
        }

        setOptionAnswers(newValues);
    }

    // reset
    function reset() {
        setInputValues([]);
        setOptionAnswers([]);
    }

    // style
    const CheckboxRow = styled('div')({
        minHeight: TemplateConfig.buttonHeight,
        borderRadius: item.radius ? Number(item.radius) : undefined,
        backgroundColor: TemplateConfig.inputBackgroundColor,
        padding: TemplateConfig.shortPadding
    });

    return (
        <div>
            {
                item.value.map((field, index) => (
                    <div key={index}>
                        {
                            field.label === 'checkbox'
                                ? <CheckboxRow>
                                    <SimpleTextComponent type='lightTitle'>
                                        {field.children!.title}
                                    </SimpleTextComponent>
                                    <SimpleRetirementComponent size={15} />
                                    {
                                        field.children!.options.map((option, index) => (
                                            <div key={index}>
                                                <LinkWrapperComponent onClick={() => onSetOptionAnswers(field.id, field.children!.title, option)}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={optionAnswers.find(item => item.value.includes(option)) ? TickFalseIcon : TickTrueIcon} alt="tick icon" />
                                                        <SimpleRetirementComponent size={16} />
                                                        <SimpleTextComponent>
                                                            {option.option}
                                                        </SimpleTextComponent>
                                                    </div>
                                                </LinkWrapperComponent>
                                                {
                                                    index === field.children!.options.length - 1
                                                        ? null
                                                        : <SimpleRetirementComponent size={10} />
                                                }
                                            </div>
                                        ))
                                    }
                                </CheckboxRow>
                                : <StandartTextField
                                    radius={item.radius ? Number(item.radius) : undefined}
                                    multiline={field.label === 'text'} placeholder={field.name.split('`')[1].split('`')[0]}
                                    maxLength={field.label === 'text' ? 300 : 100}
                                    rows={field.label === 'text' ? '3' : '1'}
                                    text={inputValues.find(item => item.id === field.id) ? inputValues.find(item => item.id === field.id)!.value : ''}
                                    onChange={(event: any) => event ? onSetInputValue(field.id, field.name, event.target.value) : null}
                                />
                        }
                        <SimpleRetirementComponent size={10} />
                    </div>
                ))
            }
            <RaisedButtonComponent
                isAnimation
                radius={item.radius ? Number(item.radius) : undefined}
                placeholder={item.placeholder}
                onClick={() => {
                    for (let i = 0; i < item.value.length; i++) {
                        const value = item.value[i];
                        const foundInput = inputValues.find(item => item.id === value.id);
                        const foundOption = optionAnswers.find(item => item.id === value.id);

                        if (value.label === 'name' && !foundInput) {
                            toast("Заполните все поля", { type: "error" });
                            return;
                        }

                        if (value.label === 'checkbox' && (!foundOption || foundOption.value.length < 1)) {
                            toast("Заполните все поля", { type: "error" });
                            return;
                        }
                    }

                    FormAttributeSendService({ attributeId: attributeId, reset: reset, optionAnswers: optionAnswers, inputValues: inputValues });
                }}
                backgroundColor={item.background ?? TemplateConfig.blackColor}
                placeholderColor={item.color ?? TemplateConfig.whiteColor}
            />
        </div>
    );
}