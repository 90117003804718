import { useState } from "react";
import ColorPickerComponent from "../../shared/ui/ColorPickerComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";

const ColorPickerButtonComponent = ({ text, color, onSave }: { text: string; color: string; onSave: any }) => {
    // init
    const [pickerState, setPickerState] = useState(false);

    return (
        <div>
            <LinkWrapperComponent onClick={() => setPickerState(true)}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ width: 16, height: 16, borderRadius: '24%', border: `1px solid ${color === '#ffffff' ? 'black' : color}`, backgroundColor: color }} />
                    <SimpleRetirementComponent size={2} />
                    <SimpleTextComponent type='paragraph'>
                        {text}
                    </SimpleTextComponent>
                </div>
            </LinkWrapperComponent>
            <ColorPickerComponent open={pickerState} onDismiss={() => setPickerState(false)} colorState={color} onSave={onSave} />
        </div>
    );
}

export default ColorPickerButtonComponent;