import TemplateConfig from "../../../../config/TemplateConfig";

const TextAlignCenterIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 4.5H21" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.25977 9.5H16.7398" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 14.5H21" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.25977 19.5H16.7398" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default TextAlignCenterIcon;