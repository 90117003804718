import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { ProductResponse } from "../model/ProductResponse";
import productDataStore from "../store/ProductDataStore";

export default async function GetProductDataService() {
    //check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;
    if (productDataStore.productData) return;

    //request
    const params = {
        "type": "product",
        "subType": "data",
        "accessToken": accessToken
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    //check result
    if (!result || !result.data) return;
    //add result to store
    productDataStore.saveProducts(result.data as ProductResponse);
    return result.data;
}