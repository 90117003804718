import { Button } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";
import SimpleTextComponent from "./SimpleTextComponent";

export default function RaisedButtonComponent({ onClick, placeholder, fullWidth = true, customWidth, backgroundColor = TemplateConfig.purpleColor, icon, miniButton = false, customPadding, customHeight, placeholderColor, radius, isAnimation = false }: { onClick: any; placeholder: string; fullWidth?: boolean; customWidth?: number | null, backgroundColor?: any; icon?: any; miniButton?: boolean; customPadding?: string; customHeight?: number; placeholderColor?: string; radius?: number; isAnimation?: boolean }) {
    return (
        <Button
        className={isAnimation ? "hasAnimation" : undefined}
            style={{
                textTransform: 'none',
                borderRadius: radius ?? TemplateConfig.borderRadius,
                padding: customPadding ?? miniButton ? '10px 5px 10px 5px' : '10px 16px 10px 16px',
                minWidth: customWidth ? customWidth : 64,
                width: customWidth ? customWidth : fullWidth ? '100%' : undefined,
                height: customHeight ?? TemplateConfig.buttonHeight,
                backgroundColor: backgroundColor
            }}
            onClick={onClick}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SimpleTextComponent type="hardMediumWhite" anotherColor={placeholderColor}>
                    {customWidth ? null : placeholder}
                </SimpleTextComponent>
                {
                    icon
                        ? <img style={{
                            marginLeft: customWidth ? 0 : 8,
                            maxWidth: 28,
                            maxHeight: 28,
                            borderRadius: TemplateConfig.miniBorderRadius
                        }} src={icon} alt="custom icon" />
                        : null
                }
            </div>
        </Button>
    );
}