import TemplateConfig from "../../../config/TemplateConfig";

const FacebookIcon = ({ background = TemplateConfig.blackColor, color = TemplateConfig.whiteColor }: { background?: string; color?: string }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill={background} />
            <path d="M20.9663 30H18.2322C17.2959 30 16.5094 29.2135 16.5094 28.2772V22.1348H15.7228C14.7865 22.1348 14 21.3483 14 20.412V18.0899C14 17.1536 14.7865 16.367 15.7228 16.367H16.5468V15.618C16.5468 12.1723 18.5318 10 21.7528 10C22.8764 10 23.8502 10.0749 24.2622 10.1124C25.1236 10.2247 25.7603 10.9738 25.7603 11.8352V14.1573C25.7603 15.0936 24.9738 15.8801 24.0375 15.8801H22.764V16.367H23.9625C24.8989 16.367 25.6854 17.1536 25.6854 18.0899C25.6854 18.2022 25.6854 18.2772 25.6479 18.3895L25.2734 20.6367C25.161 21.4607 24.412 22.0974 23.5506 22.0974H22.764V28.2397C22.7266 29.2135 21.9401 30 20.9663 30ZM15.7228 17.9401C15.6479 17.9401 15.573 18.015 15.573 18.0899V20.412C15.573 20.4869 15.6479 20.5618 15.7228 20.5618H17.3333C17.7828 20.5618 18.1199 20.8989 18.1199 21.3483V28.2397C18.1199 28.3146 18.1948 28.3895 18.2697 28.3895H21.0037C21.0787 28.3895 21.1536 28.3146 21.1536 28.2397V21.3483C21.1536 20.8989 21.4906 20.5618 21.9401 20.5618H23.5131C23.588 20.5618 23.6629 20.4869 23.6629 20.412L24.0375 18.0899C24.0375 18.015 23.9625 17.9401 23.8876 17.9401H21.9026C21.4532 17.9401 21.1161 17.603 21.1161 17.1536V15.5805C21.1161 15.2434 21.1536 15.0187 21.2285 14.8315C21.3034 14.6442 21.4157 14.5318 21.603 14.4569C21.7903 14.3446 22.0524 14.3071 22.427 14.3071H24C24.0749 14.3071 24.1498 14.2322 24.1498 14.1573V11.8352C24.1498 11.7603 24.0749 11.6854 24 11.6854C23.7004 11.6479 22.764 11.573 21.7154 11.573C18.5693 11.573 18.0824 14.1198 18.0824 15.618V17.1536C18.0824 17.603 17.7453 17.9401 17.2959 17.9401H15.7228Z" fill={color} />
        </svg>
    );
}

export default FacebookIcon;