import TemplateConfig from "../../../../config/TemplateConfig";

const ItalicIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.62012 3H18.8701" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.12012 21H14.3701" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.25 3L9.75 21" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default ItalicIcon;