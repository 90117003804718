import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import { Attribute } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import imageCompression from 'browser-image-compression';
import { ProfileAttributeResponse } from "../model/ProfileAttributeResponse";
import loaderStore from "../../shared/store/LoaderStore";

export default async function InsertProfileBlockService({ pageId, avatar, background, title, description, color, attributePadding, radius }: { pageId: string; avatar: File | null; background: File | null; title: string; description: string; color: string; attributePadding: string; radius: string }) {
    //check access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    //open loader
    loaderStore.showStore();

    const compressedAvatar = avatar ? await imageCompression(avatar, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }) : null;
    const compressedBackground = background ? await imageCompression(background, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }) : null;

    const attributeType = "profile";
    const attributeValue = JSON.stringify({ "title": title, "description": description, "color": color, radius: radius });
    const formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('pageId', pageId);
    formData.append('attributeType', attributeType);
    formData.append('attributeValue', attributeValue);
    formData.append('attributePadding', attributePadding);
    if (compressedAvatar) formData.append('avatar', compressedAvatar, compressedAvatar.name);
    if (compressedBackground) formData.append('background', compressedBackground, compressedBackground.name);

    //close loader
    loaderStore.hideStore();

    var result = await GlobalRequestTemplate(
        {
            type: "post",
            link: ApplicationConfig.thisProductBackendAttributeCreatePath,
            formData: formData,
        }
    );

    //if error
    if (result === null) return;

    //notify
    toast("Блок успешно добавлен", {
        type: "success"
    });

    //store
    pagesDataStore.insertAttribute({
        id: (result.data as ProfileAttributeResponse).attributeId,
        mark: (result.data as ProfileAttributeResponse).mark,
        type: attributeType,
        padding: attributePadding,
        value: JSON.stringify({ "title": title, "description": description, "avatar": (result.data as ProfileAttributeResponse).avatar ?? null, "background": (result.data as ProfileAttributeResponse).background ?? null, "color": color, "radius": radius }),
    } as Attribute, pageId);
}