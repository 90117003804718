import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import loaderStore from "../../shared/store/LoaderStore";

export default async function WebsiteSettingsService({ pageId, type, color, background, telephone, seo, setSeo }: { pageId: string; type: string; color?: string; background?: File | null; telephone?: string; seo?: any; setSeo?: any }) {
    //check access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    //open loader
    loaderStore.showStore();

    //create form data
    const formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('pageId', pageId);
    formData.append('settingType', type);

    if (type === 'background' && background) {
        const compressedBackground = await imageCompression(background, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        });
        formData.append('background', compressedBackground, compressedBackground.name);
    }

    if (type === 'color' && color) formData.append('color', color);
    if (type === 'telephone' && telephone) formData.append('telephone', telephone);
    if (type === 'seo' && seo) {
        formData.append('seoTitle', seo.title);
        formData.append('seoDescription', seo.description);
        formData.append('seoKeywords', seo.keywords);
        const compressedFavicon = await imageCompression(seo.favicon, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        });
        formData.append('seoFavicon', compressedFavicon, compressedFavicon.name);
    }

    //close loader
    loaderStore.hideStore();

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendStyleUpdatePath,
            type: "post",
            formData: formData
        }
    );
    if (!result || !result.data || !result.data.settings) return;

    // store
    pagesDataStore.updateSettings(result.data.settings, pageId);

    // toast
    toast("Веб-сайт успешно обновлен", { type: "success" });

    return result.data.settings;
}