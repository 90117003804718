import { createTheme } from "@mui/material";

export default class TemplateConfig {
    //global size
    static mobileMaxWidth: number = 520;
    static miniContainerWidth: number = 300;

    //padding
    static shortPadding: number = 16;
    static longPadding: number = 24;

    //font size
    static smallFontSize: number = 14;
    static mediumFontSize: number = 16;
    static largeFontSize: number = 24;

    //weight
    static lightweightText: number = 200;
    static regularText: number = 300;
    static mediumText: number = 400;
    static semiboldText: number = 500;
    static heavyText: number = 600;

    //color
    static whiteColor: string = '#ffffff';
    static blackColor: string = '#000000';
    static blackColorWithOpacity: string = 'rgba(0, 0, 0, 0.42)';
    static purpleColor: string = '#7A3DF7';
    static greyColorWithOpacity: string = 'rgba(194, 200, 216, 0.42)';
    static darkPurpleColor: string = '#1B044A';
    static inputBackgroundColor: string = 'rgba(218, 220, 224, 0.42)';
    static webBackgroundColor: string = '#F3F5FF';
    static greyBorderColor: string = '#DADCE0';

    //widget elements
    static miniBorderRadius: number = 8;
    static borderRadius: number = 16;
    static simpleWrapperBorderRadius: number = 24;
    static buttonHeight: number = 48;
    static boxShadow: string = `0px 0px 25px -20px ${TemplateConfig.blackColorWithOpacity}`;

    //theme
    static theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 520,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        palette: {
            primary: {
                main: TemplateConfig.blackColor
            },
            secondary: {
                main: TemplateConfig.purpleColor
            },
            error: {
                main: '#d32f2f'
            },
            info: {
                main: '#2196f3'
            },
            warning: {
                main: '#f57c00'
            },
            success: {
                main: '#388e3c'
            },
        },
    });
}