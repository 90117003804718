import { action, makeObservable, observable } from 'mobx';
import { ProductResponse } from '../model/ProductResponse';

class ProductDataStore {
    productData: ProductResponse | null = null;

    constructor() {
        makeObservable(this, {
            productData: observable,
            saveProducts: action,
        });
    }

    saveProducts(value: ProductResponse) {
        this.productData = value;
    }
}

const productDataStore = new ProductDataStore();
export default productDataStore;
