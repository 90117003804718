import { useEffect, useRef, useState } from 'react';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import { toast } from 'react-toastify';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import CustomMuiFileInputComponent from '../../../shared/ui/CustomMuiFileInputComponent';
import { ImageElementWithNullableElements } from '../../model/ImageAttributeRequest';
import StandartTextField from '../../../shared/ui/StandartTextField';
import { ImageAttributeDao } from '../../../leaf/model/ImageAttributeDao';
import { Attribute, PagesDataResponseImpl } from '../../../configuration/model/PagesDataResponse';
import ApplicationConfig from '../../../../core/config/ApplicationConfig';
import ParseFileUtil from '../../../../core/utils/ParseFileUtil';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import PaddingComponent from './PaddingComponent';
import RadiusComponent from './RadiusComponent';

function ImageBlockBuildBottomSheetComponent({ open, onDismiss, saveImage, type, updateValue, item, choosedPage, updatePadding }: { open: boolean; onDismiss: any; saveImage: any; type: string; updateValue?: ImageAttributeDao; item?: Attribute; choosedPage?: PagesDataResponseImpl; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        async function fetchData() {
            if (updateValue) {
                const imageLink = `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage?.page_id}/${item?.id}/`;

                const updateMap: ImageElementWithNullableElements[] = [];

                for (const field of updateValue.images) {
                    const image = await ParseFileUtil(`${imageLink}${field.image}`);
                    updateMap.push({ image: image, url: field.url ?? '' });
                }
                if ((type === 'image' || type === 'banner') && updateMap.length < 1) updateMap.push({ image: null, url: null });
                if (type === 'slider' && updateMap.length < 10) updateMap.push({ image: null, url: null });

                setImageItemsState(updateMap);
            }
            else setImageItemsState([{ image: null, url: null }]);
        }

        fetchData();
    }, [updateValue, choosedPage, item, type]);

    // items
    const [imageItemsState, setImageItemsState] = useState<ImageElementWithNullableElements[]>([{ image: null, url: null }]);

    function handleImageLoader(file: File | null, inputKey: string) {
        //replace value
        const updatedImageItems = [...imageItemsState];
        updatedImageItems[Number(inputKey)] = {
            image: file,
            url: updatedImageItems[Number(inputKey)].url,
        };
        if (!file && type === 'slider') updatedImageItems.splice(Number(inputKey), 1);

        //insert row if need
        if ((type === 'image' || type === 'banner') && updatedImageItems[0].image != null && updatedImageItems.length < 1) updatedImageItems.push({ image: null, url: null })
        if (type === 'slider' && updatedImageItems[updatedImageItems.length - 1].image != null && updatedImageItems.length < 5) updatedImageItems.push({ image: null, url: null })

        //set to global
        setImageItemsState(updatedImageItems);
    }

    function handleTextLoader(text: string, inputKey: string) {
        const updatedImageItems = [...imageItemsState];
        updatedImageItems[Number(inputKey)] = {
            image: updatedImageItems[Number(inputKey)].image,
            url: text,
        };
        setImageItemsState(updatedImageItems);
    }

    function getImageContainer(i: number) {
        return (
            <div key={i}>
                <CustomMuiFileInputComponent
                    value={imageItemsState[i] === null || imageItemsState[i].image === null ? null : imageItemsState[i].image}
                    placeholder="Загрузите картинку"
                    onChange={handleImageLoader}
                    inputKey={i.toString()}
                    aspectRatio={type === 'banner' ? 15 / 4 : undefined}
                    initValue={imageItemsState[i] && imageItemsState[i].image ? imageItemsState[i].image?.name : ''}
                />
                <SimpleRetirementComponent size={10} />
                <StandartTextField placeholder="Введите URL-адрес" maxLength={200} text={imageItemsState[i].url ?? ""} onChange={(event: any) => event ? handleTextLoader(event.target.value, i.toString()) : null} />
                {
                    i === (imageItemsState.length - 1)
                        ? null
                        : <SimpleRetirementComponent />
                }
            </div>
        );
    }

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    // radius
    const radiusField = useRef('');
    const onRadiusChange = (v: string) => { radiusField.current = v };

    return (
        <StandartBottomSheetComponent
            open={open}
            onDismiss={onDismiss}
            onSubmit={!imageItemsState[0].image ? null : () => {
                if (!imageItemsState || !imageItemsState[0]) {
                    toast("Выберите минимум одно изображение", { type: "error" });
                    return;
                }

                async function loadImage() {
                    await saveImage(imageItemsState, type, paddingField.current, radiusField.current);
                    setImageItemsState([{ image: null, url: null }]);
                }

                loadImage();
            }}
            title={type === 'image' ? 'Изображение' : type === 'slider' ? 'Cлайдер' : 'Баннер'}
            description={(type === 'banner' || type === 'image')
                ? 'URL-адрес для картинки (Опционально)'
                : 'Загрузите до десяти картинок. URL-адрес для картинки (Опционально)'}
        >
            {
                (() => {
                    const itemElements: any = [];
                    for (let i = 0; i < imageItemsState.length; i++) {
                        itemElements.push(getImageContainer(i))
                    }
                    return itemElements;
                })()
            }
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
                <RadiusComponent init={updateValue ? updateValue.radius ?? undefined : undefined} onSave={onRadiusChange} />
            </div>
        </StandartBottomSheetComponent >
    );
};

export default ImageBlockBuildBottomSheetComponent;
