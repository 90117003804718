import { TimerAttributeDao } from "../model/TimeAttributeDao";
import Countdown from 'react-countdown';
import { styled } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";

export default function TimerAttributeComponent({ item }: { item: TimerAttributeDao }) {

    const ValueBlock = styled('div')({
        fontSize: window.innerWidth > TemplateConfig.mobileMaxWidth ? '2.5rem' : '2.0rem',
        fontWeight: TemplateConfig.semiboldText,
        margin: window.innerWidth > TemplateConfig.mobileMaxWidth ? '0 8px' : '0 6px',
        color: item.color ?? TemplateConfig.blackColor
    });

    const DescriptionBlock = styled('div')({
        fontSize: '0.8rem',
        margin: window.innerWidth > TemplateConfig.mobileMaxWidth ? '0 8px' : '0 6px',
        color: item.color ?? TemplateConfig.blackColor
    });

    return (
        <Countdown date={item.time} renderer={({ days, hours, minutes, seconds }) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    <ValueBlock>{days.toString().padStart(2, '0')}</ValueBlock>
                    <DescriptionBlock>дней</DescriptionBlock>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <ValueBlock>{hours.toString().padStart(2, '0')}</ValueBlock>
                    <DescriptionBlock>часов</DescriptionBlock>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <ValueBlock>{minutes.toString().padStart(2, '0')}</ValueBlock>
                    <DescriptionBlock>минут</DescriptionBlock>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <ValueBlock>{seconds.toString().padStart(2, '0')}</ValueBlock>
                    <DescriptionBlock>секунд</DescriptionBlock>
                </div>
            </div>
        )} />
    );
}