import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import QRCodeIcon from '../../../core/assets/module/customize/qrcode.svg';
import CopyIcon from "../../../core/assets/module/customize/copy.svg";
import ShareIcon from "../../../core/assets/module/customize/share.svg";
import { PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import { useState } from "react";
import CopyTextUtil from "../../../core/utils/CopyTextUtil";
import SharedListComponent from "../../shared/ui/SharedListComponent";
import PageQRCodeComponent from "./PageQRCodeComponent";

const ParamsComponent = ({ open, onDismiss, page }: { open: boolean; onDismiss: any; page: PagesDataResponseImpl }) => {
    // init
    const items = [
        {
            label: "Поделиться", icon: ShareIcon, onClick: () => {
                if (navigator.share && navigator.canShare(shareData)) navigator.share(shareData);
                else copyText();
            }
        },
        { label: "Скачать QR-код", icon: QRCodeIcon, onClick: () => setQrWindowState(true) },
        { label: "Скопировать ссылку", icon: CopyIcon, onClick: copyText }
    ];

    // qr code
    const [qrWindowState, setQrWindowState] = useState(false);

    // share
    function copyText() {
        CopyTextUtil({ text: `https://${page.domain}/${page.nickname}` })
    }
    const shareData = {
        url: `https://${page.domain}/${page.nickname}`
    };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Мой веб-сайт">
            <SharedListComponent items={items} />
            <PageQRCodeComponent open={qrWindowState} onDismiss={() => setQrWindowState(false)} choosedPage={page} />
        </StandartBottomSheetComponent>
    );
}

export default ParamsComponent;