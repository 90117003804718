import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import { Attribute } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { ImageAttributeRequest, ImageElement } from "../model/ImageAttributeRequest";
import imageCompression from "browser-image-compression";
import loaderStore from "../../shared/store/LoaderStore";

export default async function InsertImageBlockService({ pageId, type, imageAttributeRequest, attributePadding, radius }: { pageId: string; type: string, imageAttributeRequest: ImageAttributeRequest; attributePadding: string; radius: string }) {
    //check access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    //open loader
    loaderStore.showStore();

    //compress all images
    const compressedImages = [];
    for (var imageElement of imageAttributeRequest.images) {
        const compress = await imageCompression(imageElement.image, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        });

        compressedImages.push({
            image: compress,
            url: imageElement.url,
        } as ImageElement);
    }

    //create form data
    const formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('pageId', pageId);
    formData.append('attributeType', type);
    formData.append('attributePadding', attributePadding);

    //add images to form data
    var counter = 1;
    const imagesToLoadJson = [];
    for (var compressedImageElement of compressedImages) {
        const imageName = `img${counter}`;
        formData.append(imageName, compressedImageElement.image, compressedImageElement.image.name);
        imagesToLoadJson.push({ "filename": imageName, "url": compressedImageElement.url });
        counter++;
    }
    formData.append('attributeValue', JSON.stringify({ "images": imagesToLoadJson, radius: radius }));

    //close loader
    loaderStore.hideStore();

    //request
    var result = await GlobalRequestTemplate(
        {
            type: "post",
            link: ApplicationConfig.thisProductBackendAttributeCreatePath,
            formData: formData,
        }
    );

    //if error
    if (result === null) return;

    //notify
    toast("Блок успешно добавлен", {
        type: "success"
    });

    //store
    pagesDataStore.insertAttribute({
        id: result.data.attributeId,
        mark: result.data.mark,
        type: type,
        padding: attributePadding,
        value: JSON.stringify({
            images: result.data.images,
            radius: radius
        }),
    } as Attribute, pageId);
}