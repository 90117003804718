import { InputAdornment } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
import SearchAddressService from "../../service/SearchAddressService";
import { MapCoordinatesResponse } from "../../model/MapCoordinatesResponse";
import SearchIcon from '../../../../core/assets/module/customize/search.svg';
import StandartTextFieldWrapper from "../../../shared/ui/StandartTextFieldWrapper";
import { MapAttributeResponse } from "../../model/MapAttributeResponse";
import SharedListComponent from "../../../shared/ui/SharedListComponent";
import MapUncheckIcon from '../../../../core/assets/module/customize/map-uncheck.svg';
import MapCheckIcon from '../../../../core/assets/module/customize/map-check.svg';
import LinkWrapperComponent from "../../../shared/ui/LinkWrapperComponent";
import { PaddingDao } from "../../../leaf/model/PaddingDao";
import PaddingComponent from "./PaddingComponent";
import SimpleRetirementComponent from "../../../shared/ui/SimpleRetirementComponent";
import RadiusComponent from "./RadiusComponent";


export default function MapBlockBuildBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, pageId, updateValue, updatePadding }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; pageId: string; updateValue?: MapAttributeResponse; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        if (updateValue) {
            handleTextFieldValueChange(updateValue.placeholder);
            setLatitude(updateValue.latitude);
            setLongitude(updateValue.longitude);
        } else {
            handleTextFieldValueChange('');
            setLatitude('');
            setLongitude('');
        }
    }, [updateValue]);

    //init
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [addresses, setAddresses] = useState<{ address: string; code: string; latitude: string; longitude: string }[] | null>(null);
    //init text field
    const textFieldRef = useRef('');
    const handleTextFieldValueChange = (newValue: string) => { textFieldRef.current = newValue; };

    async function handleSearchAddress() {
        //reset
        setLatitude('');
        setLongitude('');

        //check input
        if (textFieldRef.current === null || textFieldRef.current.length < 2) {
            onDismiss();
            toast("Минимально 2 символа", { type: "error" });
            return;
        }

        //query
        var result = await SearchAddressService({ pageId: pageId, address: textFieldRef.current });
        if (!result || !result.status || result.status === 'error') return;

        //fetch result
        const featureMember = (JSON.parse(result.data.map) as MapCoordinatesResponse).response.GeoObjectCollection.featureMember;
        if (featureMember === null || featureMember.length === 0) return;

        //init new addresses
        const newAddresses = [];
        for (var element of featureMember) {
            if (element.GeoObject === null) continue;
            const coordinate = element.GeoObject.Point.pos.split(' ');
            newAddresses.push(
                {
                    address: element.GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country.AddressLine,
                    code: element.GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country.CountryNameCode,
                    longitude: coordinate[0],
                    latitude: coordinate[1],
                }
            );
        }
        setAddresses(newAddresses);
    }

    function handleChooseItem(field: {
        address: string;
        code: string;
        latitude: string;
        longitude: string;
    }) {
        if (field === null) return;
        setAddresses([field]);
        setLatitude(field.latitude);
        setLongitude(field.longitude);
    }

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    // radius
    const radiusField = useRef('');
    const onRadiusChange = (v: string) => { radiusField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (textFieldRef.current === null || latitude === null || longitude === null || latitude.length === 0 || longitude.length === 0) {
                toast("Заполните поле", { type: "error" });
                return;
            }

            saveDirectlyLoading('map', JSON.stringify({ "placeholder": textFieldRef.current, "latitude": latitude, longitude: longitude, radius: radiusField.current }), paddingField.current);
            setAddresses(null);
            setLongitude('');
            setLatitude('');
        }} title={'Карта'} description="Укажите нужный вам адрес и нажмите на иконку поиска. После успешного поиска выберите нужный адрес из списка.">
            <StandartTextFieldWrapper
                placeholder="Введите город, адрес"
                endAdornment={(
                    <InputAdornment position="end">
                        <LinkWrapperComponent onClick={handleSearchAddress}>
                            <img style={{ paddingRight: 0 }} src={SearchIcon} height={24} alt="search icon" />
                        </LinkWrapperComponent>
                    </InputAdornment>
                )}
                handleTextFieldValueChange={handleTextFieldValueChange}
                initText={textFieldRef.current && textFieldRef.current.length > 0 ? textFieldRef.current : null}
            />
            {
                addresses === null || addresses.length === 0
                    ? null
                    : <SharedListComponent
                        items={
                            addresses.map((field) => {
                                return { label: field.address, onClick: () => handleChooseItem(field), icon: longitude.length > 0 ? MapCheckIcon : MapUncheckIcon };
                            })
                        }
                    />
            }
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
                <RadiusComponent init={updateValue ? updateValue.radius ?? undefined : undefined} onSave={onRadiusChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};