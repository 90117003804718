import Flickity from "react-flickity-component";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import TemplateConfig from "../../../core/config/TemplateConfig";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const AppointmentItemImagesViewerComponent = ({ open, onDismiss, images, pageId, itemId }: { open: boolean; onDismiss: any; images: string[]; pageId: string; itemId: string }) => {
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Картинки услуги">
            <Flickity static options={{ "draggable": true, "freeScroll": false, "imagesLoaded": false, "prevNextButtons": false, "friction": 0.8, "selectedAttraction": 0.3, "adaptiveHeight": true }}>
                {
                    images.map((field, index) => (
                        <div
                            key={index}
                            style={{
                                width: '100%',
                                maxWidth: '100vw',
                                marginRight: '20px',
                            }}
                        >
                            <img
                                src={`${ApplicationConfig.thisProductBackendImagesPath}/${pageId}/${itemId}/${field}`}
                                alt="Изображение"
                                style={{
                                    width: '100%',
                                    borderRadius: TemplateConfig.borderRadius,
                                }}
                            />
                        </div>

                    ))
                }
            </Flickity>
        </StandartBottomSheetComponent>
    );
}

export default AppointmentItemImagesViewerComponent;