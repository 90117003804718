import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import customerDataStore from "../store/CustomerDataStore";
import AccessTokenVerifyService from "./AccessTokenVerifyService";
export default async function DataService(forename: string, surname: string) {
    //check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    //create params
    const params = {
        "type": "account",
        "subType": "updateName",
        "accessToken": accessToken,
        "forename": forename,
        "surname": surname
    };

    //request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (result.status === 'succes') {
        toast("Данные успешно обновлены", { type: "success" });
        customerDataStore.updateCustomer(forename, surname);
    }

    return result;
}