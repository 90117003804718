import React from "react";
import { YouTubeEmbed } from "react-social-media-embed";
import { VideoAttributeDao } from "../model/VideoAttributeDao";

const YoutubeAttributeComponent = React.memo(({ item }: { item: VideoAttributeDao }) => {
    return (
        <YouTubeEmbed url={item.placeholder} width="100%" height={270} style={{ borderRadius: item.radius ? Number(item.radius) : undefined }} />
    );
});

export default YoutubeAttributeComponent;