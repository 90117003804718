import { styled } from "@mui/material";
import { toast } from "react-toastify";
import { PagesDataResponseImpl, Attribute } from "../../configuration/model/PagesDataResponse";
import SortAttributeService from "../service/SortAttributeService";
import DraggableItemComponent from "./DraggableItemComponent";
import { SortableContainer, SortableElement, SortEnd } from 'react-18-sortable-hoc';
import pagesDataStore from "../../configuration/store/PagesDataStore";

const reorder = (list: any, startIndex: number, endIndex: number, choosedPage: PagesDataResponseImpl) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const idList: string[] = result.map((attribute: Attribute) => attribute.id);
    SortAttributeService({ idList: idList, pageId: choosedPage.page_id });
    pagesDataStore.updatePage({
        page_id: choosedPage!.page_id,
        nickname: choosedPage!.nickname,
        settings: choosedPage!.settings,
        attributes: result,
        activities: choosedPage!.activities,
        client_orders_count: choosedPage!.client_orders_count,
        watermark: choosedPage!.watermark,
        domain: choosedPage!.domain,
        font: choosedPage!.font
    });
    return result;
};

// draggable
const SortableItem = ({ index, item, setItems, choosedPage }: { index: number; item: any; setItems: any; choosedPage: any }) => {
    const WrappedSortableItem = SortableElement(() => (
        <DraggableItemComponent setItems={setItems} choosedPage={choosedPage} index={index} item={item} />
    ));

    return <WrappedSortableItem index={index} />;
};
const SortableContainerBlock = SortableContainer<{ children: React.ReactNode }>(
    ({ children }: { children: any }) => <span>{children}</span>
);


const DraggableComponent = ({ choosedPage, items, setItems, scrollContainerRef }: { choosedPage: PagesDataResponseImpl; items: Attribute[]; setItems: any; scrollContainerRef: any }) => {
    // logic
    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (oldIndex === undefined || oldIndex === null || newIndex === undefined || newIndex === null) {
            return;
        }

        const newItems = reorder(
            items,
            oldIndex,
            newIndex,
            choosedPage,
        );

        setItems(newItems);
        toast("Блок успешно перемещен", {
            type: "success"
        });

    };

    // style
    const MiddleBlock = styled('div')({
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        position: 'absolute',
        width: '100%',
        height: '100%',
        paddingTop: 1
    });

    return (
        <MiddleBlock ref={scrollContainerRef} className="draggableColumn">
            <SortableContainerBlock onSortEnd={onSortEnd} useDragHandle>
                {items.map((value, index) => (
                    <SortableItem setItems={setItems} choosedPage={choosedPage} key={value.id} index={index} item={value} />
                ))}
            </SortableContainerBlock>
            <style>
                {
                    `
                        .draggableColumn * 
                        *:not(.rhap_main *)  {
                            font-family: ${choosedPage!.font}, sana-serif !important;
                        }
                    `
                }
            </style>
        </MiddleBlock>
    );
}

export default DraggableComponent;
