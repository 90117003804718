import { Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import TelephoneFormateUtil from "../../../core/utils/TelephoneFormateUtil";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import MoreIcon from '../../../core/assets/module/customize/more.svg';
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import OrdersModerateComponent from "./OrdersModerateComponent";
import ordersDataStore from "../store/OrdersDataStore";
import GetOrdersDataService from "../service/GetOrdersDataService";
import { OrderItem, Orders } from "../model/OrdersDataResponse";
import { AppointmentDataResponse } from "../model/AppointmentDataResponse";
import { ru } from 'date-fns/locale';
import { format } from "date-fns";
import UpdateOrderStatusService from "../service/UpdateOrderStatusService";
import SimpleTabComponent from "../../shared/ui/SimpleTabComponent";
import { FormOrderDataResponse } from "../model/FormOrderDataResponse";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { toast } from "react-toastify";
import ClientOrderIcon from '../../../core/assets/module/customize/client-order.svg';
import AppointmentIcon from '../../../core/assets/module/customize/appointment.svg';
import OrderItemComponent from "./OrderItemComponent";
// icons
import FormNameIcon from '../../../core/assets/module/customize/form-name.svg';
import FormMailIcon from '../../../core/assets/module/customize/form-mail.svg';
import FormTelephoneIcon from '../../../core/assets/module/customize/form-telephone.svg';
import FormTextIcon from '../../../core/assets/module/customize/form-text.svg';
import FormCheckboxIcon from '../../../core/assets/module/customize/form-checkbox.svg';

const OrdersBottomSheetComponent = ({ open, onDismiss, pageId }: { open: boolean; onDismiss: any; pageId: string }) => {
    // reload
    function reload() {
        const foundItem = ordersDataStore.ordersData.elements.find((item) => item.pageId === pageId);
        if (foundItem) {
            setActiveItem(foundItem.orders);
            handleActiveTab(activeValue, foundItem.orders);
        }
    }

    // get value
    const [activeItem, setActiveItem] = useState<Orders>({ new: [], success: [], error: [], "in process": [] });
    useEffect(() => {
        async function loadItems() {
            const result = await GetOrdersDataService({ pageId: pageId });
            if (!result) return;
            setActiveItem(result.orders);
            setOrders(result.orders.new);
        }

        if (open && !ordersDataStore.ordersData.elements.find((item) => item.pageId === pageId)) {
            const foundItem = ordersDataStore.ordersData.elements.find((item) => item.pageId === pageId);
            if (foundItem) {
                setActiveItem(foundItem.orders);
                setOrders(foundItem.orders.new)
            }
            else loadItems();
        }
    }, [open, pageId]);
    const [paginationNewState, setPaginationNewState] = useState(true);
    const [paginationErrorState, setPaginationErrorState] = useState(true);
    const [paginationSuccessState, setPaginationSuccessState] = useState(true);
    const [paginationInProcessState, setPaginationInProcessState] = useState(true);
    const [paginationNewPage, setPaginationNewPage] = useState(1);
    const [paginationErrorPage, setPaginationErrorPage] = useState(1);
    const [paginationSuccessPage, setPaginationSuccessPage] = useState(1);
    const [paginationInProcessPage, setPaginationInProcessPage] = useState(1);
    async function loadMore() {
        if (activeValue === tabs[0]) setPaginationNewPage(paginationNewPage + 1);
        if (activeValue === tabs[1]) setPaginationInProcessPage(paginationInProcessPage + 1);
        if (activeValue === tabs[2]) setPaginationSuccessPage(paginationSuccessPage + 1);
        if (activeValue === tabs[3]) setPaginationErrorPage(paginationErrorPage + 1);

        const result = await GetOrdersDataService({
            pageId: pageId,
            page: activeValue === tabs[0]
                ? paginationNewPage + 1
                : activeValue === tabs[1]
                    ? paginationInProcessPage + 1
                    : activeValue === tabs[2]
                        ? paginationSuccessPage + 1
                        : paginationErrorPage + 1,
            push: false,
            onlyRow: activeValue === tabs[0]
                ? 'new'
                : activeValue === tabs[1]
                    ? 'in process'
                    : activeValue === tabs[2]
                        ? 'success'
                        : 'error',
        });
        if (!result || (result.orders.error.length === 0 && result.orders["in process"].length === 0 && result.orders.new.length === 0 && result.orders.success.length === 0)) {
            if (activeValue === tabs[0]) setPaginationNewState(false);
            if (activeValue === tabs[1]) setPaginationInProcessState(false);
            if (activeValue === tabs[2]) setPaginationSuccessState(false);
            if (activeValue === tabs[3]) setPaginationErrorState(false);
            toast("Все заявки уже загружены", { type: "success" });
        } else reload();
    }

    // init
    const [orders, setOrders] = useState<OrderItem[]>([]);
    const tabs = ['Новый', 'В обработке', 'Выполнено', 'Отменено'];
    const [activeValue, setActiveValue] = useState(tabs[0]);
    function handleActiveTab(name: string, item: Orders = activeItem) {
        if (name === tabs[0]) setOrders(item.new);
        if (name === tabs[1]) setOrders(item["in process"]);
        if (name === tabs[2]) setOrders(item.success);
        if (name === tabs[3]) setOrders(item.error);
        setActiveValue(name);
    }

    // moderate
    const [activeOrder, setActiveOrder] = useState<OrderItem | null>(null);
    const [moderateState, setModerateState] = useState(false);
    function onChangeStatus(order: OrderItem, status: string) {
        setModerateState(false);
        UpdateOrderStatusService({
            reload: reload, pageId: pageId, orderItem: order, status:
                status === tabs[0]
                    ? '0'
                    : status === tabs[1]
                        ? '3'
                        : status === tabs[2]
                            ? '2'
                            : '1'
        });
    }

    // widget
    const [orderItemState, setOrderItemState] = useState(false);
    const [activeOrderItem, setActiveOrderItem] = useState<{ title: string, elements: any[] }>({ title: '', elements: [] });
    const OrderWrapperBlock = ({ order, last }: { order: OrderItem, last: boolean }) => {
        return (
            <div>
                <Card variant="outlined" style={{ borderRadius: TemplateConfig.borderRadius, borderColor: TemplateConfig.greyBorderColor }}>
                    <CardContent style={{ padding: 16 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <LinkWrapperComponent onClick={() => order.type === 'appointment' ? createAppointmentBody({ order: order }) : createFormBody({ order: order })}>
                                    <div style={{ display: 'flex' }}>
                                        <img style={{ paddingBottom: 5 }} src={
                                            order.type === 'appointment'
                                                ? AppointmentIcon
                                                : ClientOrderIcon
                                        } alt="order icon" />
                                        <SimpleRetirementComponent size={15} />
                                        <div>
                                            <SimpleTextComponent type='hardMedium'>
                                                #{order.number} Заявка
                                            </SimpleTextComponent>
                                            <SimpleRetirementComponent size={5} />
                                            <SimpleTextComponent>
                                                {format(new Date(order.created_at), "HH:mm, dd MMMM yyyy", { locale: ru })}
                                            </SimpleTextComponent>
                                        </div>
                                    </div>
                                </LinkWrapperComponent>
                            </div>
                            <LinkWrapperComponent onClick={() => { setActiveOrder(order); setModerateState(true); }}>
                                <img draggable={false} src={MoreIcon} alt="more icon" />
                            </LinkWrapperComponent>
                        </div>
                    </CardContent>
                </Card>
                {
                    last
                        ? <SimpleRetirementComponent size={10} />
                        : null
                }
            </div>
        );
    }
    function createAppointmentBody({ order }: { order: OrderItem }) {
        const data = JSON.parse(order.value) as AppointmentDataResponse;
        const dateString = data.day;
        const parts = dateString.split('-');
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

        const dateTimeString = data.orderStartTime;
        const [datePart, timePart] = dateTimeString.split(' ');
        const [day, month, year] = datePart.split('-');
        const [hours, minutes, seconds] = timePart.split(':');
        const formattedDateTime = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);

        setActiveOrderItem({
            title: `Заявка #${order.number}`,
            elements: [
                { title: 'Имя', value: data.name },
                { title: 'Телефон', value: TelephoneFormateUtil({ value: data.telephone }) },
                { title: 'Категория', value: data.category },
                { title: 'Услуга', value: data.item },
                { title: 'Цена', value: PriceFormatUtil(Number(data.price)) },
                { title: 'Цена со скидкой', value: PriceFormatUtil(Number(data.discount ?? 0)) },
                { title: 'Дата', value: format(new Date(formattedDate), 'dd MMMM', { locale: ru }) },
                { title: 'Время', value: format(new Date(formattedDateTime), 'HH:mm', { locale: ru }) }
            ]
        });
        setOrderItemState(true);

    }
    function createFormBody({ order }: { order: OrderItem }) {
        const elements: { title: string; value: string }[] = [];
        const data = JSON.parse(order.value) as FormOrderDataResponse;

        if (data.input) {
            elements.push(...data.input.map((field) => ({
                title: field.title.split('`')[1].split('`')[0],
                value: field.value,
                icon: field.title.split('`')[1].split('`')[0] === 'Имя' ? FormNameIcon
                    : field.title.split('`')[1].split('`')[0] === 'Телефон' ? FormTelephoneIcon
                        : field.title.split('`')[1].split('`')[0] === 'Эл. почта' ? FormMailIcon
                            : FormTextIcon,
            })));
        }

        if (data.option) {
            elements.push(
                ...data.option.map((field) => {
                    const title = field.title && typeof field.title === 'string' ? field.title : '';
                    const value =
                        field.value && Array.isArray(field.value)
                            ? field.value.map((option) => option.option).join(', ')
                            : '';

                    return {
                        icon: FormCheckboxIcon,
                        title: title,
                        value: value,
                    };
                })
            );
        }

        setActiveOrderItem({
            title: `Заявка #${order.number}`,
            elements: elements,
        });

        setOrderItemState(true);
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={'Заявки'}>
            <SimpleTabComponent
                items={
                    tabs.map((tab) => {
                        return { label: tab, onClick: () => handleActiveTab(tab) };
                    })
                }
            />
            <SimpleRetirementComponent />
            {
                orders.map((field, index) => (
                    <div key={index}>
                        <OrderWrapperBlock key={index} order={field} last={index === (orders.length - 1)} />
                        {
                            index === orders.length - 1
                                ? null
                                : <SimpleRetirementComponent size={10} />
                        }
                    </div>
                ))
            }
            {
                (activeValue === tabs[0] && paginationNewState && activeItem.new.length > 0) ||
                    (activeValue === tabs[3] && paginationErrorState && activeItem.error.length > 0) ||
                    (activeValue === tabs[2] && paginationSuccessState && activeItem.success.length > 0) ||
                    (activeValue === tabs[1] && paginationInProcessState && activeItem["in process"].length > 0)
                    ? <div>
                        <SimpleRetirementComponent size={10} />
                        <RaisedButtonComponent
                            placeholder="Показать ещё"
                            onClick={() => loadMore()}
                        />
                    </div>
                    : null
            }
            <OrdersModerateComponent tabs={tabs} open={moderateState} onDismiss={() => setModerateState(false)} onChangeStatus={onChangeStatus} order={activeOrder ?? undefined} />
            <OrderItemComponent open={orderItemState} onDismiss={() => setOrderItemState(false)} item={activeOrderItem} />
        </StandartBottomSheetComponent>
    );
}

export default OrdersBottomSheetComponent;