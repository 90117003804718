import { Card, CardContent } from "@mui/material";
import { useState } from "react";
import ArrowDownIcon from "../../../core/assets/module/leaf/ArrowDownIcon";
import ArrowUpIcon from "../../../core/assets/module/leaf/ArrowUpIcon";
import TemplateConfig from "../../../core/config/TemplateConfig";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { PricelistAttributeDao } from "../model/PricelistAttributeDao";

export default function PricelistAttributeComponent({ item }: { item: PricelistAttributeDao }) {
    // open state
    const [openState, setOpenState] = useState(false);

    return (
        <Card variant="outlined" style={{ borderRadius: TemplateConfig.borderRadius, borderColor: TemplateConfig.inputBackgroundColor, backgroundColor: TemplateConfig.inputBackgroundColor }}>
            <CardContent style={{ padding: 16 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SimpleTextComponent type='title' anotherColor={item.color}>
                        {item.title}
                        {
                            item.value && item.value.length > 0
                                ? `
                                -
                                ${PriceFormatUtil(Number(item.value))}
                                `
                                : ''
                        }
                    </SimpleTextComponent>
                    <LinkWrapperComponent onClick={() => setOpenState(!openState)}>
                        {
                            openState
                                ? <ArrowUpIcon color={item.color} />
                                : <ArrowDownIcon color={item.color} />
                        }
                    </LinkWrapperComponent>
                </div>
                {
                    openState
                        ? <div>
                            <SimpleRetirementComponent size={10} />
                            {
                                item.items.map((field, index) => (
                                    <div key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <SimpleTextComponent anotherColor={item.color}>
                                                {field.title}
                                            </SimpleTextComponent>
                                            <SimpleTextComponent anotherColor={item.color}>
                                                {
                                                    field.value && field.value.length > 0
                                                        ?
                                                        PriceFormatUtil(Number(field.value))
                                                        : ''
                                                }
                                            </SimpleTextComponent>
                                        </div>
                                        {
                                            item.items.length - 1 === index
                                                ? null
                                                : <SimpleRetirementComponent size={5} />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        : null
                }
            </CardContent>
        </Card>
    );
}