import { PaddingDao } from "../model/PaddingDao";
import useIntersectionObserver from "../service/useIntersectionObserver";

export default function AttributeWrapperComponent({ children, padding = {} }: { children: any; padding?: PaddingDao }) {
    // init
    const { top = "8", bottom = "8", left = "24", right = "24" } = padding;
    const computedPaddingLeft = `${Number(left)}px`;
    const computedPaddingRight = `${Number(right)}px`;

    // scroll
    const { targetRef, isVisible } = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    });

    // widget
    return (
        <>
            <div
                ref={targetRef}
                style={{
                    paddingTop: `${Number(top)}px`,
                    paddingBottom: `${Number(bottom)}px`,
                    paddingLeft: computedPaddingLeft,
                    paddingRight: computedPaddingRight,
                }}
                className={`attributeWrapper ${isVisible ? 'visible' : ''}`}
            >
                {children}
            </div>
            <style>
                {
                    `
                        .attributeWrapper {
                            opacity: 0;
                            transform: translateY(20px);
                            transition: transform 0.5s, opacity 0.5s;
                        }
                        .attributeWrapper.visible {
                            transform: translateY(0px);
                            opacity: 1;
                        }
                    `
                }
            </style>
        </>
    );
}
