import { styled } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";

const LinkWrapperComponent = ({ children, onClick, text = true, underline = false, color }: { children: any; onClick: any; text?: boolean; underline?: boolean; color?: string }) => {
  const StyledSpan = styled('span')({
    cursor: 'pointer',
    color: color ? color : text ? TemplateConfig.blackColor : 'none',
    textDecoration: underline ? 'underline' : 'none',
    textUnderlineOffset: 2,
    textDecorationThickness: 1,
    '&:hover': {
      color: color ? color : text ? TemplateConfig.blackColor : 'none',
    }
  });

  return (
    <StyledSpan onClick={onClick}>
      {children}
    </StyledSpan>
  );
}

export default LinkWrapperComponent;