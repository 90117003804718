import { useEffect, useState } from "react";
import TemplateConfig from "../../../../core/config/TemplateConfig";
import RaisedButtonComponent from "../../../shared/ui/RaisedButtonComponent";
import SimpleRetirementComponent from "../../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
import SwipeableComponent from "../../../shared/ui/SwipeableComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { FormControlLabel, Switch } from "@mui/material";
import { format, set } from "date-fns";
import { toast } from "react-toastify";
import AppointmentCategoryUpdateService from "../../service/AppointmentCategoryUpdateService";
import { PagesDataResponseImpl } from "../../../configuration/model/PagesDataResponse";
import RandomStringUtil from "../../../../core/utils/RandomStringUtil";
import { Category } from "../../model/AppointmentValueResponse";
import StandartTextField from "../../../shared/ui/StandartTextField";

const AppointmentCategoryAddComponent = ({ open, onDismiss, choosedPage, reload, appointmentValue, updateItem, updateItemIndex, state }: { open: boolean; onDismiss: any; choosedPage: PagesDataResponseImpl; reload: any; appointmentValue: any; updateItem?: Category | null; updateItemIndex?: number | null; state: number }) => {
    useEffect(() => {
        if (updateItem) {
            setNameText(updateItem.name);
            setWeekState([updateItem.schedule.weekday[0], updateItem.schedule.weekday[1], updateItem.schedule.weekday[2], updateItem.schedule.weekday[3], updateItem.schedule.weekday[4], updateItem.schedule.weekday[5], updateItem.schedule.weekday[6]]);
            setFullTime(updateItem.schedule.startTime === '12:00' && updateItem.schedule.endTime === '11:00');
            setStartTime(set(new Date(), { hours: Number(updateItem.schedule.startTime.split(':')[0]), minutes: 0, seconds: 0 }));
            setEndTime(set(new Date(), { hours: Number(updateItem.schedule.endTime.split(':')[0]), minutes: 0, seconds: 0 }));
            return;
        }

        setNameText('');
        setWeekState([false, false, false, false, false, false, false]);
        setFullTime(false);
        setStartTime(null);
        setEndTime(null);
    }, [updateItem, state]);

    // name field
    const [nameText, setNameText] = useState('');

    // weeks
    const [weekState, setWeekState] = useState([false, false, false, false, false, false, false]);
    function handleWeekChange(id: number, value: boolean) {
        const cloneWeekState = weekState;
        cloneWeekState[id] = value;
        setWeekState([cloneWeekState[0], cloneWeekState[1], cloneWeekState[2], cloneWeekState[3], cloneWeekState[4], cloneWeekState[5], cloneWeekState[6]]);
    }
    const weekElements = [
        { "label": "Пн", value: weekState[0] },
        { "label": "Вт", value: weekState[1] },
        { "label": "Ср", value: weekState[2] },
        { "label": "Чт", value: weekState[3] },
        { "label": "Пт", value: weekState[4] },
        { "label": "Сб", value: weekState[5] },
        { "label": "Вс", value: weekState[6] }
    ];

    // time
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [fullTime, setFullTime] = useState(false);
    function fullTimeHandle(value: boolean) {
        setFullTime(value);
        if (!value) {
            setStartTime(null);
            setEndTime(null);
            return;
        }

        setStartTime(set(new Date(), { hours: 12, minutes: 0, seconds: 0 }));
        setEndTime(set(new Date(), { hours: 11, minutes: 0, seconds: 0 }));
    }
    const TimeBlock = () => {
        return (
            <div>
                <FormControlLabel
                    control={<Switch
                        color="secondary"
                        checked={fullTime}
                        onChange={(_event, checked) => fullTimeHandle(checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />}
                    label="Круглосуточно"
                    labelPlacement="start"
                    style={{
                        marginLeft: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                />
                {
                    fullTime
                        ? null
                        : <div>
                            <SimpleRetirementComponent size={10} />
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 3.5 }}>
                                    <DatePicker
                                        placeholderText="с 00:00"
                                        selected={startTime}
                                        onChange={(date) => !date ? null : setStartTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Время"
                                        dateFormat="HH:mm"
                                        locale={ru}
                                        popperPlacement="top-start"
                                        className="date-picker-custom-style"
                                    />
                                </div>
                                <div style={{ flex: 1 }}></div>
                                <div style={{ flex: 3.5 }}>
                                    <DatePicker
                                        placeholderText="до 00:00"
                                        selected={endTime}
                                        onChange={(date) => !date ? null : setEndTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Время"
                                        dateFormat="HH:mm"
                                        locale={ru}
                                        popperPlacement="top-start"
                                        className="date-picker-custom-style full-width"
                                    />
                                </div>
                                <div style={{ flex: 5 }}></div>
                            </div>
                        </div>
                }
            </div>
        );
    }

    // return
    return (
        <StandartBottomSheetComponent title="Категория" open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!startTime || !endTime || startTime === endTime) {
                toast("Заполните время правильно. Они не могут совпадать", { type: "error" });
                return;
            }

            if (nameText.length === 0) {
                toast("Заполните имя категории", { type: "error" });
                return;
            }

            AppointmentCategoryUpdateService({
                appointmentValue: appointmentValue,
                reload: reload,
                action: updateItemIndex !== null && updateItem ? 'update' : 'insert',
                choosedPage: choosedPage,
                category: {
                    id: updateItemIndex !== null && updateItem ? updateItem.id : RandomStringUtil(36),
                    name: nameText,
                    schedule: {
                        startTime: format(startTime, "HH:mm"),
                        endTime: format(endTime, "HH:mm"),
                        weekday: weekState
                    }
                },
                index: updateItemIndex !== null && updateItem ? updateItemIndex : undefined,
            });

            onDismiss()
        }}>
            <StandartTextField
                placeholder="Наименование"
                maxLength={100}
                text={nameText}
                onChange={(event: any) => !event ? null : setNameText(event.target.value)}
            />
            <SimpleRetirementComponent size={10} />
            <SwipeableComponent>
                {
                    weekElements.map((field, index) => (
                        <div key={index} style={{ marginRight: 10, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                            <RaisedButtonComponent
                                backgroundColor={field.value ? TemplateConfig.purpleColor : TemplateConfig.greyBorderColor}
                                placeholder={field.label}
                                onClick={() => handleWeekChange(index, !field.value)}
                                miniButton
                            />
                        </div>
                    ))
                }
            </SwipeableComponent>
            <SimpleRetirementComponent size={10} />
            <TimeBlock />
        </StandartBottomSheetComponent>
    );
}

export default AppointmentCategoryAddComponent;