import { useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "./LinkWrapperComponent";
import SimpleRetirementComponent from "./SimpleRetirementComponent";
import SimpleTextComponent from "./SimpleTextComponent";
import SwipeableComponent from "./SwipeableComponent";

const SimpleTabComponent = ({ items }: { items: { label: string, onClick: any }[] }) => {
    const [activeValue, setActiveValue] = useState(items[0].label);

    return (
        <div style={{
            borderBottom: `1px solid ${TemplateConfig.greyBorderColor}`
        }}>
            <SwipeableComponent>
                {items.map((field, index) => (
                    <div key={index} style={{ flex: 1, textAlign: 'center', width: '100%', marginRight: 5, marginLeft: 5, whiteSpace: 'nowrap' }}>
                        <LinkWrapperComponent onClick={() => {
                            field.onClick();
                            setActiveValue(field.label);
                        }}>
                            <SimpleTextComponent type={activeValue === field.label ? 'hardMediumPurple' : 'hardMedium'}>
                                {field.label}
                            </SimpleTextComponent>
                            <SimpleRetirementComponent size={10} />
                        </LinkWrapperComponent>
                    </div>
                ))}
            </SwipeableComponent>
        </div>
    );
}

export default SimpleTabComponent;