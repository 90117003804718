import { useState } from 'react';
import StandartBottomSheetComponent from '../../shared/ui/StandartBottomSheetComponent';
import MenuBottomSheetComponent from './MenuBottomSheetComponent';
import SimpleRetirementComponent from '../../shared/ui/SimpleRetirementComponent';
import LogoutService from '../../authenticate/service/LogoutService';
import { useNavigate } from 'react-router-dom';
import RouteNamespaces from '../../../core/route/RouteNamespaces';
import { observer } from 'mobx-react-lite';
import ConfirmBottomSheetComponent from '../../shared/ui/ConfirmModalComponent';
import MenuIcon from '../../../core/assets/module/configuration/menu.svg';
import VWebIcon from '../../../core/assets/module/configuration/icon-vweb.svg';
import LinkWrapperComponent from '../../shared/ui/LinkWrapperComponent';
import TemplateConfig from '../../../core/config/TemplateConfig';

function ConfigurationHeaderComponent() {
    const navigate = useNavigate();
    const [menuOpenState, setMenuState] = useState(false);
    const [exitConfirmState, setExitConfirmState] = useState(false);
    function logoutHandle() {
        setExitConfirmState(false);
        LogoutService();
        navigate(RouteNamespaces.authenticate);
    }
    function showConfirm() {
        setMenuState(false);
        setExitConfirmState(true);
    }

    return (
        <div>
            <SimpleRetirementComponent size={24} />
            <div style={{
                borderBottom: `1px solid ${TemplateConfig.greyBorderColor}`,
                paddingBottom: 20
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: `0px ${TemplateConfig.longPadding}px`
                }}>
                    <img src={VWebIcon} height={24} alt="vweb icon" />
                    <LinkWrapperComponent onClick={() => setMenuState(true)}>
                        <img src={MenuIcon} alt="menu icon" />
                    </LinkWrapperComponent>
                </div>
            </div>
            <StandartBottomSheetComponent open={menuOpenState} onDismiss={() => setMenuState(false)} title={'Меню'}>
                <MenuBottomSheetComponent showConfirm={showConfirm} navigate={navigate} />
            </StandartBottomSheetComponent>
            <ConfirmBottomSheetComponent
                open={exitConfirmState}
                onDismiss={() => setExitConfirmState(false)}
                onSubmit={logoutHandle}
                description="Вы уверены, что хотите выйти из аккаунта?"
                title="Предупреждение"
            />
        </div>
    );
}

export default observer(ConfigurationHeaderComponent);