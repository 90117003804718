import ApplicationConfig from "../../../core/config/ApplicationConfig";
import TemplateConfig from "../../../core/config/TemplateConfig";
import { ImageAttributeDao } from "../model/ImageAttributeDao";
import ClickableAttributeComponent from "./ClickableAttributeComponent";

export default function ImageAttributeComponent({ item, pageId, attributeId, customize = false }: { item: ImageAttributeDao; pageId: string; attributeId: string; customize?: boolean }) {
    return (
        <div>
            {item.images.map((image, index) => (
                <ClickableAttributeComponent key={index} url={image.url}>
                    <img
                        src={`${ApplicationConfig.thisProductBackendImagesPath}/${pageId}/${attributeId}/${image.image}`}
                        alt="Изображение"
                        style={{
                            width: '100%',
                            borderRadius: item.radius ? Number(item.radius) : TemplateConfig.borderRadius,
                        }}
                    />
                </ClickableAttributeComponent>
            ))}
        </div>
    );
}