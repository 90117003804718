import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { CreatePageResponse } from "../model/CreatePageResponse";
import { PagesDataResponseImpl } from "../model/PagesDataResponse";
import pagesDataStore from "../store/PagesDataStore";

export default async function CreatePageService({ nickname, domain }: { nickname: string; domain: string }) {
    if (pagesDataStore != null && pagesDataStore.pagesData != null && pagesDataStore.pagesData!.length > 500) {
        toast("Максимальное значение 25 веб-сайтов", {
            type: "error"
        });
        return;
    }

    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "type": "page",
        "subType": "create",
        "accessToken": accessToken,
        "nickname": nickname,
        "domain": domain
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    //if error
    if (result === null) return;

    //add to store
    var pageId = (result.data as CreatePageResponse).page_id;
    if (pageId === null) return;
    pagesDataStore.insertPage({
        nickname: nickname,
        page_id: pageId,
        settings: null,
        attributes: [],
        activities: [],
        client_orders_count: '0',
        watermark: '1',
        domain: domain,
        font: 'Inter'
    } as PagesDataResponseImpl)

    //notify
    toast("Веб-сайт успешно создан", {
        type: "success"
    });
}