import { InputAdornment, InputLabel } from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ImageAddIcon from '../../../core/assets/module/customize/image-add.svg';
import ImageDeleteIcon from '../../../core/assets/module/customize/image-delete.svg';
import TemplateConfig from '../../../core/config/TemplateConfig';
import ImageCropperComponent from './ImageCropperComponent';
import LinkWrapperComponent from './LinkWrapperComponent';

export default function CustomMuiFileInputComponent({ value, placeholder, onChange, aspectRatio, inputKey, initValue }: { value: File | null; placeholder: string; onChange: any; aspectRatio?: number; inputKey: string; initValue?: string }) {
    // init value
    useEffect(() => {
        if (initValue && initValue.length > 1) setFileSrcState(initValue);
        else setFileSrcState('');
    }, [initValue]);

    //init
    const [cropperState, setCropperState] = useState(false);
    const [fileSrcState, setFileSrcState] = useState<string>('');

    return (
        <div>
            <MuiFileInput
                value={value}
                placeholder={placeholder}
                onChange={(value) => {
                    if (!value) {
                        onChange(null, inputKey);
                        return;
                    }

                    if (value.size <= 10 * 1024 * 1024) {
                        if (value.type.startsWith('image/')) {
                            const blob = new Blob([value], { type: value.type });
                            const objectURL = URL.createObjectURL(blob);
                            setFileSrcState(objectURL);
                            setCropperState(true);
                        } else {
                            toast("Некорректный формат файла", {
                                type: "error"
                            });
                            return;
                        }
                    } else {
                        toast("Файл превышает размер 10мб", {
                            type: "error"
                        });
                        return;
                    }
                }}
                fullWidth
                rows="1"
                variant="standard"
                InputProps={{
                    startAdornment: null,
                    endAdornment: (<InputAdornment style={{}} position="end" component="div" disablePointerEvents={fileSrcState.length === 0}>
                        <InputLabel style={{ paddingRight: 16 }}>
                            {
                                fileSrcState.length === 0
                                    ? <img src={ImageAddIcon} alt="img add icon" />
                                    : (<LinkWrapperComponent onClick={() => {
                                        onChange(null, inputKey);
                                        setFileSrcState('');
                                    }}>
                                        <img src={ImageDeleteIcon} alt="img delete icon" />
                                    </LinkWrapperComponent>)
                            }
                        </InputLabel>
                    </InputAdornment>),
                    disableUnderline: true,
                    sx: { borderRadius: 4, backgroundColor: TemplateConfig.inputBackgroundColor, color: TemplateConfig.blackColor, paddingLeft: 2, fontWeight: TemplateConfig.regularText },
                }}
                inputProps={{
                    accept: '.png, .jpg, .jpeg',
                    style: { padding: "12.5px 16px" }
                }} />
            < ImageCropperComponent inputKey={inputKey} open={cropperState} onDismiss={() => setCropperState(false)} onSubmit={onChange} image={fileSrcState} aspectRatio={aspectRatio} />
        </div >
    );
}