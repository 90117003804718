import RouteNamespaces from "../../../core/route/RouteNamespaces";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const OrderNoAccessComponent = ({ open, onDismiss, navigate }: { open: boolean; onDismiss: any; navigate: any }) => {
    return (
        <StandartBottomSheetComponent title="Проибретите новый тариф" open={open} onDismiss={onDismiss}>
            <div style={{ textAlign: 'center' }}>
                <SimpleTextComponent type='paragraph'>
                    Этот функционал недоступен на вашим тарифе.
                </SimpleTextComponent>
            </div>
            <SimpleRetirementComponent />
            <RaisedButtonComponent
                onClick={() => navigate(RouteNamespaces.market)}
                placeholder="Посмотреть другие тарифы"
            />
        </StandartBottomSheetComponent>
    );
}

export default OrderNoAccessComponent;