import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { PagesDataResponse } from "../model/PagesDataResponse";
import pagesDataStore from "../store/PagesDataStore";

export default async function GetPagesDataService() {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "type": "page",
        "subType": "get",
        "accessToken": accessToken,
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (result) pagesDataStore.saveTotalPagesData(result.data.pages as PagesDataResponse);
}