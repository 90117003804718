import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import CustomizeComponent from '../../module/customize/ui/CustomizeComponent';
import RouteNamespaces from './RouteNamespaces';
import SimpleWrapperComponent from '../../module/shared/ui/SimpleWrapperComponent';
import PageNotFoundComponent from '../../module/shared/ui/PageNotFoundComponent';
import ConfigurationComponent from '../../module/configuration/ui/ConfigurationComponent';
import customerDataStore from '../../module/authenticate/store/CustomerDataStore';
import { observer } from 'mobx-react-lite';
import LeafComponent from '../../module/leaf/ui/LeafComponent';
import AuthenticateComponent from '../../module/authenticate/ui/AuthenticateComponent';
import ProfileComponent from '../../module/authenticate/ui/ProfileComponent';
import MarketComponent from '../../module/market/ui/MarketComponent';

function RouteComponent() {
    const PublicRoute = ({ children }: { children: any }) => {
        return customerDataStore.customerData == null ? children : <Navigate to={RouteNamespaces.configuration} />;
    };

    const PrivateRoute = ({ children }: { children: any }) => {
        return customerDataStore.customerData != null ? children : <Navigate to={RouteNamespaces.authenticate} />;
    };

    return (
        <BrowserRouter>
            {/* Router with rules */}
            <Routes>
                <Route path={RouteNamespaces.error} element={<PageNotFoundComponent />} />
                <Route path={RouteNamespaces.authenticate} element={<PublicRoute><SimpleWrapperComponent background child={<AuthenticateComponent />} completelyWrap={false} /></PublicRoute>} />
                <Route path={RouteNamespaces.cabinet} element={<PrivateRoute><SimpleWrapperComponent child={<ProfileComponent />} /></PrivateRoute>} />
                <Route path={RouteNamespaces.market} element={<PrivateRoute><SimpleWrapperComponent child={<MarketComponent />} /></PrivateRoute>} />
                <Route path={RouteNamespaces.configuration} element={<PrivateRoute><SimpleWrapperComponent child={<ConfigurationComponent />} /></PrivateRoute>} />
                <Route path={RouteNamespaces.customize} element={<PrivateRoute><SimpleWrapperComponent child={<CustomizeComponent />} /></PrivateRoute>} />
                <Route path={RouteNamespaces.leaf} element={<SimpleWrapperComponent child={<LeafComponent />} />} />
                <Route path={RouteNamespaces.another} element={<PageNotFoundComponent />} />
            </Routes>
        </BrowserRouter>
    );
}

export default observer(RouteComponent);