import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const OrderItemComponent = ({ open, onDismiss, item }: { open: boolean; onDismiss: any; item: { elements: { title: string, value: string, icon?: string }[], title: string } }) => {
    return (
        <StandartBottomSheetComponent title={item.title} open={open} onDismiss={onDismiss}>
            {
                item.elements.map((field, index) => (
                    <div key={index}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', wordBreak: "break-word" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {field.icon && <>
                                    <img src={field.icon} alt="icon" />
                                    <SimpleRetirementComponent size={16} />
                                </>}
                                <SimpleTextComponent type='hardMedium' >
                                    {field.title}
                                </SimpleTextComponent>
                            </div>
                            <div style={{ flex: 1, wordBreak: "break-word", textAlign: 'right' }}>
                                <SimpleTextComponent>
                                    {field.value}
                                </SimpleTextComponent>
                            </div>

                        </div>
                        {
                            item.elements.length - 1 === index
                                ? null
                                : <SimpleRetirementComponent size={10} />
                        }
                    </div>
                ))
            }
        </StandartBottomSheetComponent >
    );
}

export default OrderItemComponent;