import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { useState } from "react";
import ColorPickerComponent from "../../shared/ui/ColorPickerComponent";
import BackgroundImageComponent from "./BackgroundImageComponent";
import WebTemplateComponent from "./WebTemplateComponent";
import WebsiteSettingsService from "../service/WebsiteSettingsService";
import { PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import SetTemplateService from "../service/SetTemplateService";
import productPurchasedStore from "../../purchase/store/ProductPurchasedStore";
import ColorIcon from '../../../core/assets/module/customize/color.svg';
import BackgroundIcon from '../../../core/assets/module/customize/background.svg';
import FontIcon from '../../../core/assets/module/customize/font.svg';
import VerifyIcon from '../../../core/assets/module/customize/verify.svg';
import OrderNoAccessComponent from "../../purchase/ui/OrderNoAccessComponent";
import SharedListComponent from "../../shared/ui/SharedListComponent";
import FontService from "../service/FontService";
import FontComponent from "./FontComponent";

const SettingsEditComponent = ({ open, onDismiss, choosedPage, reloadWeb, navigate }: { open: boolean; onDismiss: any; choosedPage: PagesDataResponseImpl; reloadWeb: any; navigate: any }) => {
    // init
    const colorCurrent: string = choosedPage.settings && JSON.parse(choosedPage.settings).color ? JSON.parse(choosedPage.settings).color : null;
    const [backgroundCurrent, setBackgroundCurrent] = useState(choosedPage.settings && JSON.parse(choosedPage.settings).background ? JSON.parse(choosedPage.settings).background : '');
    function calculateAction(label: string) {
        return !productPurchasedStore.purchasedData || !productPurchasedStore.purchasedData.settings.includes(label)
            ? <img style={{ justifySelf: 'end' }} src={VerifyIcon} alt="verify icon" />
            : null;
    }
    const items = [
        {
            label: "Шрифт",
            icon: FontIcon,
            onClick: () => setFontState(true)
        },
        {
            label: "Цвет фона",
            icon: ColorIcon,
            onClick: () => calculateAction("color") ? setOrderAccessState(true) : setColorWindowState(true),
            action: calculateAction("color")
        },
        {
            label: "Изображение фона",
            icon: BackgroundIcon,
            onClick: () => calculateAction("background") ? setOrderAccessState(true) : setBackgroundWindowState(true),
            action: calculateAction("background")
        },
        // {
        //     label: "Шаблоны сайта",
        //     icon: TemplateIcon,
        //     onClick: () => calculateAction("template") ? setOrderAccessState(true) : setThemeWindowState(true),
        //     action: calculateAction("template")
        // }
    ];

    // reload
    function reloadPage(settings: string) {
        choosedPage.settings = settings;
        reloadWeb();
    }

    // color
    const [colorWindowState, setColorWindowState] = useState(false);
    const [colorState, setColorState] = useState(colorCurrent ?? '#ffffff');
    async function onSaveColor(color: string) {
        const result = await WebsiteSettingsService({ pageId: choosedPage.page_id, type: 'color', color: color });
        setColorState(color);
        if (result) reloadPage(result);
    }

    // background
    const [backgroundWindowState, setBackgroundWindowState] = useState(false);
    async function onSaveBackground(file: File | null) {
        const result = await WebsiteSettingsService({ pageId: choosedPage.page_id, type: 'background', background: file });
        if (result) reloadPage(result);
        setBackgroundCurrent(file ? file.name : '');
    }

    // theme
    const [themeWindowState, setThemeWindowState] = useState(false);
    async function onSaveTheme(value: string) {
        const result = await SetTemplateService({ pageId: choosedPage.page_id, type: value });
        if (result) choosedPage.settings = result.settings;
        if (result) reloadWeb(result);
    }

    // font
    const [fontState, setFontState] = useState(false);
    async function onFontUpdate(value: string) {
        const result = await FontService({ pageId: choosedPage.page_id, font: value });
        choosedPage.font = value;
        if (result) reloadWeb();
        onDismiss();
    }


    // order access
    const [orderAccessState, setOrderAccessState] = useState(false);

    return (
        <StandartBottomSheetComponent title="Стиль" open={open} onDismiss={onDismiss}>
            <SharedListComponent items={items} action />
            <ColorPickerComponent open={colorWindowState} onDismiss={() => setColorWindowState(false)} colorState={colorState} onSave={onSaveColor} />
            <BackgroundImageComponent choosedPage={choosedPage} open={backgroundWindowState} onDismiss={() => setBackgroundWindowState(false)} onSave={onSaveBackground} updateValue={backgroundCurrent} />
            <WebTemplateComponent open={themeWindowState} onDismiss={() => setThemeWindowState(false)} onSave={onSaveTheme} />
            <OrderNoAccessComponent open={orderAccessState} onDismiss={() => setOrderAccessState(false)} navigate={navigate} />
            <FontComponent open={fontState} onDismiss={() => setFontState(false)} onSave={onFontUpdate} />
        </StandartBottomSheetComponent>
    );
}

export default SettingsEditComponent;