import TemplateConfig from "../../../config/TemplateConfig";

const InstagramIcon = ({ background = TemplateConfig.blackColor, color = TemplateConfig.whiteColor }: { background?: string; color?: string }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill={background} />
            <path d="M20 11.8025C22.67 11.8025 22.9867 11.8125 24.0417 11.8608C26.7517 11.9842 28.0175 13.27 28.1408 15.96C28.1892 17.0142 28.1983 17.3308 28.1983 20.0008C28.1983 22.6717 28.1883 22.9875 28.1408 24.0417C28.0167 26.7292 26.7542 28.0175 24.0417 28.1408C22.9867 28.1892 22.6717 28.1992 20 28.1992C17.33 28.1992 17.0133 28.1892 15.9592 28.1408C13.2425 28.0167 11.9833 26.725 11.86 24.0408C11.8117 22.9867 11.8017 22.6708 11.8017 20C11.8017 17.33 11.8125 17.0142 11.86 15.9592C11.9842 13.27 13.2467 11.9833 15.9592 11.86C17.0142 11.8125 17.33 11.8025 20 11.8025ZM20 10C17.2842 10 16.9442 10.0117 15.8775 10.06C12.2458 10.2267 10.2275 12.2417 10.0608 15.8767C10.0117 16.9442 10 17.2842 10 20C10 22.7158 10.0117 23.0567 10.06 24.1233C10.2267 27.755 12.2417 29.7733 15.8767 29.94C16.9442 29.9883 17.2842 30 20 30C22.7158 30 23.0567 29.9883 24.1233 29.94C27.7517 29.7733 29.775 27.7583 29.9392 24.1233C29.9883 23.0567 30 22.7158 30 20C30 17.2842 29.9883 16.9442 29.94 15.8775C29.7767 12.2492 27.7592 10.2275 24.1242 10.0608C23.0567 10.0117 22.7158 10 20 10ZM20 14.865C17.1642 14.865 14.865 17.1642 14.865 20C14.865 22.8358 17.1642 25.1358 20 25.1358C22.8358 25.1358 25.135 22.8367 25.135 20C25.135 17.1642 22.8358 14.865 20 14.865ZM20 23.3333C18.1592 23.3333 16.6667 21.8417 16.6667 20C16.6667 18.1592 18.1592 16.6667 20 16.6667C21.8408 16.6667 23.3333 18.1592 23.3333 20C23.3333 21.8417 21.8408 23.3333 20 23.3333ZM25.3383 13.4625C24.675 13.4625 24.1375 14 24.1375 14.6625C24.1375 15.325 24.675 15.8625 25.3383 15.8625C26.0008 15.8625 26.5375 15.325 26.5375 14.6625C26.5375 14 26.0008 13.4625 25.3383 13.4625Z" fill={color} />
        </svg>
    );
}

export default InstagramIcon;