import TemplateConfig from "../../../config/TemplateConfig";

const TelegramIcon = ({ background = TemplateConfig.blackColor, color = TemplateConfig.whiteColor }: { background?: string; color?: string }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill={background} />
            <path d="M15.2825 14.203L23.7725 11.373C27.5825 10.103 29.6525 12.183 28.3925 15.993L25.5625 24.483C23.6625 30.193 20.5425 30.193 18.6425 24.483L17.8025 21.963L15.2825 21.123C9.5725 19.223 9.5725 16.113 15.2825 14.203Z" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" fill={background} />
            <path d="M17.9922 21.533L21.5722 17.943" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" fill={background} />
        </svg>
    );
}

export default TelegramIcon;
