import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import SimpleTextComponent from '../../shared/ui/SimpleTextComponent';
import SimpleRetirementComponent from '../../shared/ui/SimpleRetirementComponent';
import { toast } from 'react-toastify';
import PagesListComponent from './PagesListComponent';
import NewBraendBottomSheetComponent from './NewBraendBottomSheetComponent';
import CreatePageService from '../service/CreatePageService';
import DeletePageService from '../service/DeletePageService';
import { PagesDataResponseImpl } from '../model/PagesDataResponse';
import AddIcon from '../../../core/assets/module/customize/add.svg';
import OrderNoAccessComponent from '../../purchase/ui/OrderNoAccessComponent';
import pagesDataStore from '../store/PagesDataStore';
import productPurchasedStore from '../../purchase/store/ProductPurchasedStore';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ConfigurationWrapperComponent from './ConfigurationWrapperComponent';

function ConfigurationComponent() {
  //init
  const navigate = useNavigate();
  const [pageBuildStateOpen, setPageBuildComponentState] = useState(false);
  const [loadingState, updateLoadingState] = useState(false);

  // page limit
  const [orderState, setOrderState] = useState(false);

  async function onBuildPage(nickname: string, domain: string) {
    setPageBuildComponentState(false);

    if (pagesDataStore.pagesData && productPurchasedStore.purchasedData && pagesDataStore.pagesData.length === productPurchasedStore.purchasedData.pageLimit) {
      setOrderState(true);
      return;
    }

    if (nickname == null || nickname.length === 0) {
      toast("Не заполнено", {
        type: "error"
      });
      return;
    }

    if (nickname.length < 3) {
      toast("Никнейм должен содержать мин. 3 символа", {
        type: "error"
      });
      return;
    }

    await CreatePageService({ nickname: nickname, domain: domain });
    updateLoadingState(!loadingState);
  }

  async function onDeletePage({ element }: { element: PagesDataResponseImpl }) {
    await DeletePageService({ page: element });
    updateLoadingState(!loadingState);
  }

  return (
    <ConfigurationWrapperComponent>
      <Helmet title={'Веб-сайты'} />
      <SimpleRetirementComponent size={30} />
      <div style={{ textAlign: 'center' }}>
        <SimpleTextComponent type="header">Веб-сайты</SimpleTextComponent></div>
      <SimpleRetirementComponent size={30} />
      <PagesListComponent onDeletePage={onDeletePage} checkNotFreeAccount={() => setOrderState(true)} freeAccount={!productPurchasedStore.purchasedData || productPurchasedStore.purchasedData.expiredAt.length === 1} />
      <SimpleRetirementComponent size={10} />
      <RaisedButtonComponent
        placeholder='Новый веб-сайт'
        onClick={() => setPageBuildComponentState(true)}
        icon={AddIcon}
      />
      <NewBraendBottomSheetComponent open={pageBuildStateOpen} onDismiss={() => setPageBuildComponentState(false)} onSave={onBuildPage} />
      <OrderNoAccessComponent open={orderState} onDismiss={() => setOrderState(false)} navigate={navigate} />
    </ConfigurationWrapperComponent>
  );
}

export default observer(ConfigurationComponent);