import { action, makeObservable, observable } from 'mobx';
import { Activity, Attribute, PagesDataResponse, PagesDataResponseImpl } from '../model/PagesDataResponse';

class PagesDataStore {
    pagesData: PagesDataResponse | null = null;

    constructor() {
        makeObservable(this, {
            pagesData: observable,
            saveTotalPagesData: action,
            insertPage: action,
            deletePage: action,
            updatePage: action,
            insertAttribute: action,
            updateAttribute: action,
            deleteAttribute: action,
            insertActivites: action,
            updateSettings: action,
            updateNickname: action,
            updateWatermark: action,
            updateFont: action
        });
    }

    saveTotalPagesData(value: PagesDataResponse) {
        this.pagesData = value;
    }

    insertPage(value: PagesDataResponseImpl) {
        if (this.pagesData === null) {
            pagesDataStore.saveTotalPagesData([value]);
            return;
        }
        this.pagesData.push(value);
    }

    deletePage(value: PagesDataResponseImpl) {
        if (this.pagesData === null) return;
        this.pagesData = this.pagesData.filter(element => element !== value);
    }

    updatePage(value: PagesDataResponseImpl) {
        if (this.pagesData === null) return;
        this.pagesData = [...this.pagesData.filter(element => element.page_id !== value.page_id), value];
    }

    insertAttribute(attribute: Attribute, pageId: string) {
        if (this.pagesData === null) return;
        const updatedPagesData = this.pagesData.map(item => {
            if (item.page_id === pageId) {
                const updatedAttributes = [...item.attributes, attribute];
                return { ...item, attributes: updatedAttributes };
            }
            return item;
        });

        this.pagesData = updatedPagesData;
    }

    updateAttribute(attribute: Attribute, pageId: string) {
        if (this.pagesData === null) return;

        const updatedPagesData = this.pagesData.map(item => {
            if (item.page_id === pageId) {
                const updatedAttributes = item.attributes.map(attr => {
                    if (attr.id === attribute.id) {
                        return attribute;
                    }
                    return attr;
                });
                return { ...item, attributes: updatedAttributes };
            }
            return item;
        });

        this.pagesData = updatedPagesData;
    }

    deleteAttribute(attributeId: string, pageId: string) {
        if (this.pagesData === null) return;
        const updatedPagesData = this.pagesData.map(item => {
            if (item.page_id === pageId) {
                const updatedAttributes = item.attributes.filter(attr => attr.id !== attributeId);
                return { ...item, attributes: updatedAttributes };
            }
            return item;
        });


        this.pagesData = updatedPagesData;
    }

    insertActivites(pageId: string, activity: Activity) {
        if (this.pagesData === null) return;
        const updatedPagesData = this.pagesData.map(item => {
            if (item.page_id === pageId) {
                const updatedActivities = [...item.activities.filter(actv => actv.id !== activity.id), activity];
                return { ...item, activities: updatedActivities };
            }
            return item;
        });

        this.pagesData = updatedPagesData;
    }

    updateSettings(settings: string, pageId: string) {
        if (this.pagesData === null) return;
        const updatedPagesData = this.pagesData.map(item => {
            if (item.page_id === pageId) return { ...item, settings: settings };
            return item;
        });

        this.pagesData = updatedPagesData;
    }

    updateNickname(nickname: string, domain: string, pageId: string) {
        if (this.pagesData === null) return;
        const updatedPagesData = this.pagesData.map(item => {
            if (item.page_id === pageId) return { ...item, nickname: nickname, domain: domain };
            return item;
        });

        this.pagesData = updatedPagesData;
    }

    updateWatermark(watermark: string, pageId: string) {
        if (this.pagesData === null) return;
        const updatedPagesData = this.pagesData.map(item => {
            if (item.page_id === pageId) return { ...item, watermark: watermark };
            return item;
        });

        this.pagesData = updatedPagesData;
    }

    updateFont(font: string, pageId: string) {
        if (this.pagesData === null) return;
        const updatedPagesData = this.pagesData.map(item => {
            if (item.page_id === pageId) return { ...item, font: font };
            return item;
        });

        this.pagesData = updatedPagesData;
    }

    removeData() {
        this.pagesData = null;
    }
}

const pagesDataStore = new PagesDataStore();
export default pagesDataStore;
