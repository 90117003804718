import RandomStringUtil from "../../../core/utils/RandomStringUtil";
import { Activity, PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import { AppointmentValueResponse, Link } from "../model/AppointmentValueResponse";
import AppointmentUpdateService from "./AppointmentUpdateService";

export default async function AppointmentLinksUpdateService({ action, choosedPage, link, reload, appointmentValue, categoryId, updateServices }: { action: string, choosedPage: PagesDataResponseImpl; link?: Link; reload: any; appointmentValue?: AppointmentValueResponse | null; categoryId?: string; updateServices?: string[] }) {
    if (!appointmentValue) appointmentValue = { category: [], items: [], links: [] };

    if (action === 'update') {
        if (!categoryId || !updateServices) return;
        const newValue = appointmentValue.links.filter((item) => item.category !== categoryId);
        for (const item of updateServices) newValue.unshift({ id: RandomStringUtil(36), item: item, category: categoryId });
        appointmentValue.links = newValue;
    }

    if (action === 'delete') {
        if (!link) return;
        var indexToRemove = appointmentValue.links.indexOf(link);
        if (indexToRemove !== -1) appointmentValue.links.splice(indexToRemove, 1);
    }

    const newAppointmentValue = JSON.stringify({
        category: appointmentValue.category,
        items: appointmentValue.items,
        links: appointmentValue.links
    });

    //request
    const result = await AppointmentUpdateService({ pageId: choosedPage.page_id, appointmentValue: newAppointmentValue });
    if (!result) return;

    pagesDataStore.insertActivites(
        choosedPage.page_id,
        {
            id: (result.data.activity as Activity).id,
            type: (result.data.activity as Activity).type,
            value: (result.data.activity as Activity).value
        }
    );

    reload(newAppointmentValue);
}