import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function FontService({ pageId, font }: { pageId: string; font: string }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return false;

    const params = {
        "type": "page",
        "subType": "font",
        "accessToken": accessToken,
        "pageId": pageId,
        "font": font
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result) return false;

    // success
    pagesDataStore.updateWatermark(font, pageId);
    toast("Шрифт успешно изменен", { type: "success" });
    return true;
}