import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function SortAttributeService({ idList, pageId }: { idList: any; pageId: any; }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "type": "page",
        "subType": "sortAttribute",
        "accessToken": accessToken,
        "pageId": pageId,
        "idList": JSON.stringify(idList),
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    //if error
    if (result === null) return;
}