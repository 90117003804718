import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { Product } from "../model/ProductResponse";
// icons
import CardsIcon from '../../../core/assets/module/configuration/cards.svg';
import TimerIcon from '../../../core/assets/module/customize/timer.svg';
import CalculateDelay from "../../../core/utils/CalculateDelay";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { useState } from "react";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import PayboxPurchaseService from "../service/PayboxPurchaseService";
// import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import PromoCodeComponent from "./PromoCodeComponent";


const PurchaseComponent = ({ open, onDismiss, product }: { open: boolean; onDismiss: any; product: Product | undefined }) => {
    // init
    const [promo, setPromo] = useState('');
    const [calculatedPrice, setCalculatedPrice] = useState(0);
    const [promoCodeWindow, setPromoCodeWindow] = useState(false);
    if (!product) return null;

    const elements = [
        { icon: TimerIcon, label: "Срок действия", description: <SimpleTextComponent type='hardMedium'>{CalculateDelay(product.delay)}</SimpleTextComponent> },
        { icon: CardsIcon, label: "Цена", description: <SimpleTextComponent type='hardMedium'>{calculatedPrice !== 0 && `${PriceFormatUtil(calculatedPrice)} `}<span style={{ textDecoration: calculatedPrice !== 0 ? 'line-through' : 'unset' }}>{PriceFormatUtil(Number(product.amount))}</span></SimpleTextComponent> }
    ];

    // function
    async function execPurchase() {
        await PayboxPurchaseService({ productId: product!.id, promoCode: promo });
    }

    // widget
    const ElementsBlock = () => {
        return (
            <div>
                {
                    elements.map((field, index) =>
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: elements.length - 1 !== index ? 8 : 0 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={field.icon} alt="tick icon" width={20} />
                                <SimpleRetirementComponent size={16} />
                                <SimpleTextComponent>{field.label}</SimpleTextComponent>
                            </div>
                            {field.description}
                        </div>
                    )
                }
            </div>
        )
    };
    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={product.title}>
                <ElementsBlock />
                <SimpleRetirementComponent size={20} />
                <RaisedButtonComponent
                    onClick={execPurchase}
                    placeholder="Продолжить"
                />
                {/* <SimpleRetirementComponent size={20} />
                <div style={{ textAlign: 'center' }}>
                    <SimpleTextComponent>
                        Есть промокод?&nbsp;
                        <LinkWrapperComponent onClick={() => setPromoCodeWindow(true)} underline>
                            Применить
                        </LinkWrapperComponent>
                    </SimpleTextComponent>
                </div> */}
            </StandartBottomSheetComponent>
            <PromoCodeComponent open={promoCodeWindow} onDismiss={() => setPromoCodeWindow(false)} setPromo={setPromo} setCalculatedPrice={setCalculatedPrice} price={product.amount} />
        </>
    )
}

export default PurchaseComponent;