import { styled } from "@mui/material";

export default function PageNotFoundComponent() {
  const ErrorBlock = styled('div')({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  });

  return (
    <ErrorBlock>
      <p>404: Page not found</p>
    </ErrorBlock>
  );
}