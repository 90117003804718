import { useRef, useState } from 'react';
import ApplicationConfig from '../../../core/config/ApplicationConfig';
import LinkWrapperComponent from '../../shared/ui/LinkWrapperComponent';
import SimpleRetirementComponent from '../../shared/ui/SimpleRetirementComponent';
import SimpleTextComponent from '../../shared/ui/SimpleTextComponent';
import StandartBottomSheetComponent from '../../shared/ui/StandartBottomSheetComponent';
import StandartTextFieldWrapper from '../../shared/ui/StandartTextFieldWrapper';
import DomainComponent from './DomainComponent';

export default function NewBraendBottomSheetComponent({ open, onDismiss, onSave, init, title, initDomain }: { open: boolean; onDismiss: any; onSave: any; init?: string; title?: string; initDomain?: string }) {
    //init text field
    const textFieldRef = useRef(init ?? '');
    const handleTextFieldValueChange = (newValue: string) => { textFieldRef.current = newValue; };

    // domain
    const [domainState, setDomainState] = useState(false);
    const [activeDomain, setActiveDomain] = useState(initDomain ?? ApplicationConfig.domains[0]);

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            onSave(textFieldRef.current, activeDomain);
            handleTextFieldValueChange('');
            onDismiss();
        }} title={title ?? 'Новый веб-сайт'} description="Латинские буквы (от a до z), цифры (от 0 до 9) и символ (_). Длина (от 3 до 30 символов).">
            <StandartTextFieldWrapper
                initText={textFieldRef.current}
                placeholder="никнейм"
                startAdornment={<SimpleTextComponent type='lightTitle'>{activeDomain}/</SimpleTextComponent>}
                handleTextFieldValueChange={handleTextFieldValueChange}
                filter={/[^A-Za-z0-9_]/g}
            />
            <SimpleRetirementComponent />
            <div style={{ textAlign: 'center' }}>
                <LinkWrapperComponent underline onClick={() => setDomainState(true)}>
                    <SimpleTextComponent>Выбрать другой домен</SimpleTextComponent>
                </LinkWrapperComponent>
            </div>
            <DomainComponent open={domainState} onDismiss={() => setDomainState(false)} onSave={setActiveDomain} />
        </StandartBottomSheetComponent>
    );
};