import { InputAdornment, InputLabel } from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FileAddIcon from '../../../core/assets/module/customize/file-add.svg';
import ImageDeleteIcon from '../../../core/assets/module/customize/image-delete.svg';
import TemplateConfig from '../../../core/config/TemplateConfig';
import LinkWrapperComponent from './LinkWrapperComponent';

const CustomMuiUniversalFileInputComponent = ({ value, placeholder, onChange, format, initValue }: { value: File | null; placeholder: string; onChange: any; format: string; initValue?: string }) => {
    // init value
    useEffect(() => {
        if (initValue && initValue.length > 1) setFileSrcState(initValue);
        else setFileSrcState('');
    }, [initValue]);
    const [fileSrcState, setFileSrcState] = useState<string>('');

    return (
        <MuiFileInput
            value={value}
            placeholder={placeholder}
            onChange={(value) => {
                if (!value) {
                    onChange(null);
                    return;
                }

                if (value.size <= 10 * 1024 * 1024) {
                    onChange(value);
                    setFileSrcState(value.name);
                } else {
                    toast("Файл превышает размер 10мб", {
                        type: "error"
                    });
                    return;
                }
            }}
            fullWidth
            rows="1"
            variant="standard"
            InputProps={{
                startAdornment: null,
                endAdornment: (<InputAdornment style={{}} position="end" component="div" disablePointerEvents={fileSrcState.length === 0}>
                    <InputLabel style={{ paddingRight: 16 }}>
                        {
                            fileSrcState.length === 0
                                ? <img src={FileAddIcon} alt="img add icon" />
                                : (<LinkWrapperComponent onClick={() => {
                                    onChange(null);
                                    setFileSrcState('');
                                }}>
                                    <img src={ImageDeleteIcon} alt="img delete icon" />
                                </LinkWrapperComponent>)
                        }
                    </InputLabel>
                </InputAdornment>),
                disableUnderline: true,
                sx: { borderRadius: 4, backgroundColor: TemplateConfig.inputBackgroundColor, color: TemplateConfig.blackColor, paddingLeft: 2, fontWeight: TemplateConfig.regularText },
            }}
            inputProps={{
                accept: format,
                style: { padding: "12.5px 16px" }
            }} />
    );
}

export default CustomMuiUniversalFileInputComponent;