import { useRef } from "react";
import { toast } from "react-toastify";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../shared/ui/StandartTextFieldWrapper";
import DataService from "../service/DataService";
import customerDataStore from "../store/CustomerDataStore";

const DataComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    // fields
    const forename = useRef(customerDataStore.customerData?.customer.forename ?? '');
    const onForenameChange = (v: string) => { forename.current = v };
    const surname = useRef(customerDataStore.customerData?.customer.surname ?? '');
    const onSurnameChange = (v: string) => { surname.current = v };

    // function
    async function execSave() {
        if (forename.current.length < 2 || surname.current.length < 2) {
            toast("Заполните все поля", { type: "error" });
            return;
        }
        await DataService(forename.current, surname.current);
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent open={open} onSubmit={execSave} onDismiss={onDismiss} title="Редактировать данные">
            <StandartTextFieldWrapper
                initText={forename.current}
                placeholder="Введите имя"
                handleTextFieldValueChange={onForenameChange}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                initText={surname.current}
                placeholder="Введите фамилию"
                handleTextFieldValueChange={onSurnameChange}
            />
        </StandartBottomSheetComponent>
    );
}

export default DataComponent;