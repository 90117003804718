import TemplateConfig from "../../../../config/TemplateConfig";

const BoldIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 4.89474C5 3.85263 5.90986 3 7.0219 3H12.198C14.8467 3 17 5.01789 17 7.5C17 9.98211 14.8467 12 12.198 12H5V4.89474Z" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 12H14.3333C16.9074 12 19 14.0179 19 16.5C19 18.9821 16.9074 21 14.3333 21H6.96491C5.88421 21 5 20.1474 5 19.1053V12V12Z" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default BoldIcon;