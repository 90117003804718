import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function SearchAddressService({ address, pageId }: { address: string; pageId: string }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "type": "page",
        "subType": "coordinates",
        "accessToken": accessToken,
        "pageId": pageId,
        "address": address,
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
        }
    );

    if (!result) {
        toast("Не удалось начать поиск", { type: "error" });
        return;
    }

    return result;
}