import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import { Attribute } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import imageCompression from 'browser-image-compression';
import loaderStore from "../../shared/store/LoaderStore";
import { RowAttributeResponse } from "../model/RowAttributeResponse";

export default async function InsertDividerBlockService({ pageId, image, color, attributePadding }: { pageId: string; image: File | null; color: string; attributePadding: string; }) {
    // get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // open loader
    loaderStore.showStore();

    const compressedImage = image ? await imageCompression(image, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }) : null;

    const attributeType = "divider";
    const attributeValue = JSON.stringify({
        "color": color,
    });
    const formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('pageId', pageId);
    formData.append('attributeType', attributeType);
    formData.append('attributeValue', attributeValue);
    formData.append('attributePadding', attributePadding);
    if (compressedImage) formData.append('image', compressedImage, compressedImage.name);

    //close loader
    loaderStore.hideStore();

    var result = await GlobalRequestTemplate(
        {
            type: "post",
            link: ApplicationConfig.thisProductBackendAttributeCreatePath,
            formData: formData,
        }
    );

    //if error
    if (result === null) return;

    //notify
    toast("Блок успешно добавлен", {
        type: "success"
    });

    //store
    pagesDataStore.insertAttribute({
        id: (result.data as RowAttributeResponse).attributeId,
        mark: (result.data as RowAttributeResponse).mark,
        type: attributeType,
        padding: attributePadding,
        value: JSON.stringify({
            "color": color,
            "image": (result.data as RowAttributeResponse).image
        }),
    } as Attribute, pageId);
}