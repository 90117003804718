export default function PriceFormatUtil(value: number) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'KZT',
        currencyDisplay: 'symbol',
    });

    const formattedValue = formatter.format(value);

    const symbol = '₸';
    const replacedValue = formattedValue.replace(/KZT/g, symbol).split('.')[0];

    return replacedValue;
}