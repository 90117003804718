import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function FormAttributeSendService({ attributeId, inputValues, optionAnswers, reset }: { attributeId: string; inputValues: any; optionAnswers: any; reset: any }) {
    const params = {
        "type": "page",
        "subType": "form",
        "attributeId": attributeId,
        "value": JSON.stringify({
            input: inputValues,
            option: optionAnswers
        })
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    //if error
    if (result === null) {
        toast("Не удалось отправить сообщение", {
            type: "error"
        });
        return;
    }

    //notify
    toast("Сообщение успешно отправлено", {
        type: "success"
    });

    reset();
}