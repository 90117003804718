import TemplateConfig from "../../../config/TemplateConfig";

const TiktokIcon = ({ background = TemplateConfig.blackColor, color = TemplateConfig.whiteColor }: { background?: string; color?: string }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill={background} />
            <path d="M24.8218 13.1344C24.0887 12.2939 23.648 11.1981 23.648 10H22.7294M24.8218 13.1344C25.4899 13.9006 26.3945 14.4579 27.4246 14.6761C27.7447 14.7457 28.0787 14.7829 28.4267 14.7829V18.2191C26.6451 18.2191 24.9933 17.648 23.6478 16.6821V23.6707C23.6478 27.1627 20.8083 30 17.3239 30C15.5005 30 13.8534 29.2198 12.6981 27.98C11.6449 26.847 11 25.3331 11 23.6707C11 20.2298 13.756 17.4251 17.1708 17.3508M24.8218 13.1344C24.804 13.1228 24.7862 13.111 24.7685 13.0991M14.9856 25.3517C14.6422 24.8781 14.4381 24.2977 14.4381 23.6661C14.4381 22.0734 15.7326 20.7778 17.324 20.7778C17.6209 20.7778 17.9086 20.8288 18.1777 20.9124V17.4019C17.8993 17.3647 17.6163 17.3415 17.324 17.3415C17.2729 17.3415 16.8618 17.3688 16.8108 17.3688M22.7245 10H20.2098L20.2052 23.7775C20.1495 25.3192 18.8782 26.5591 17.3239 26.5591C16.3588 26.5591 15.5098 26.0808 14.9809 25.3564" stroke={color} stroke-width="1.5" strokeLinejoin="round" />
        </svg>
    );
}

export default TiktokIcon;
