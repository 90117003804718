import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ApplicationConfig from '../../../../core/config/ApplicationConfig';
import ParseFileUtil from '../../../../core/utils/ParseFileUtil';
import { Attribute, PagesDataResponseImpl } from '../../../configuration/model/PagesDataResponse';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import { RowAttributeDao } from '../../../leaf/model/RowAttributeDao';
import CustomMuiFileInputComponent from '../../../shared/ui/CustomMuiFileInputComponent';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import PaddingComponent from './PaddingComponent';
import RadiusComponent from './RadiusComponent';

export default function RowBlockBuildBottomSheetComponent({ open, onDismiss, saveRow, updateValue, item, choosedPage, updatePadding }: { open: boolean; onDismiss: any; saveRow: any; updateValue?: RowAttributeDao; item?: Attribute; choosedPage?: PagesDataResponseImpl; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        async function fetchData() {
            if (updateValue) {
                updateTitleField(updateValue.title);
                updateTextField(updateValue.value);
                const imageLink = `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage?.page_id}/${item?.id}/`;
                setImage(updateValue.image && updateValue.image.length > 1 ? await ParseFileUtil(`${imageLink}${updateValue.image}`) : null);
                setColorState(updateValue.color ?? '#000000');
            } else {
                updateTitleField('');
                updateTextField('');
                setImage(null);
                setColorState('#000000');
            }
        }

        fetchData();
    }, [updateValue, choosedPage, item]);

    // title text
    const titleFieldRef = useRef('');
    const updateTitleField = (newValue: string) => { titleFieldRef.current = newValue; };

    // text text
    const textFieldRef = useRef('');
    const updateTextField = (newValue: string) => { textFieldRef.current = newValue; };

    // image
    const [image, setImage] = useState<File | null>(null);
    const handleImage = (file: File | null, inputKey: string) => setImage(file);

    // color
    const [colorState, setColorState] = useState('#000000');

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    // radius
    const radiusField = useRef('');
    const onRadiusChange = (v: string) => { radiusField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!titleFieldRef || titleFieldRef.current.length === 0 || !textFieldRef || textFieldRef.current.length === 0 || !image) {
                toast("Заполните все поля", { type: "error" });
                return;
            }

            async function loadSave() {
                await saveRow(titleFieldRef.current, textFieldRef.current, image, paddingField.current, colorState, radiusField.current);
                updateTitleField('');
                updateTextField('');
                setImage(null);
            }

            loadSave();
        }} title={'Медиа и текст'}>
            <CustomMuiFileInputComponent
                value={image}
                placeholder={"Загрузите картинку"}
                onChange={handleImage}
                aspectRatio={1}
                inputKey={"1"}
                initValue={image && image.name.length > 0 ? image.name : ''}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Введите заголовок"
                handleTextFieldValueChange={updateTitleField}
                initText={titleFieldRef.current && titleFieldRef.current.length > 0 ? titleFieldRef.current : null}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Введите текст"
                handleTextFieldValueChange={updateTextField}
                initText={textFieldRef.current && textFieldRef.current.length > 0 ? textFieldRef.current : null}
                multiline
                rows={"3"}
                maxLength={500}
            />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Текст" color={colorState} onSave={setColorState} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
                <RadiusComponent init={updateValue ? updateValue.radius ?? undefined : undefined} onSave={onRadiusChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};
