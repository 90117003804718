import { action, makeObservable, observable } from 'mobx';

class LoaderStore {
    openState = false;

    constructor() {
        makeObservable(this, {
            openState: observable,
            showStore: action,
            hideStore: action,
        });
    }

    showStore() {
        this.openState = true;
    }

    hideStore() {
        this.openState = false;
    }
}

const loaderStore = new LoaderStore();
export default loaderStore;
