import analyticsDataStore from "../../configuration/store/AnalyticsDataStore";
import ordersDataStore from "../../configuration/store/OrdersDataStore";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import productPurchasedStore from "../../purchase/store/ProductPurchasedStore";
import customerDataStore from "../store/CustomerDataStore";
import LogoutRequestService from "./LogoutRequestService";

export default function LogoutService(request: boolean = true) {
    if (request) LogoutRequestService();
    localStorage.removeItem("accessToken");
    customerDataStore.removeCustomer();
    productPurchasedStore.removeData();
    analyticsDataStore.removeData();
    pagesDataStore.removeData();
    ordersDataStore.removeData();
}