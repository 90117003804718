import { useEffect, useRef, useState } from 'react';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import { toast } from 'react-toastify';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import { FormAttributeDao, Value } from '../../../leaf/model/FormAttributeDao';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import RaisedButtonComponent from '../../../shared/ui/RaisedButtonComponent';
import AddIcon from '../../../../core/assets/module/customize/add.svg';
import FormAddFieldComponent from './FormAddFieldComponent';
import SimpleTextComponent from '../../../shared/ui/SimpleTextComponent';
import LinkWrapperComponent from '../../../shared/ui/LinkWrapperComponent';
import DeleteIcon from '../../../../core/assets/module/configuration/delete.svg';
import RandomStringUtil from '../../../../core/utils/RandomStringUtil';
import FormCheckboxFieldComponent from './FormCheckboxFieldComponent';
import PaddingComponent from './PaddingComponent';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import RadiusComponent from './RadiusComponent';

export default function FormBlockBuildBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, updateValue, updatePadding }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; updateValue?: FormAttributeDao; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        if (!updateValue) {
            reset();
            return;
        }

        handleButtonFieldValueChange(updateValue.placeholder);
        setColorState(updateValue.color ?? '#ffffff');
        setBackgroundState(updateValue.background ?? '#000000');
        setFields(updateValue.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateValue]);
    function reset() {
        handleButtonFieldValueChange('');
        setColorState('#ffffff');
        setBackgroundState('#000000');
        setFields([]);
    }

    // button text
    const buttonFieldRef = useRef('');
    const handleButtonFieldValueChange = (newValue: string) => { buttonFieldRef.current = newValue; };

    // color, background
    const [colorState, setColorState] = useState('#ffffff');
    const [backgroundState, setBackgroundState] = useState('#000000');

    // fields
    const [addFieldState, setAddFieldState] = useState(false);
    const [fields, setFields] = useState<Value[]>([]);
    function onFieldAdd(value: string) {
        setAddFieldState(false);
        if (fields.length >= 8) {
            toast("Максимально 8 полей", { type: "error" });
            return;
        }

        if (value === 'checkbox') {
            setCheckboxState(true);
            return;
        }

        const newFields = [...fields];
        newFields.push({
            id: RandomStringUtil(36),
            label: value,
            name: value === 'name'
                ? 'Поле `Имя`'
                : value === 'mail'
                    ? 'Поле `Эл. почта`'
                    : value === 'telephone'
                        ? 'Поле `Телефон`'
                        : 'Поле `Текст`'
        })
        setFields(newFields);
    }
    function onFieldRemove(id: string) {
        const newFields = [...fields.filter(item => item.id !== id)];
        setFields(newFields);
    }

    // checkbox
    const [checkboxState, setCheckboxState] = useState(false);
    function onCheckboxAdd(title: string, value: string) {
        setCheckboxState(false);
        const newFields = [...fields];
        newFields.push({
            id: RandomStringUtil(36),
            label: 'checkbox',
            name: 'Поле `Чекбокс`',
            children: {
                title: title,
                options: value.split('\n').map(option => ({
                    id: RandomStringUtil(36),
                    option: option
                }))
            }
        });
        setFields(newFields);
    }

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    // radius
    const radiusField = useRef('');
    const onRadiusChange = (v: string) => { radiusField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!buttonFieldRef.current || buttonFieldRef.current.length === 0) {
                toast("Заполните поле", { type: "error" });
                return;
            }

            if (fields.length === 0) {
                toast("Добавьте поле", { type: "error" });
                return;
            }

            saveDirectlyLoading('form', JSON.stringify({ "placeholder": buttonFieldRef.current.toString(), "value": fields, "color": colorState, "background": backgroundState, "radius": radiusField.current }), paddingField.current);

            // reset
            reset();
        }} title={'Форма'}>
            {
                fields.map((field, index) => (
                    <div key={index} >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <SimpleTextComponent>
                                {field.name}
                            </SimpleTextComponent>
                            <LinkWrapperComponent onClick={() => onFieldRemove(field.id)}>
                                <img src={DeleteIcon} alt="delete icon" />
                            </LinkWrapperComponent>
                        </div>
                        <SimpleRetirementComponent size={index === fields.length - 1 ? 15 : 10} />
                    </div>
                ))
            }
            <StandartTextFieldWrapper
                placeholder="Введите надпись на кнопке"
                handleTextFieldValueChange={handleButtonFieldValueChange}
                initText={buttonFieldRef.current && buttonFieldRef.current.length > 0 ? buttonFieldRef.current : null}
            />
            <SimpleRetirementComponent size={10} />
            <RaisedButtonComponent
                placeholder={`Новое поле`}
                onClick={() => setAddFieldState(true)}
                icon={AddIcon}
            />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Фон" color={backgroundState} onSave={setBackgroundState} />
                <ColorPickerButtonComponent text="Текст" color={colorState} onSave={setColorState} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
                <RadiusComponent init={updateValue ? updateValue.radius ?? undefined : undefined} onSave={onRadiusChange} />
            </div>
            <FormAddFieldComponent open={addFieldState} onDismiss={() => setAddFieldState(false)} onSave={onFieldAdd} />
            <FormCheckboxFieldComponent open={checkboxState} onDismiss={() => setCheckboxState(false)} onSave={onCheckboxAdd} />
        </StandartBottomSheetComponent>
    );
};
