import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import { Attribute } from '../../../leaf/model/PublicDataResponse';
import { VideoAttributeDao } from '../../../leaf/model/VideoAttributeDao';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import PaddingComponent from './PaddingComponent';
import RadiusComponent from './RadiusComponent';


export default function VideoBlockBuildBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, updateValue, updatePadding, item }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; updateValue?: VideoAttributeDao; updatePadding?: PaddingDao; item?: Attribute }) {
    // update value
    useEffect(() => {
        handleTextFieldValueChange(updateValue ? updateValue.placeholder : '');
    }, [updateValue]);

    //init text field
    const textFieldRef = useRef('');
    const handleTextFieldValueChange = (newValue: string) => { textFieldRef.current = newValue; };

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    // radius
    const radiusField = useRef('');
    const onRadiusChange = (v: string) => { radiusField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (textFieldRef.current === null || textFieldRef.current.length === 0) {
                toast("Заполните как мин. одно поле", { type: "error" });
                return;
            }

            saveDirectlyLoading('youtube', JSON.stringify({ "placeholder": textFieldRef.current, radius: radiusField.current }), paddingField.current);
            handleTextFieldValueChange('');
        }} title={'Видео'}>
            <StandartTextFieldWrapper
                placeholder="Введите URL-адрес"
                handleTextFieldValueChange={handleTextFieldValueChange}
                maxLength={200}
                initText={textFieldRef.current && textFieldRef.current.length > 0 ? textFieldRef.current : null}
            />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
                <RadiusComponent init={item ? (JSON.parse(item.value!) as VideoAttributeDao).radius ?? undefined : undefined} onSave={onRadiusChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};
