import { useEffect, useRef, useState } from 'react';
import ApplicationConfig from '../../../../core/config/ApplicationConfig';
import ParseFileUtil from '../../../../core/utils/ParseFileUtil';
import { Attribute, PagesDataResponseImpl } from '../../../configuration/model/PagesDataResponse';
import { DividerAttributeDao } from '../../../leaf/model/DividerAttributeDao';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import CustomMuiFileInputComponent from '../../../shared/ui/CustomMuiFileInputComponent';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import PaddingComponent from './PaddingComponent';

export default function DividerBlockBuildBottomSheetComponent({ open, onDismiss, saveDivider, updateValue, updatePadding, choosedPage, item }: { open: boolean; onDismiss: any; saveDivider: any; updateValue?: DividerAttributeDao; updatePadding?: PaddingDao; choosedPage?: PagesDataResponseImpl; item?: Attribute }) {
    // update value
    useEffect(() => {
        async function fetchData() {
            if (updateValue) {
                setColorState(updateValue.color);
                const imageLink = `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage?.page_id}/${item?.id}/`;
                setImage(updateValue.image && updateValue.image.length > 1 ? await ParseFileUtil(`${imageLink}${updateValue.image}`) : null);
            } else {
                setColorState('#000000');
                setImage(null);
            }
        }

        fetchData();
    }, [updateValue, choosedPage, item]);

    // color
    const [colorState, setColorState] = useState('#000000');

    // image
    const [image, setImage] = useState<File | null>(null);
    const handleImage = (file: File | null, inputKey: string) => setImage(file);

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            saveDivider(colorState, image, paddingField.current);
            setColorState('#000000');
            setImage(null);
        }} title={'Разделитель'} description="Загрузка картинки (Опционально)">
            <CustomMuiFileInputComponent
                value={image}
                placeholder={"Загрузите картинку"}
                onChange={handleImage}
                aspectRatio={1}
                inputKey={"1"}
                initValue={image && image.name.length > 0 ? image.name : ''}
            />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Линия" color={colorState} onSave={setColorState} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};
