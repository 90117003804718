import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import ParseFileUtil from "../../../core/utils/ParseFileUtil";
import { PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import CustomMuiFileInputComponent from "../../shared/ui/CustomMuiFileInputComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../shared/ui/StandartTextFieldWrapper";
import { PageSeoResponse } from "../model/PageSettingsResponse";

const SeoEditComponent = ({ open, onDismiss, onSave, updateValue, choosedPage }: { open: boolean; onDismiss: any; onSave: any; updateValue?: PageSeoResponse; choosedPage: PagesDataResponseImpl; }) => {
    // update value
    useEffect(() => {
        async function fetchData() {
            if (updateValue) {
                onTitleChange(updateValue.title ?? '');
                onDescriptionChange(updateValue.description ?? '');
                onKeywordsChange(updateValue.keywords ?? '');
                if (updateValue.favicon) {
                    const imageLink = `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage.page_id}/favicon/${updateValue.favicon}`;
                    setFavicon(await ParseFileUtil(imageLink));
                } else setFavicon(null);
            } else reset();
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateValue, choosedPage])

    function reset() {
        onTitleChange('');
        onDescriptionChange('');
        onKeywordsChange('');
        setFavicon(null);
    }

    // FAVICON
    const [favicon, setFavicon] = useState<File | null>(null);
    function saveFavicon(file: File, inputKey: string) { setFavicon(file); }

    // TITLE TEXT
    const titleField = useRef('');
    const onTitleChange = (newValue: string) => { titleField.current = newValue; };

    // DESCRIPTION TEXT
    const descriptionField = useRef('');
    const onDescriptionChange = (newValue: string) => { descriptionField.current = newValue; };

    // KEYWORDS TEXT
    const keywordsField = useRef('');
    const onKeywordsChange = (newValue: string) => { keywordsField.current = newValue; };

    return (
        <StandartBottomSheetComponent
            open={open}
            onDismiss={onDismiss}
            title="SEO"
            onSubmit={() => {
                if (
                    !titleField.current || titleField.current.length < 1
                    || !descriptionField.current || descriptionField.current.length < 1
                    || !keywordsField.current || keywordsField.current.length < 1
                    || !favicon
                ) {
                    toast("Заполните все поля", { type: "error" });
                    return;
                }

                onSave(favicon, titleField.current, descriptionField.current, keywordsField.current);
            }}
        >
            <CustomMuiFileInputComponent
                value={favicon}
                placeholder="Загрузите картинку"
                onChange={saveFavicon}
                inputKey={'1'}
                initValue={favicon ? favicon.name : ''}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Введите заголовок"
                handleTextFieldValueChange={onTitleChange}
                initText={titleField.current && titleField.current.length > 0 ? titleField.current : null}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Введите описание"
                handleTextFieldValueChange={onDescriptionChange}
                initText={descriptionField.current && descriptionField.current.length > 0 ? descriptionField.current : null}
                maxLength={500}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Введите ключевые слова"
                handleTextFieldValueChange={onKeywordsChange}
                initText={keywordsField.current && keywordsField.current.length > 0 ? keywordsField.current : null}
                maxLength={250}
            />
        </StandartBottomSheetComponent>
    );
}

export default SeoEditComponent;
