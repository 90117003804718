import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function InsertClickAnalyticsFieldService({ pageId }: { pageId: string }) {
    const params = {
        "type": "page",
        "subType": "insertAnalytics",
        "pageId": pageId,
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    return result;
}