import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { AuthorizeResponse } from "../model/AuthorizeResponse";
import CustomerDataService from "./CustomerDataService";

export default async function AuthorizationService({ googleAccessToken, email, password, navigate, registrationProcedure }: { googleAccessToken: string; email: string; password: string; navigate: any; registrationProcedure: string }) {
    var resultCode = await sendRequest(googleAccessToken, email, password, registrationProcedure);
    if (!resultCode) return false;
    if (!resultCode.status || !resultCode.data || resultCode.status !== 'succes') {
        toast("Произошла ошибка", { type: "error" });
        return false;
    }

    if (resultCode && registrationProcedure === '1') return true;
    if (resultCode) {
        var accessToken = (resultCode.data as AuthorizeResponse).access_token;
        localStorage.setItem('accessToken', accessToken);
        await CustomerDataService(accessToken);
        navigate(RouteNamespaces.configuration);
    }
    return resultCode;
}

async function sendRequest(googleAccessToken: string, email: string, password: string, registrationProcedure: string) {
    const params = {
        "type": "account",
        "subType": "auth",
        "googleAccessToken": googleAccessToken,
        "email": email,
        "password": password,
        "registrationProcedure": registrationProcedure
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: registrationProcedure === '1' ? "Аккаунт с таким адресом эл. почты уже существует" : googleAccessToken.length > 0 ? "Возможно ваш аккаунт не зарегистрирован" : "Неверный адрес эл. почты или пароль"
        }
    );

    return result;
}