import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GetPagesDataService from "../../configuration/service/GetPagesDataService";
import ProductPurchasedVerify from "../../purchase/service/ProductPurchasedVerify";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { CustomerDataResponse } from "../model/CustomerDataResponse";
import customerDataStore from "../store/CustomerDataStore";
import LogoutService from "./LogoutService";

export default async function CustomerDataService(accessToken: string) {
    //create params
    const params = {
        "type": "account",
        "subType": "data",
        "accessToken": accessToken
    };

    //request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result || !result.status || result.status !== 'succes' || !result.data) {
        LogoutService(false);
        return;
    }

    //result
    var customer = result.data as CustomerDataResponse;
    customerDataStore.saveCustomer(customer);
    await ProductPurchasedVerify({ accessToken: accessToken });
    await GetPagesDataService();
}