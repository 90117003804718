import { observer } from "mobx-react-lite";
import pagesDataStore from "../store/PagesDataStore";
import PagesListElementComponent from "./PagesListElementComponent";

function PagesListComponent({ onDeletePage, checkNotFreeAccount, freeAccount }: { onDeletePage: any; checkNotFreeAccount: any; freeAccount: boolean }) {
    //init
    if (pagesDataStore.pagesData == null) return null;

    //widget
    const buildPagesList = pagesDataStore.pagesData!.map((element, index) => (
        <PagesListElementComponent isOpen={index === 0} onDeletePage={onDeletePage} key={index} element={element} checkNotFreeAccount={checkNotFreeAccount} freeAccount={freeAccount} />
    ));

    return (
        <div>
            {buildPagesList}
        </div>
    );
}

export default observer(PagesListComponent);