import { action, makeObservable, observable } from 'mobx';
import { ProductPurchasedResponse } from '../model/ProductPurchasedResponse';

class ProductPurchasedStore {
    purchasedData: ProductPurchasedResponse | null = null;

    constructor() {
        makeObservable(this, {
            purchasedData: observable,
            saveData: action,
            removeData: action,
        });
    }

    saveData(value: ProductPurchasedResponse) {
        this.purchasedData = value;
    }

    removeData() {
        this.purchasedData = null;
    }
}

const productPurchasedStore = new ProductPurchasedStore();
export default productPurchasedStore;
