import StandartBottomSheetComponent from "./StandartBottomSheetComponent";
import { useRef } from "react";
import { CropperRef, Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'
import { toast } from "react-toastify";
import loaderStore from "../store/LoaderStore";

export default function ImageCropperComponent({ open, onDismiss, onSubmit, image, aspectRatio, inputKey }: { open: boolean; onDismiss: any; onSubmit: any; image: string; aspectRatio?: number; inputKey: string }) {
    //init
    const cropperRef = useRef<CropperRef>(null);

    //handle on crop
    async function handleOnCrop() {
        if (!cropperRef.current || !cropperRef.current.getCanvas()) return;
        const currentCanvas: HTMLCanvasElement = cropperRef.current.getCanvas()!;

        //open loader
        loaderStore.showStore();

        try {
            const blob = await new Promise<Blob | null>((resolve) => {
                currentCanvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/png');
            });

            if (!blob) {
                loaderStore.hideStore();
                return;
            }

            const file = new File([blob], `cropped_${currentCanvas.width}${currentCanvas.height}_image.png`, { type: 'image/png' });

            if (file !== null || file !== undefined)
                onSubmit(file, inputKey);
            else
                toast("Произошла ошибка", { type: "error" });
        } catch (error) {
            loaderStore.hideStore();
            toast("Произошла ошибка", { type: "error" });
        }

        loaderStore.hideStore();
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent
            open={open} onDismiss={onDismiss} onSubmit={handleOnCrop} title={'Обрезка'}>
            <div style={{ height: '50vh' }}>
                <Cropper
                    src={image}
                    ref={cropperRef}
                    className={'cropper'}
                    aspectRatio={aspectRatio ? {
                        maximum: aspectRatio,
                        minimum: aspectRatio
                    } : undefined}
                />
            </div>
        </StandartBottomSheetComponent>
    );
}