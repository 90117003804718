import { useState } from "react";
import { toast } from "react-toastify";
import TemplateConfig from "../../../core/config/TemplateConfig";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const WebTemplateComponent = ({ open, onDismiss, onSave }: { open: boolean; onDismiss: any; onSave: any }) => {
    // init
    const items = [
        { label: 'Info', color: '#78A5A3' },
        { label: 'Info', color: '#78A5A3' },
        { label: 'Info', color: '#78A5A3' },
        { label: 'Info', color: '#78A5A3' },
        { label: 'Info', color: '#78A5A3' },
        { label: 'Info', color: '#78A5A3' },
        // { label: 'Market', color: '#CE5A57' },
        // { label: 'Business', color: '#E1B16A' },
        // { label: 'Date', color: '#A4D5EB' },
        // { label: 'Elderberry', color: '#95CAE3' },
        // { label: 'Fig', color: '#87BFD9' },
        // { label: 'Grape', color: '#79B5D1' },
        // { label: 'Honeydew', color: '#6AAAC9' },
        // { label: 'Kiwi', color: '#5C9FC1' },
        // { label: 'Lemon', color: '#4E94B9' },
    ];

    // active theme
    const [activeLabel, setActiveLabel] = useState('');

    // confirm
    const [confirmState, setConfirmState] = useState(false);

    return (
        <StandartBottomSheetComponent title="Шаблоны сайта" open={open} onDismiss={onDismiss} onSubmit={() => setConfirmState(true)}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {
                    items.map((field, index) => (
                        <div key={index} style={{ width: window.innerWidth > TemplateConfig.mobileMaxWidth ? '30%' : window.innerWidth > 400 ? '48%' : '100%' }}>
                            <LinkWrapperComponent onClick={() => setActiveLabel(field.label)}>
                                <div style={{ border: `2px solid ${field.label === activeLabel ? TemplateConfig.purpleColor : 'transparent'}`, padding: 2, borderRadius: TemplateConfig.borderRadius }}>
                                    <div style={{
                                        padding: 10,
                                        aspectRatio: 4 / 3,
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: field.color,
                                        borderRadius: TemplateConfig.borderRadius - 4
                                    }}>
                                        <SimpleTextComponent anotherColor={TemplateConfig.whiteColor}>
                                            {field.label}
                                        </SimpleTextComponent>
                                    </div>
                                </div>
                            </LinkWrapperComponent>
                            <SimpleRetirementComponent />
                        </div>
                    ))
                }
            </div>
            <ConfirmBottomSheetComponent
                open={confirmState}
                onDismiss={() => setConfirmState(false)}
                title="Предупреждение"
                description="При использовании шаблона вы потеряете текущие сохраненные блоки."
                onSubmit={() => {
                    setConfirmState(false);
                    if (!activeLabel || activeLabel === '') {
                        toast("Выберите шаблон", { type: "error" });
                        return;
                    }

                    onSave(activeLabel);
                    setTimeout(() => onDismiss(), 100);
                }}
            />
        </StandartBottomSheetComponent>
    );
}

export default WebTemplateComponent;