import { Container, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationConfig from '../../../core/config/ApplicationConfig';
import RouteNamespaces from '../../../core/route/RouteNamespaces';
import { Attribute, PagesDataResponseImpl } from '../../configuration/model/PagesDataResponse';
import pagesDataStore from '../../configuration/store/PagesDataStore';
import PageNotFoundComponent from '../../shared/ui/PageNotFoundComponent';
import { PageSeoResponse } from '../model/PageSettingsResponse';
import CustomizeBottomComponent from './CustomizeBottomComponent';
import CustomizeHeaderComponent from './CustomizeHeaderComponent';
import DraggableComponent from './DraggableComponent';
import InitalItemComponent from './InitialItemComponent';

function CustomizeComponent() {
  //init
  let { nickname } = useParams();
  const navigate = useNavigate();
  var verify = true;

  //condition
  if (pagesDataStore.pagesData === null || !pagesDataStore.pagesData.some(item => { return item.nickname === nickname })) verify = false;

  //get page if isset
  const [choosedPage, setChoosedPage] = useState<PagesDataResponseImpl | null>(verify ? pagesDataStore.pagesData!.find(item => item.nickname === nickname) as PagesDataResponseImpl : null);

  // scrollable
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  //attributes
  const [itemsState, setItemsState] = useState(verify ? choosedPage!.attributes : []);
  function setItems(attributes: Attribute[], insert: boolean = false) {
    const prevScrollTop = scrollContainerRef.current?.scrollTop || 0;
    setItemsState(attributes);
    const newPage = {
      page_id: choosedPage!.page_id,
      nickname: choosedPage!.nickname,
      settings: choosedPage!.settings,
      attributes: attributes,
      activities: choosedPage!.activities,
      client_orders_count: choosedPage!.client_orders_count,
      domain: choosedPage!.domain,
      watermark: choosedPage!.watermark,
      font: choosedPage!.font
    }
    setChoosedPage(newPage);
    requestAnimationFrame(() => {
      if (scrollContainerRef.current && !insert) {
        scrollContainerRef.current!.scrollTop = prevScrollTop;
      }

      if (scrollContainerRef.current && insert) {
        const container = scrollContainerRef.current;
        const maxScrollTop = container.scrollHeight - container.clientHeight;
        container.scrollTo({ top: maxScrollTop, behavior: 'smooth' });
      }
    });
  }

  // reload
  const [reloadState, setReloadState] = useState(false);
  function reloadWeb(newPage?: PagesDataResponseImpl) {
    const prevScrollTop = scrollContainerRef.current?.scrollTop || 0;
    if (newPage) setChoosedPage(newPage);
    if (newPage) setItems(newPage.attributes)
    setReloadState(!reloadState);
    requestAnimationFrame(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = prevScrollTop;
      }
    });
  }

  useEffect(() => {
    if (!verify) navigate(RouteNamespaces.error);
  });
  if (!verify) return <PageNotFoundComponent />

  // style
  const CustomizeComponentContainerWrapper = styled(Container)({
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
  });
  const BodyWrapper = styled('div')({
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'grid',
    gridTemplateColumns: '24px 1fr 24px'
  });
  const CenterBlock = styled('div')({
    backgroundColor: choosedPage && choosedPage.settings && JSON.parse(choosedPage.settings).color ? JSON.parse(choosedPage.settings).color : 'none',
    backgroundImage: choosedPage && choosedPage.settings && JSON.parse(choosedPage.settings).background ? `url(${ApplicationConfig.thisProductBackendImagesPath}${choosedPage.page_id}/background/${JSON.parse(choosedPage.settings).background})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 0
  });

  //widget
  return (
    <>
      {
        choosedPage && choosedPage.settings && JSON.parse(choosedPage.settings).seo
          ?
          <Helmet
            title={JSON.parse(choosedPage.settings).seo.title ?? ''}
            meta={[
              { name: "description", content: JSON.parse(choosedPage.settings).seo.description ?? '' },
              { name: "keywords", content: JSON.parse(choosedPage.settings).seo.keywords ?? '' }
            ]}
            link={[
              {
                "rel": "icon",
                "type": "image/png",
                "href": `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage.page_id}/favicon/${(JSON.parse(choosedPage.settings).seo as PageSeoResponse).favicon}`
              }
            ]}
          />
          : null
      }

      <CustomizeComponentContainerWrapper disableGutters>
        {/* header */}
        <CustomizeHeaderComponent reloadWeb={reloadWeb} choosedPage={choosedPage!} />

        {/* body */}
        {
          choosedPage?.attributes.length === 0
            ? (<InitalItemComponent />)
            : (
              <div style={{ position: 'relative' }}>
                <BodyWrapper>
                  <p />
                  <CenterBlock />
                  <p />
                </BodyWrapper>
                <DraggableComponent scrollContainerRef={scrollContainerRef} setItems={setItems} items={itemsState} choosedPage={choosedPage!} />
              </div>
            )
        }

        {/* bottom */}
        <CustomizeBottomComponent choosedPage={choosedPage!} setItems={setItems} />
      </CustomizeComponentContainerWrapper>
    </>
  );
}

export default CustomizeComponent;