import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function RegisterAppointmentService({ linkId, pageId, time, orderTime, name, telephone }: { linkId: string; pageId: string; time: string; orderTime: string; name: string; telephone: string }) {
    const params = {
        "type": "page",
        "subType": "createAppointment",
        "linkId": linkId,
        "pageId": pageId,
        "time": time,
        "orderTime": orderTime,
        "name": name,
        "telephone": telephone.replace(/\D/g, ''),
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    if (result) toast("Успешно зарегистрировано", { type: "success" });
}