import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ApplicationConfig from '../../../../core/config/ApplicationConfig';
import ParseFileUtil from '../../../../core/utils/ParseFileUtil';
import { Attribute, PagesDataResponseImpl } from '../../../configuration/model/PagesDataResponse';
import { ButtonAttributeDao } from '../../../leaf/model/ButtonAttributeDao';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import CustomMuiFileInputComponent from '../../../shared/ui/CustomMuiFileInputComponent';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import PaddingComponent from './PaddingComponent';
import RadiusComponent from './RadiusComponent';

export default function ButtonBlockBuildBottomSheetComponent({ open, onDismiss, saveButton, updateValue, item, choosedPage, updatePadding }: { open: boolean; onDismiss: any; saveButton: any; updateValue?: ButtonAttributeDao; item?: Attribute; choosedPage?: PagesDataResponseImpl; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        async function fetchData() {
            if (updateValue) {
                handleNameTextFieldValueChange(updateValue.placeholder);
                handleUrlTextFieldValueChange(updateValue.url);
                setColorState(updateValue.color);
                setBackgroundState(updateValue.background);
                const imageLink = `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage?.page_id}/${item?.id}/`;
                setButtonImage(updateValue.image && updateValue.image.length > 1 ? await ParseFileUtil(`${imageLink}${updateValue.image}`) : null);
            } else {
                handleNameTextFieldValueChange('');
                handleUrlTextFieldValueChange('');
                setColorState('#ffffff');
                setBackgroundState('#000000');
                setButtonImage(null);
            }
        }

        fetchData();
    }, [updateValue, choosedPage, item]);

    // radius
    const radiusField = useRef('');
    const onRadiusChange = (v: string) => { radiusField.current = v };

    // init name text field
    const nameTextFieldRef = useRef('');
    const handleNameTextFieldValueChange = (newValue: string) => { nameTextFieldRef.current = newValue; };

    // init url text field
    const urlTextFieldRef = useRef('');
    const handleUrlTextFieldValueChange = (newValue: string) => { urlTextFieldRef.current = newValue; };

    // color, background
    const [colorState, setColorState] = useState('#ffffff');
    const [backgroundState, setBackgroundState] = useState('#000000');

    // image
    const [buttonImage, setButtonImage] = useState<File | null>(null);
    const handleImage = (file: File | null, inputKey: string) => setButtonImage(file);

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!nameTextFieldRef.current || nameTextFieldRef.current.length === 0 || !urlTextFieldRef.current || urlTextFieldRef.current.length === 0) {
                toast("Заполните все поля", { type: "error" });
                return;
            }

            saveButton(nameTextFieldRef.current, urlTextFieldRef.current, colorState, backgroundState, buttonImage, paddingField.current, radiusField.current);
            handleNameTextFieldValueChange('');
            handleUrlTextFieldValueChange('');
            setColorState('#ffffff');
            setBackgroundState('#000000');
            setButtonImage(null);
        }} title={'Кнопка'} description="Загрузка картинки (Опционально)">
            <StandartTextFieldWrapper
                placeholder="Введите надпись на кнопке"
                handleTextFieldValueChange={handleNameTextFieldValueChange}
                initText={nameTextFieldRef.current && nameTextFieldRef.current.length > 0 ? nameTextFieldRef.current : null}
            />
            <SimpleRetirementComponent size={10} />
            <CustomMuiFileInputComponent
                value={buttonImage}
                placeholder={"Загрузите картинку"}
                onChange={handleImage}
                aspectRatio={1}
                inputKey={"1"}
                initValue={buttonImage && buttonImage.name.length > 0 ? buttonImage.name : ''}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Введите URL-адрес"
                handleTextFieldValueChange={handleUrlTextFieldValueChange}
                initText={urlTextFieldRef.current && urlTextFieldRef.current.length > 0 ? urlTextFieldRef.current : null}
            />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Фон" color={backgroundState} onSave={setBackgroundState} />
                <ColorPickerButtonComponent text="Текст" color={colorState} onSave={setColorState} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
                <RadiusComponent init={updateValue ? updateValue.radius ?? undefined : undefined} onSave={onRadiusChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};
