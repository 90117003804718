import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { Orders } from "../model/OrdersDataResponse";
import ordersDataStore from "../store/OrdersDataStore";

export default async function GetOrdersDataService({ pageId, page = 1, push = true, onlyRow }: { pageId: string; page?: number; push?: boolean; onlyRow?: string }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "type": "page",
        "subType": "getClientOrders",
        "accessToken": accessToken,
        "pageId": pageId,
        "page": page.toString(),
        "onlyRow": onlyRow ?? ''
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result) return;

    //save data
    const newData = {
        orders: result.data.orders as Orders,
        pageId: pageId
    };
    if (push) ordersDataStore.pushData(newData);
    else ordersDataStore.insertOrderToPage(newData, pageId);
    return newData;
}