import { Card, CardContent } from "@mui/material";
import { useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import { AppointmentValueResponse, Item } from "../../customize/model/AppointmentValueResponse";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import SwipeableComponent from "../../shared/ui/SwipeableComponent";
import { Activity } from "../model/PublicDataResponse";
import ArrowRightIcon from '../../../core/assets/module/configuration/arrow-right.svg';
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AppointmentItemImagesViewerComponent from "./AppointmentItemImagesViewerComponent";

const AppointmentLinkOptionComponent = ({ open, onDismiss, handleChangeLink, appointmentData, activeLink, setActiveLink, pageId }: { open: boolean; onDismiss: any; handleChangeLink: any; appointmentData: Activity | null; activeLink: string; setActiveLink: any; pageId: string }) => {
    // init
    const appointmentValue = appointmentData ? JSON.parse(appointmentData.value) as AppointmentValueResponse : { links: [], items: [], category: [] };

    // category
    function handleCategoryChange(id: string) {
        const foundLinks = appointmentValue.links.filter((item) => item.category === id);
        const localActiveService: { linkId: string, item: Item }[] = [];

        for (const link of foundLinks) {
            const foundItem = appointmentValue.items.find((item) => item.id === link.item);
            if (!foundItem) continue;
            localActiveService.push({ linkId: link.id, item: foundItem });
        }
        setActiveServices(localActiveService);
        setActiveLink(id);
    }

    // items
    const [activeServices, setActiveServices] = useState<{ linkId: string, item: Item }[]>([]);
    function handleServiceChange(id: string) { handleChangeLink(id); }
    function convertLength(length: string) {
        switch (length) {
            case '10':
                return '10 минут';
            case '30':
                return '30 минут';
            case '60':
                return '1 час';
            case '120':
                return '2 часа';
            case '180':
                return '3 часа';
            case '300':
                return '5 часов';
            case '600':
                return '10 часов';
            case '1380':
                return '24 часа';
        }
    }

    // image handle
    const [imageViewerState, setViewerState] = useState(false);
    const [itemIdValue, setItemIdValue] = useState('');
    const [activeImages, setActiveImages] = useState<string[]>([]);
    function imageClickHandle(itemId: string, images: string[]) {
        setItemIdValue(itemId);
        setActiveImages(images);
        setViewerState(true);
    }

    const ServiceBlock = () => {
        return (
            activeLink === ''
                ? null
                : activeServices.length === 0
                    ? <div>
                        <SimpleRetirementComponent />
                        <SimpleTextComponent>Нет свободных окошек.</SimpleTextComponent>
                    </div>
                    : <div>
                        <SimpleRetirementComponent />
                        <Card variant="outlined" style={{ borderRadius: TemplateConfig.borderRadius, borderColor: TemplateConfig.greyBorderColor }}>
                            <CardContent style={{ padding: 16 }}>
                                {
                                    <div>
                                        {
                                            activeServices.map((field, index) => (
                                                <div key={index}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {
                                                                field.item.images.length > 0
                                                                    ? <>
                                                                        <LinkWrapperComponent onClick={() => imageClickHandle(
                                                                            field.item.id,
                                                                            field.item.images
                                                                        )}
                                                                        >
                                                                            <img
                                                                                style={{ width: 42, height: 42, borderRadius: 8 }}
                                                                                src={`${ApplicationConfig.thisProductBackendImagesPath}${pageId}/${field.item.id}/${field.item.images[0]}`}
                                                                                alt="Изображение"
                                                                            />
                                                                        </LinkWrapperComponent>

                                                                        <SimpleRetirementComponent size={10} />
                                                                    </>
                                                                    : null
                                                            }
                                                            <div>
                                                                <SimpleTextComponent type='hardMedium'>
                                                                    {field.item.name}
                                                                </SimpleTextComponent>

                                                                <div style={{ display: 'flex', paddingTop: 5, whiteSpace: 'pre-wrap' }}>
                                                                    <SimpleTextComponent type='paragraph' lineThrough={field.item.discount && field.item.discount.length > 0 ? true : false}>
                                                                        {`${field.item.price && field.item.price.length > 0 ? PriceFormatUtil(Number(field.item.price)) : ''}`}
                                                                    </SimpleTextComponent>

                                                                    <SimpleTextComponent type='paragraph'>
                                                                        {`${field.item.discount && field.item.discount.length > 0 ? " " : ''}`}
                                                                        {`${field.item.discount && field.item.discount.length > 0 ? PriceFormatUtil(Number(field.item.discount)) : ''}`}
                                                                        {`${(field.item.discount && field.item.discount.length > 0) || (field.item.price && field.item.price.length > 0) ? " - " : ''}`}
                                                                    </SimpleTextComponent>

                                                                    <SimpleTextComponent type='paragraph'>
                                                                        {convertLength(field.item.length)}
                                                                    </SimpleTextComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <LinkWrapperComponent onClick={() => { handleServiceChange(field.linkId) }}>
                                                            <img width="24px" src={ArrowRightIcon} alt="arrow right icon" />
                                                        </LinkWrapperComponent>
                                                    </div>
                                                    {
                                                        index === activeServices.length - 1
                                                            ? null
                                                            : <SimpleRetirementComponent size={5} />
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </CardContent>
                        </Card>
                    </div>
        );
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={'Записаться'}>
            <SwipeableComponent>
                {
                    appointmentValue.category.map((field, index) => (
                        <div key={index} style={{ marginRight: 10, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                            <RaisedButtonComponent
                                backgroundColor={activeLink === field.id ? TemplateConfig.purpleColor : TemplateConfig.greyBorderColor}
                                placeholder={field.name}
                                onClick={() => handleCategoryChange(field.id)}
                            />
                        </div>
                    ))
                }
            </SwipeableComponent>
            <ServiceBlock />
            <AppointmentItemImagesViewerComponent open={imageViewerState} onDismiss={() => setViewerState(false)} images={activeImages} pageId={pageId} itemId={itemIdValue} />
        </StandartBottomSheetComponent>
    );
}

export default AppointmentLinkOptionComponent;