import TemplateConfig from "../../../../config/TemplateConfig";

const RemoveBackgroundIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.01012 18.0301L3.00012 14.0201C1.66012 12.6801 1.66012 11.3501 3.00012 10.0101L9.68012 3.33008L17.0301 10.6801C17.4001 11.0501 17.4001 11.6501 17.0301 12.0201L11.0101 18.0401C9.69012 19.3601 8.35012 19.3601 7.01012 18.0301Z" stroke={color} stroke-width="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.3501 3L10.6901 4.33997" stroke={color} stroke-width="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.85 15.9099C18.85 15.9099 17 17.9199 17 19.1499C17 20.1699 17.83 20.9999 18.85 20.9999C19.87 20.9999 20.7 20.1699 20.7 19.1499C20.7 17.9199 18.85 15.9099 18.85 15.9099Z" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.5 14C10.875 14 12 12.875 12 11.5C12 10.125 10.875 9 9.5 9C8.125 9 7 10.125 7 11.5C7 12.875 8.125 14 9.5 14Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.79248 12.2075L10.2075 10.7925" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.2075 12.2075L8.79248 10.7925" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default RemoveBackgroundIcon;