import { Switch } from "@mui/material";
import { useState } from "react";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import WatermarkService from "../service/WatermarkService";

const WatermarkComponent = ({ open, onDismiss, init, pageId }: { open: boolean; onDismiss: any; init: string; pageId: string }) => {
    const [watermark, setWatermark] = useState(init.toString() === '1' ? true : false);
    async function saveWatermark() {
        await WatermarkService({
            pageId: pageId,
            watermark: watermark ? '1' : '0'
        });
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Брендинг" onSubmit={saveWatermark}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <SimpleTextComponent>Показать</SimpleTextComponent>
                <Switch
                    color="secondary"
                    checked={watermark}
                    onChange={(_event, checked) => setWatermark(checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    style={{

                    }}
                />
            </div>
        </StandartBottomSheetComponent>
    );
}

export default WatermarkComponent;