import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import TemplateConfig from '../../../core/config/TemplateConfig';
import { MapAttributeResponse } from "../../customize/model/MapAttributeResponse";
import React from 'react';

const MapAttributeComponent = React.memo(({ item }: { item: MapAttributeResponse }) => {
    return (
        <YMaps>
            <Map style={{ height: '200px', width: '100%' }} defaultState={{ center: [Number(item.latitude), Number(item.longitude)], zoom: 16 }}>
                <Placemark properties={{ iconCaption: item.placeholder }} options={{ iconColor: TemplateConfig.blackColor, preset: 'islands#dotIcon' }} geometry={[Number(item.latitude), Number(item.longitude)]} />
            </Map>
            <style>
                {`
                    ymaps {
                        border-radius: ${item.radius ? `${item.radius}px` : "16px"};
                    }
                `}
            </style>
        </YMaps>
    );
});

export default MapAttributeComponent;