import { useState } from "react";
import { Attribute, PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import DeleteAttributeService from "../service/DeleteAttributeService";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import AppointmentEditMenuComponent from '../ui/attributes/AppointmentEditMenuComponent';
import MoreBlackIcon from '../../../core/assets/module/customize/more.svg';
import UpdateAttributeComponent from "./UpdateAttributeComponent";
import CloneAttributeService from "../service/CloneAttributeService";

export default function DraggableItemLeftRow({ item, choosedPage, setItems }: { item: Attribute; choosedPage: PagesDataResponseImpl; setItems: any }) {
    // handle set items
    function handleReloadItems() { setItems((pagesDataStore.pagesData!.find(item => item.page_id === choosedPage.page_id) as PagesDataResponseImpl).attributes); }

    // delete action
    const [deleteConfirmState, setDeleteConfirmState] = useState(false);
    async function onRemoveAttribute() {
        setDeleteConfirmState(false);
        await DeleteAttributeService({ attributeId: item.id, pageId: choosedPage.page_id });
        handleReloadItems();
    }

    // clone action
    const [cloneConfirmState, setCloneConfirmState] = useState(false);
    async function onCloneAttribute() {
        setCloneConfirmState(false);
        await CloneAttributeService({ attributeId: item.id, pageId: choosedPage.page_id });
        handleReloadItems();
    }

    // update action
    const [updateState, setUpdateState] = useState(false);
    async function onUpdateAttribute() {
        setUpdateState(false);
        handleReloadItems();
    }

    // choose action
    const [attributeMenuState, setAttributeMenuState] = useState(false);

    return (
        <div style={{ textAlign: 'left' }}>
            <LinkWrapperComponent onClick={() => setAttributeMenuState(true)}>
                <img width="18px" src={MoreBlackIcon} alt="more icon" />
            </LinkWrapperComponent>
            <ConfirmBottomSheetComponent
                open={deleteConfirmState}
                onDismiss={() => setDeleteConfirmState(false)}
                onSubmit={onRemoveAttribute}
                description="Вы собираетесь удалить выбранный блок."
                title="Удалить"
            />
            <ConfirmBottomSheetComponent
                open={cloneConfirmState}
                onDismiss={() => setCloneConfirmState(false)}
                onSubmit={onCloneAttribute}
                description="Вы собираетесь дублировать выбранный блок."
                title="Дублировать"
            />
            <AppointmentEditMenuComponent
                open={attributeMenuState}
                onDismiss={() => setAttributeMenuState(false)}
                onDelete={() => {
                    setAttributeMenuState(false);
                    setDeleteConfirmState(true);
                }}
                onEdit={() => {
                    setAttributeMenuState(false);
                    setUpdateState(true);
                }}
                onClone={() => {
                    setAttributeMenuState(false);
                    setCloneConfirmState(true);
                }}
            />
            <UpdateAttributeComponent
                open={updateState}
                item={item}
                choosedPage={choosedPage}
                onDismiss={() => setUpdateState(false)}
                onUpdate={onUpdateAttribute}
            />
        </div>
    );
}