import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function DeleteAttributeService({ attributeId, pageId }: { attributeId: any; pageId: any; }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "type": "page",
        "subType": "deleteAttribute",
        "accessToken": accessToken,
        "pageId": pageId,
        "attributeId": attributeId,
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: "Не удалось удалить"
        }
    );

    //if error
    if (result === null) return;

    //store
    pagesDataStore.deleteAttribute(attributeId, pageId);

    //notify
    toast("Блок успешно удален", {
        type: "success"
    });
}