import { styled } from "@mui/material";
import { useState } from "react";
import BlockAddBottomSheetComponent from "./BlockAddBottomSheetComponent";
import { PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import ImageBlockBuildBottomSheetComponent from "./attributes/ImageBlockBuilderBottomSheetComponent";
import InsertImageBlockService from "../service/InsertImageBlockService";
import InsertProfileBlockService from "../service/InsertProfileBlockService";
import InsertDirectlyLoadingBlockService from "../service/InsertDirectlyLoadingBlockService";
import ButtonBlockBuildBottomSheetComponent from "./attributes/ButtonBlockBuilderBottomSheetComponent";
import TextBlockBuildBottomSheetComponent from "./attributes/TextBlockBuilderBottomSheetComponent";
import ProfilelockBuildBottomSheetComponent from "./attributes/ProfileBlockBuilderBottomSheetComponent";
import SocialBlockBuildBottomSheetComponent from "./attributes/SocialBlockBuilderBottomSheetComponent";
import TimerlockBuildBottomSheetComponent from "./attributes/TimerBlockBuilderBottomSheetComponent";
import FormBlockBuildBottomSheetComponent from "./attributes/FormBlockBuilderBottomSheetComponent";
import AddIcon from '../../../core/assets/module/customize/add.svg';
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { ImageElement, ImageElementWithNullableElements } from "../model/ImageAttributeRequest";
import MapBlockBuildBottomSheetComponent from "./attributes/MapBlockBuilderBottomSheetComponent";
import VideoBlockBuildBottomSheetComponent from "./attributes/VideoBlockBuilderBottomSheetComponent";
import TemplateConfig from "../../../core/config/TemplateConfig";
import AppointmentBlockBuilderBottomSheetComponent from "./attributes/AppointmentBlockBuilderBottomSheetComponent";
import DividerBlockBuildBottomSheetComponent from "./attributes/DividerBlockBuilderBottomSheetComponent";
import QuestionBlockBuildBottomSheetComponent from "./attributes/QuestionBlockBuilderBottomSheetComponent";
import InsertRowBlockService from "../service/InsertRowBlockService";
import RowBlockBuildBottomSheetComponent from "./attributes/RowBlockBuilderBottomSheetComponent";
import PricelistBlockBuildBottomSheetComponent from "./attributes/PricelistBlockBuilderBottomSheetComponent";
import InsertButtonBlockService from "../service/InsertButtonBlockService";
import AudioBlockBuildBottomSheetComponent from "./attributes/AudioBlockBuilderBottomSheetComponent";
import InsertAudioBlockService from "../service/InsertAudioBlockService";
import { useNavigate } from "react-router-dom";
import InsertDividerBlockService from "../service/InsertDividerBlockService";
import MenuBlockBuildBottomSheetComponent from "./attributes/MenuBlockBuilderBottomSheetComponent copy";

export default function CustomizeBottomComponent({ setItems, choosedPage }: { setItems: any; choosedPage: PagesDataResponseImpl; }) {
    const navigate = useNavigate();
    //choose block
    const [blockAddStateOpen, setBlockAddComponentState] = useState(false);

    //style
    const BottomBlock = styled('div')({
        paddingTop: 10,
        paddingBottom: 24,
        paddingLeft: TemplateConfig.longPadding,
        paddingRight: TemplateConfig.longPadding,
        borderTop: `1px solid ${TemplateConfig.greyBorderColor}`,
    });


    // DIRECTLY LOADING 

    const [textBlockState, setTextBlockState] = useState(false);
    const [socialBlockState, setSocialBlockState] = useState(false);
    const [timerBlockState, setTimerBlockState] = useState(false);
    const [formBlockState, setFormBlockState] = useState(false);
    const [mapBlockState, setMapBlockState] = useState(false);
    const [videoBlockState, setVideoBlockState] = useState(false);
    const [appointmentBlockState, setAppointmentBlockState] = useState(false);
    const [questionBlockState, setQuestionBlockState] = useState(false);
    const [pricelistBlockState, setPricelistBlockState] = useState(false);
    const [menuBlockState, setMenuBlockState] = useState(false);

    async function saveDirectlyLoading(type: string, value: string, padding: string) {
        switch (type) {
            case 'text':
                setTextBlockState(false);
                break;
            case 'social':
                setSocialBlockState(false);
                break;
            case 'timer':
                setTimerBlockState(false);
                break;
            case 'form':
                setFormBlockState(false);
                break;
            case 'map':
                setMapBlockState(false);
                break;
            case 'youtube':
                setVideoBlockState(false);
                break;
            case 'appointment':
                setAppointmentBlockState(false);
                break;
            case 'question':
                setQuestionBlockState(false);
                break;
            case 'pricelist':
                setPricelistBlockState(false);
                break;
            case 'menu':
                setMenuBlockState(false);
                break;
        }

        await InsertDirectlyLoadingBlockService({ pageId: choosedPage.page_id, attributeType: type, attributeValue: value, attributePadding: padding });
        setItems(pagesDataStore.pagesData!.find(item => item.page_id === choosedPage.page_id)?.attributes, true);
    }

    // CONFIGURE LOADING

    //image block
    const [imageBlockState, setImageBlockState] = useState(false);
    //slider block
    const [sliderBlockState, setSliderBlockState] = useState(false);
    //banner block
    const [bannerBlockState, setBannerBlockState] = useState(false);

    // divider
    const [dividerBlockState, setDividerBlockState] = useState(false);
    async function saveDivider(color: string, image: File | null, padding: string) {
        setDividerBlockState(false);

        await InsertDividerBlockService({
            pageId: choosedPage.page_id,
            color: color,
            image: image,
            attributePadding: padding
        });
        setItems(pagesDataStore.pagesData!.find(item => item.page_id === choosedPage.page_id)?.attributes);
    }



    //handle common save image
    async function saveImage(images: ImageElementWithNullableElements[], type: string, padding: string, radius: string) {
        if (type === 'image') setImageBlockState(false);
        if (type === 'slider') setSliderBlockState(false);
        if (type === 'banner') setBannerBlockState(false);

        const imageArrayToLoad = [];
        for (var imageItem of images) {
            if (!imageItem.image) continue;
            imageArrayToLoad.push({ image: imageItem.image, url: imageItem.url ?? '' } as ImageElement)
        }

        await InsertImageBlockService({ pageId: choosedPage.page_id, type: type, imageAttributeRequest: { images: imageArrayToLoad }, attributePadding: padding, radius: radius });
        setItems(pagesDataStore.pagesData!.find(item => item.page_id === choosedPage.page_id)?.attributes);
    }

    //profile block
    const [profileBlockState, setProfileBlockState] = useState(false);
    async function saveProfile(title: string, description: string, avatar: File | null, background: File | null, color: string, padding: string, radius: string) {
        setProfileBlockState(false);

        await InsertProfileBlockService({ pageId: choosedPage.page_id, avatar: avatar, background: background, title: title, description: description, color: color, attributePadding: padding, radius: radius });
        setItems(pagesDataStore.pagesData!.find(item => item.page_id === choosedPage.page_id)?.attributes);
    }

    // row block
    const [rowBlockState, setRowBlockState] = useState(false);
    async function saveRow(title: string, value: string, image: File, padding: string, color: string, radius: string) {
        setRowBlockState(false);

        await InsertRowBlockService({
            pageId: choosedPage.page_id,
            title: title,
            value: value,
            image: image,
            color: color,
            radius: radius,
            attributePadding: padding
        });
        setItems(pagesDataStore.pagesData!.find(item => item.page_id === choosedPage.page_id)?.attributes);
    }

    // button block
    const [buttonBlockState, setButtonBlockState] = useState(false);
    async function saveButton(placeholder: string, url: string, color: string, background: string, image: File | null, padding: string, radius: string) {
        setButtonBlockState(false);

        await InsertButtonBlockService({
            pageId: choosedPage.page_id,
            placeholder: placeholder,
            url: url,
            color: color,
            radius: radius,
            background: background,
            image: image,
            attributePadding: padding
        });
        setItems(pagesDataStore.pagesData!.find(item => item.page_id === choosedPage.page_id)?.attributes);
    }

    // audio block
    const [audioBlockState, setAudioBlockState] = useState(false);
    async function saveAudio(color: string, audio: File, padding: string) {
        setAudioBlockState(false);

        await InsertAudioBlockService({
            pageId: choosedPage.page_id,
            color: color,
            audio: audio,
            attributePadding: padding
        });
        setItems(pagesDataStore.pagesData!.find(item => item.page_id === choosedPage.page_id)?.attributes);
    }

    //widget
    return (
        <div>
            <BottomBlock>
                <RaisedButtonComponent
                    onClick={() => setBlockAddComponentState(true)}
                    placeholder="Новый блок"
                    icon={AddIcon}
                />
            </BottomBlock>

            {/* GLOBAL BLOCK */}
            <BlockAddBottomSheetComponent
                navigate={navigate}
                open={blockAddStateOpen}
                onDismiss={() => setBlockAddComponentState(false)}
                onTextBlock={() => {
                    setBlockAddComponentState(false);
                    setTextBlockState(true);
                }}
                onDividerBlock={() => {
                    setBlockAddComponentState(false);
                    setDividerBlockState(true);
                }}
                onButtonBlock={() => {
                    setBlockAddComponentState(false);
                    setButtonBlockState(true);
                }}
                onImageBlock={() => {
                    setBlockAddComponentState(false);
                    setImageBlockState(true);
                }}
                onProfileBlock={() => {
                    setBlockAddComponentState(false);
                    setProfileBlockState(true);
                }}
                onSocialBlock={() => {
                    setBlockAddComponentState(false);
                    setSocialBlockState(true);
                }}
                onTimerBlock={() => {
                    setBlockAddComponentState(false);
                    setTimerBlockState(true);
                }}
                onFormBlock={() => {
                    setBlockAddComponentState(false);
                    setFormBlockState(true);
                }}
                onSliderBlock={() => {
                    setBlockAddComponentState(false);
                    setSliderBlockState(true);
                }}
                onMapBlock={() => {
                    setBlockAddComponentState(false);
                    setMapBlockState(true);
                }}
                onVideoBlock={() => {
                    setBlockAddComponentState(false);
                    setVideoBlockState(true);
                }}
                onAppointmentBlock={() => {
                    setBlockAddComponentState(false);
                    setAppointmentBlockState(true);
                }}
                onQuestionBlock={() => {
                    setBlockAddComponentState(false);
                    setQuestionBlockState(true);
                }}
                onRowBlock={() => {
                    setBlockAddComponentState(false);
                    setRowBlockState(true);
                }}
                onPricelistBlock={() => {
                    setBlockAddComponentState(false);
                    setPricelistBlockState(true);
                }}
                onAudioBlock={() => {
                    setBlockAddComponentState(false);
                    setAudioBlockState(true);
                }}
                onBannerBlock={() => {
                    setBlockAddComponentState(false);
                    setBannerBlockState(true);
                }}
                onMenuBlock={() => {
                    setBlockAddComponentState(false);
                    setMenuBlockState(true);
                }}
            />

            {/* TITLE BLOCK */}
            <TextBlockBuildBottomSheetComponent open={textBlockState} onDismiss={() => setTextBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} font={choosedPage.font} />

            {/* BUTTON BLOCK */}
            <ButtonBlockBuildBottomSheetComponent open={buttonBlockState} onDismiss={() => setButtonBlockState(false)} saveButton={saveButton} />

            {/* IMAGE BLOCK */}
            <ImageBlockBuildBottomSheetComponent type="image" open={imageBlockState} onDismiss={() => setImageBlockState(false)} saveImage={saveImage} />

            {/* PROFILE BLOCK */}
            <ProfilelockBuildBottomSheetComponent open={profileBlockState} onDismiss={() => setProfileBlockState(false)} saveProfile={saveProfile} />

            {/* SOCIAL BLOCK */}
            <SocialBlockBuildBottomSheetComponent open={socialBlockState} onDismiss={() => setSocialBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} />

            {/* TIMER BLOCK */}
            <TimerlockBuildBottomSheetComponent open={timerBlockState} onDismiss={() => setTimerBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} />

            {/* FORM BLOCK */}
            <FormBlockBuildBottomSheetComponent open={formBlockState} onDismiss={() => setFormBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} />

            {/* SLIDER BLOCK */}
            <ImageBlockBuildBottomSheetComponent type="slider" open={sliderBlockState} onDismiss={() => setSliderBlockState(false)} saveImage={saveImage} />

            {/* MAP BLOCK */}
            <MapBlockBuildBottomSheetComponent pageId={choosedPage.page_id} open={mapBlockState} onDismiss={() => setMapBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} />

            {/* VIDEO BLOCK */}
            <VideoBlockBuildBottomSheetComponent open={videoBlockState} onDismiss={() => setVideoBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} />

            {/* APPOINTMENT BLOCK */}
            <AppointmentBlockBuilderBottomSheetComponent choosedPage={choosedPage} open={appointmentBlockState} onDismiss={() => setAppointmentBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} />

            {/* DIVIDER BLOCK */}
            <DividerBlockBuildBottomSheetComponent open={dividerBlockState} onDismiss={() => setDividerBlockState(false)} saveDivider={saveDivider} />

            {/* QUESTION BLOCK */}
            <QuestionBlockBuildBottomSheetComponent open={questionBlockState} onDismiss={() => setQuestionBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} />

            {/* ROW BLOCK */}
            <RowBlockBuildBottomSheetComponent open={rowBlockState} onDismiss={() => setRowBlockState(false)} saveRow={saveRow} />

            {/* PRICELIST BLOCK */}
            <PricelistBlockBuildBottomSheetComponent open={pricelistBlockState} onDismiss={() => setPricelistBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} />

            {/* AUDIO BLOCK */}
            <AudioBlockBuildBottomSheetComponent open={audioBlockState} onDismiss={() => setAudioBlockState(false)} saveAudio={saveAudio} />

            {/* BANNER BLOCK */}
            <ImageBlockBuildBottomSheetComponent type="banner" open={bannerBlockState} onDismiss={() => setBannerBlockState(false)} saveImage={saveImage} />

            {/* MENU BLOCK */}
            <MenuBlockBuildBottomSheetComponent open={menuBlockState} onDismiss={() => setMenuBlockState(false)} saveDirectlyLoading={saveDirectlyLoading} />
        </div>
    );
}