import ApplicationConfig from "../../../core/config/ApplicationConfig";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import VWEBIcon from "../../../core/assets/module/configuration/icon-vweb.svg";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import AuthorizationService from "../service/AuthorizationService";
import { useNavigate, useSearchParams } from "react-router-dom";
import UrlLauncherUtil from "../../../core/utils/UrlLauncherUtil";
import { useEffect, useRef, useState } from "react";
import StandartTextFieldWrapper from "../../shared/ui/StandartTextFieldWrapper";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import ResetPasswordService from "../service/ResetPasswordService";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import MailIcon from "../../../core/assets/module/input/mail.svg";
import EyeIcon from "../../../core/assets/module/input/eye.svg";
import EyeSlashIcon from "../../../core/assets/module/input/eye-slash.svg";
import { InputAdornment } from "@mui/material";

const AuthenticateComponent = () => {
  // page state variables
  const [searchParams] = useSearchParams();
  const [isAuth, setIsAuth] = useState(true);
  const [isEmailAuth, setIsEmailAuth] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isRegistrationFinish, setIsRegistrationFinish] = useState(false);

  // login
  const login = useGoogleLogin({
    onSuccess: credentialResponse => {
      AuthorizationService({
        googleAccessToken: credentialResponse.access_token,
        email: '',
        password: '',
        navigate: navigate,
        registrationProcedure: ''
      });
    }
  });
  async function emailAuth() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailField.current || emailField.current.length < 3 || !emailRegex.test(emailField.current)) {
      toast("Некорректный адрес эл. почты", { type: "error" });
      return;
    }

    if (!passwordField.current || passwordField.current.length < 3) {
      toast("Пароль должен содержать мин. 6 символов", { type: "error" });
      return;
    }

    var result = await AuthorizationService({
      googleAccessToken: '',
      email: emailField.current,
      password: passwordField.current,
      navigate: navigate,
      registrationProcedure: isAuth ? '0' : '1'
    });
    if (!isAuth && result) {
      setIsRegistrationFinish(true);
      toast("Регистрация прошла успешно!", { type: "success" });
    }
  }

  // confirm message
  const confirmMessage = searchParams.get('confirm');
  useEffect(() => {
    if (confirmMessage && confirmMessage === 'true') {
      toast("Ваш аккаунт успешно подтвержден", { type: "success" });
    }
  }, [confirmMessage]);

  // email
  const emailField = useRef('');
  const onEmailChange = (newValue: string) => { emailField.current = newValue };

  // password
  const [obsecure, setObsecure] = useState(true);
  const passwordField = useRef('');
  const onPasswordChange = (newValue: string) => { passwordField.current = newValue };

  // navigate
  const navigate = useNavigate();

  // reset password
  function resetPassword() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailField.current || emailField.current.length < 3 || !emailRegex.test(emailField.current)) {
      toast("Некорректный адрес эл. почты", { type: "error" });
      return;
    }

    ResetPasswordService({ email: emailField.current });
    setIsPasswordReset(false);
    setIsAuth(true);
    setIsEmailAuth(true);
  }

  // widget
  const headerBlock = () => {
    return (
      <div>
        <LinkWrapperComponent onClick={() => UrlLauncherUtil({ url: ApplicationConfig.applicationInfoLink })}>
          <img src={VWEBIcon} alt="vweb icon" height={80} />
        </LinkWrapperComponent>
        <SimpleRetirementComponent size={30} />
        <SimpleTextComponent type='semiboldHeader'>
          {isPasswordReset ? "Восстановление" : isAuth ? `${ApplicationConfig.applicationName} – платформа для создания мобильных веб-сайтов` : 'Регистрация'}
        </SimpleTextComponent>
        <SimpleRetirementComponent size={30} />
      </div>
    );
  }
  const emailAuthBlock = () => {
    return (
      !isEmailAuth
        ? <RaisedButtonComponent
          placeholder="Войти через Email"
          onClick={() => setIsEmailAuth(true)}
        />
        : <div>
          <StandartTextFieldWrapper
            placeholder="Введите адрес эл. почты"
            handleTextFieldValueChange={onEmailChange}
            endAdornment={(
              <InputAdornment position="end">
                <img style={{ paddingRight: 0 }} src={MailIcon} alt="mail icon" />
              </InputAdornment>
            )}
          />
          <SimpleRetirementComponent size={10} />
          <StandartTextFieldWrapper
            placeholder="Введите пароль"
            handleTextFieldValueChange={onPasswordChange}
            password={obsecure}
            endAdornment={(
              <InputAdornment position="end">
                <LinkWrapperComponent onClick={() => setObsecure(!obsecure)}>
                  <img style={{ paddingRight: 0 }} src={obsecure ? EyeIcon : EyeSlashIcon} alt="eye icon" />
                </LinkWrapperComponent>
              </InputAdornment>
            )}
          />
          <SimpleRetirementComponent size={20} />
          <RaisedButtonComponent
            placeholder={isAuth ? "Войти через Email" : "Зарегистрироваться"}
            onClick={() => emailAuth()}
          />
        </div>
    );
  }
  const passwordResetBlock = () => {
    return (
      <div>
        <StandartTextFieldWrapper
          placeholder="Введите адрес эл. почты"
          handleTextFieldValueChange={onEmailChange}
          endAdornment={(
            <InputAdornment position="end">
              <img style={{ paddingRight: 0 }} src={MailIcon} alt="mail icon" />
            </InputAdornment>
          )}
        />
        <SimpleRetirementComponent />
        <RaisedButtonComponent
          placeholder="Отправить"
          onClick={() => resetPassword()}
        />
      </div>
    );
  }
  const rulesBlock = () => {
    function openLink(ruleLink: string) {
      UrlLauncherUtil({ url: `${ApplicationConfig.applicationInfoLink}${ruleLink}`, newTab: true });
    }

    return (
      <SimpleTextComponent type='regularParagraph'>
        Продолжая, Вы соглашаетесь с&nbsp;
        <LinkWrapperComponent onClick={() => openLink('agreement.html')} underline>
          условиями оферты
        </LinkWrapperComponent>
        ,&nbsp;
        <LinkWrapperComponent onClick={() => openLink('privacy.html')} underline>
          политикой конфиденциальности
        </LinkWrapperComponent>
        &nbsp;и&nbsp;
        <LinkWrapperComponent onClick={() => openLink('processpay.html')} underline>
          правилами процесса оплаты
        </LinkWrapperComponent>
        .
      </SimpleTextComponent>
    );
  }
  const nagivationBlock = () => {
    return (
      <div>
        <SimpleRetirementComponent size={20} />
        {
          !isPasswordReset && (!isAuth || !isEmailAuth)
            ? <div>
              {rulesBlock()}
              <SimpleRetirementComponent size={10} />
            </div>
            : null
        }
        {
          !isPasswordReset && isAuth && <SimpleTextComponent type='lightTitle'>
            Нет аккаунта? <LinkWrapperComponent onClick={() => { setIsEmailAuth(true); setIsAuth(!isAuth); }} underline>Создать</LinkWrapperComponent>
          </SimpleTextComponent>
        }
        {
          !isPasswordReset && !isAuth && <SimpleTextComponent type='lightTitle'>
            У меня есть аккаунт. <LinkWrapperComponent onClick={() => { setIsEmailAuth(false); setIsAuth(!isAuth); }} underline>Войти</LinkWrapperComponent>
          </SimpleTextComponent>
        }
        {isEmailAuth && isAuth && !isPasswordReset && <SimpleRetirementComponent size={10} />}
        {
          isEmailAuth && isAuth && !isPasswordReset && <SimpleTextComponent type='lightTitle'>
            Забыли пароль? <LinkWrapperComponent onClick={() => setIsPasswordReset(true)} underline>Восстановить</LinkWrapperComponent>
          </SimpleTextComponent>
        }
        {
          isPasswordReset && <SimpleTextComponent type='lightTitle'>
            Я вспомнил(а) пароль. <LinkWrapperComponent onClick={() => setIsPasswordReset(false)} underline>Войти</LinkWrapperComponent>
          </SimpleTextComponent>
        }
      </div>
    );
  }
  const googleAuthBlock = () => {
    return (
      <div>
        <RaisedButtonComponent
          placeholder="Войти через Google"
          backgroundColor={TemplateConfig.darkPurpleColor}
          onClick={() => login()}
        />
        <SimpleRetirementComponent size={10} />
      </div>
    )
  };

  return (
    <div style={{ textAlign: 'center', paddingTop: 26, paddingBottom: 26 }}>
      {headerBlock()}
      {
        isRegistrationFinish
          ? <div>
            <SimpleTextComponent>
              Благодарим вас за выбор <span style={{ fontWeight: TemplateConfig.semiboldText, color: TemplateConfig.purpleColor }}>{ApplicationConfig.applicationName}</span>. Для продолжения необходимо подтвердить аккаунт. Инструкции отправлены на вашу эл. почту.
            </SimpleTextComponent>
            <SimpleRetirementComponent />
            <SimpleTextComponent type='lightTitle'>
              <LinkWrapperComponent onClick={() => UrlLauncherUtil({ url: ApplicationConfig.fullApplicationLink })} underline>Вернуться назад</LinkWrapperComponent>
            </SimpleTextComponent>
          </div>
          : <>
            {isAuth && !isPasswordReset && googleAuthBlock()}
            {isPasswordReset ? passwordResetBlock() : emailAuthBlock()}
            {nagivationBlock()}
          </>
      }
    </div>
  );
}

export default AuthenticateComponent;