import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { ProductPurchasedResponse } from "../model/ProductPurchasedResponse";
import productPurchasedStore from "../store/ProductPurchasedStore";

export default async function ProductPurchasedVerify({ accessToken }: { accessToken: string }) {
    const params = {
        "type": "order",
        "accessToken": accessToken,
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    if (!result || !result.data || !(result.data as ProductPurchasedResponse).orders) return;

    productPurchasedStore.saveData(result.data);
}