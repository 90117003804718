import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import pagesDataStore from "../store/PagesDataStore";

export default async function WatermarkService({ pageId, watermark }: { pageId: string; watermark: string }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return false;

    const params = {
        "type": "page",
        "subType": "watermark",
        "accessToken": accessToken,
        "pageId": pageId,
        "watermark": watermark
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result) return false;

    // success
    pagesDataStore.updateWatermark(watermark, pageId);
    toast("Брендинг успешно изменен", { type: "success" });
    return true;
}