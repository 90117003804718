import TemplateConfig from "../../../../config/TemplateConfig";

const UnderlineIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 21H19" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 3V10C5 13.87 8.13 17 12 17C15.87 17 19 13.87 19 10V3" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default UnderlineIcon;