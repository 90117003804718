export default function TelephoneFormateUtil({ value }: { value: string }) {
    const cleaned = value.replace(/\D/g, '');
    if (cleaned.length < 12) {
        let formattedPhoneNumber = '';
        if (cleaned.length >= 1) {
            formattedPhoneNumber += `+${cleaned[0]}`;
        }
        if (cleaned.length > 1) {
            formattedPhoneNumber += ` (${cleaned.slice(1, 4)}`;
        }
        if (cleaned.length > 4) {
            formattedPhoneNumber += `) ${cleaned.slice(4, 7)}`;
        }
        if (cleaned.length > 7) {
            formattedPhoneNumber += ` ${cleaned.slice(7, 11)}`;
        }

        return formattedPhoneNumber;
    }
    else if (cleaned.length === 12) {
        let formattedPhoneNumber = '';
        formattedPhoneNumber += `+${cleaned.slice(0, 2)}`;
        formattedPhoneNumber += ` (${cleaned.slice(2, 5)}`;
        formattedPhoneNumber += `) ${cleaned.slice(5, 8)}`;
        formattedPhoneNumber += ` ${cleaned.slice(8, 12)}`;

        return formattedPhoneNumber;
    }
    else {
        let formattedPhoneNumber = '';
        formattedPhoneNumber += `+${cleaned.slice(0, 3)}`;
        formattedPhoneNumber += ` (${cleaned.slice(3, 6)}`;
        formattedPhoneNumber += `) ${cleaned.slice(6, 9)}`;
        formattedPhoneNumber += ` ${cleaned.slice(9, 13)}`;

        return formattedPhoneNumber;
    }
}