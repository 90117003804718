import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import { SocialAttributeDao } from '../../../leaf/model/SocialAttributeDao';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import PaddingComponent from './PaddingComponent';

export default function SocialBlockBuildBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, updateValue, updatePadding }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; updateValue?: SocialAttributeDao; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        if (updateValue) {
            setTelephone(updateValue.telephone ?? '');
            setTelegram(updateValue.telegram ?? '');
            setWhatsapp(updateValue.whatsapp ?? '');
            setInsta(updateValue.insta ?? '');
            setFacebook(updateValue.facebook ?? '');
            setTiktok(updateValue.tiktok ?? '');
            setColorState(updateValue.color ?? '#ffffff')
            setBackgroundState(updateValue.background ?? '#000000')
        } else {
            setTelephone('');
            setTelegram('');
            setWhatsapp('');
            setInsta('');
            setFacebook('');
            setTiktok('');
        }
    }, [updateValue]);

    // color, background
    const [colorState, setColorState] = useState('#ffffff');
    const [backgroundState, setBackgroundState] = useState('#000000');

    // init text field
    const telephone = useRef('');
    const telegram = useRef('');
    const whatsapp = useRef('');
    const insta = useRef('');
    const facebook = useRef('');
    const tiktok = useRef('');

    const setTelephone = (newValue: string) => { telephone.current = newValue; };
    const setTelegram = (newValue: string) => { telegram.current = newValue; };
    const setWhatsapp = (newValue: string) => { whatsapp.current = newValue; };
    const setInsta = (newValue: string) => { insta.current = newValue; };
    const setFacebook = (newValue: string) => { facebook.current = newValue; };
    const setTiktok = (newValue: string) => { tiktok.current = newValue; };

    const socialNetworks = [
        {
            "placeholder": "Введите номер телефона",
            "filter": null,
            "onChange": setTelephone,
            "mobileFilter": true,
            "init": telephone.current && telephone.current.length > 0 ? telephone.current : null
        },
        {
            "placeholder": "Введите логин Telegram (без t.me/)",
            "filter": /\s/g,
            "onChange": setTelegram,
            "mobileFilter": false,
            "init": telegram.current && telegram.current.length > 0 ? telegram.current : null
        },
        {
            "placeholder": "Введите номер Whatsapp",
            "filter": null,
            "onChange": setWhatsapp,
            "mobileFilter": true,
            "init": whatsapp.current && whatsapp.current.length > 0 ? whatsapp.current : null
        },
        {
            "placeholder": "Введите логин Instagram",
            "filter": /\s/g,
            "onChange": setInsta,
            "mobileFilter": false,
            "init": insta.current && insta.current.length > 0 ? insta.current : null
        },
        {
            "placeholder": "Введите cсылку Facebook",
            "filter": /\s/g,
            "onChange": setFacebook,
            "mobileFilter": false,
            "init": facebook.current && facebook.current.length > 0 ? facebook.current : null
        },
        {
            "placeholder": "Введите логин TikTok (без @)",
            "filter": /\s/g,
            "onChange": setTiktok,
            "mobileFilter": false,
            "init": tiktok.current && tiktok.current.length > 0 ? tiktok.current : null
        }
    ];

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (
                (telephone.current === null || telephone.current.length === 0) &&
                (telegram.current === null || telegram.current.length === 0) &&
                (whatsapp.current === null || whatsapp.current.length === 0) &&
                (insta.current === null || insta.current.length === 0) &&
                (facebook.current === null || facebook.current.length === 0) &&
                (tiktok.current === null || tiktok.current.length === 0)
            ) {
                toast("Заполните как мин. одно поле", { type: "error" });
                return;
            }
            saveDirectlyLoading('social', JSON.stringify({ "telephone": telephone.current.length === 0 ? '' : `+${telephone.current.replace(/\D/g, "")}`, "telegram": telegram.current, "whatsapp": whatsapp.current.replace(/\D/g, ""), "insta": insta.current, "facebook": facebook.current, "tiktok": tiktok.current, "color": colorState, "background": backgroundState }), paddingField.current);
            setTelephone('');
            setTelegram('');
            setWhatsapp('');
            setInsta('');
            setFacebook('');
            setTiktok('');
            setColorState('#ffffff');
            setBackgroundState('#000000');
        }} title={'Ссылки'} description="Заполните нужные вам поля, оставляя ненужные пустыми.">
            {
                socialNetworks.map((item, index) => (
                    <div key={index}>
                        <StandartTextFieldWrapper
                            placeholder={item.placeholder}
                            handleTextFieldValueChange={item.onChange}
                            maxLength={200}
                            mobileFilter={item.mobileFilter}
                            filter={item.filter}
                            initText={item.init}
                        />
                        {
                            index === socialNetworks.length - 1
                                ? null
                                : <SimpleRetirementComponent size={10} />
                        }
                    </div>
                ))
            }
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Фон" color={backgroundState} onSave={setBackgroundState} />
                <ColorPickerButtonComponent text="Иконка" color={colorState} onSave={setColorState} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};
