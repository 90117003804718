import TemplateConfig from "../../../../config/TemplateConfig";

const FontSizeIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.2943 10.1399L28.8802 13.554C28.477 13.9571 27.8173 13.9571 27.4141 13.554L24 10.1399" stroke={color} stroke-width="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.0098 18.1201L20.2806 21.0013L22.5514 18.1201" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.2798 20.0046V4.01001" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.5475 5.55083L20.2767 3.28003L18.0059 5.55083" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.66992 7.17003V5.35003C1.66992 4.20003 2.59992 3.28003 3.73992 3.28003H16.5" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 20.7201V4.11011" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.06006 21H14.9401" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default FontSizeIcon;