import LogoutIcon from '../../../core/assets/module/configuration/logout.svg';
import MarketIcon from '../../../core/assets/module/configuration/market.svg';
import ProfileIcon from '../../../core/assets/module/configuration/profile.svg';
import TarifIcon from '../../../core/assets/module/configuration/tarif.svg';
import RouteNamespaces from '../../../core/route/RouteNamespaces';
import SharedListComponent from '../../shared/ui/SharedListComponent';

function MenuBottomSheetComponent({ showConfirm, navigate }: { showConfirm: any; navigate: any }) {
    const items = [
        { label: "Профиль", icon: ProfileIcon, onClick: () => navigate(RouteNamespaces.cabinet) },
        { label: "Тарифы", icon: TarifIcon, onClick: () => navigate(RouteNamespaces.market) },
        { label: "Веб-сайты", icon: MarketIcon, onClick: () => navigate(RouteNamespaces.configuration) },
        { label: "Выйти", icon: LogoutIcon, onClick: () => showConfirm() }
    ];

    return (
        <SharedListComponent items={items} />
    );
};

export default MenuBottomSheetComponent;
