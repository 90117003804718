import { CircularProgress, styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LoaderStore from "../store/LoaderStore";

function LoadingModalComponent() {
    const { openState } = LoaderStore;

    const Loader = styled('div')({
        top: 0,
        left: 0,
        position: 'fixed',
        width: '100%',
        height: '100%',
        zIndex: '2000',
        backgroundColor: TemplateConfig.greyColorWithOpacity,
        display: openState ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'opacity 0.3s, visibility 0.3s',
    });

    return (
        <Loader><CircularProgress /></Loader>
    )
}

export default observer(LoadingModalComponent);