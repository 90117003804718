import { SocialAttributeDao } from "../model/SocialAttributeDao";
import { styled } from "@mui/material";
import ClickableAttributeComponent from "./ClickableAttributeComponent";
import InsertClickAnalyticsFieldService from "../service/InsertClickAnalyticsFieldService";
import TelephoneIcon from "../../../core/assets/module/leaf/TelephoneIcon";
import TelegramIcon from "../../../core/assets/module/leaf/TelegramIcon";
import WhatsappIcon from "../../../core/assets/module/leaf/WhatsappIcon";
import InstagramIcon from "../../../core/assets/module/leaf/InstagramIcon";
import FacebookIcon from "../../../core/assets/module/leaf/FacebookIcon";
import TiktokIcon from "../../../core/assets/module/leaf/TiktokIcon";

const IconWrapper = styled('div')({
    margin: '0 10px',
});

export default function SocialAttributeComponent({ item, pageId }: { item: SocialAttributeDao; pageId: string }) {
    async function execInsertClickAnalyticsField() {
        await InsertClickAnalyticsFieldService({ pageId: pageId });
    }

    const socialElements = [
        {
            value: item.telephone,
            url: `tel:${item.telephone}`,
            icon: <TelephoneIcon color={item.color} background={item.background} />
        },
        {
            value: item.telegram,
            url: `https://t.me/${item.telegram}`,
            icon: <TelegramIcon color={item.color} background={item.background} />
        },
        {
            value: item.whatsapp,
            url: `https://wa.me/${item.whatsapp}`,
            icon: <WhatsappIcon color={item.color} background={item.background} />
        },
        {
            value: item.insta,
            url: `https://instagram.com/${item.insta}`,
            icon: <InstagramIcon color={item.color} background={item.background} />
        },
        {
            value: item.facebook,
            url: item.facebook,
            icon: <FacebookIcon color={item.color} background={item.background} />
        },
        {
            value: item.tiktok,
            url: `https://tiktok.com/@${item.tiktok}`,
            icon: <TiktokIcon color={item.color} background={item.background} />
        }
    ];

    return (
        <div style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
            {
                socialElements.map((field, index) => (
                    field.value != null && field.value.length > 0
                        ? (
                            <IconWrapper key={index}>
                                <ClickableAttributeComponent anotherFunction={execInsertClickAnalyticsField} icon={false} url={field.url}>
                                    <div style={{ paddingBottom: window.innerWidth < 436 ? 15 : 0 }}>{field.icon}</div>
                                </ClickableAttributeComponent>
                            </IconWrapper>
                        )
                        : null
                ))
            }
        </div>
    );
}