import { useEffect, useState } from "react";
import RaisedButtonComponent from "../../../shared/ui/RaisedButtonComponent";
import AddIcon from '../../../../core/assets/module/customize/add.svg';
import TemplateConfig from "../../../../core/config/TemplateConfig";
import LinkWrapperComponent from "../../../shared/ui/LinkWrapperComponent";
import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
import AppointmentItemAddComponent from "./AppointmentItemAddComponent";
import MoreIcon from '../../../../core/assets/module/customize/more.svg';
import AppointmentEditMenuComponent from "./AppointmentEditMenuComponent";
import { AppointmentValueResponse, Item } from "../../model/AppointmentValueResponse";
import { PagesDataResponseImpl } from "../../../configuration/model/PagesDataResponse";
import AppointmentItemsUpdateService from "../../service/AppointmentItemsUpdateService";
import ConfirmBottomSheetComponent from "../../../shared/ui/ConfirmModalComponent";
import AppointmentLinksUpdateService from "../../service/AppointmentLinksUpdateService";
import SimpleRetirementComponent from "../../../shared/ui/SimpleRetirementComponent";
import { Card, CardContent } from "@mui/material";
import SimpleTextComponent from "../../../shared/ui/SimpleTextComponent";
import TickTrueIcon from '../../../../core/assets/module/customize/tick-circle-true.svg';
import TickFalseIcon from '../../../../core/assets/module/customize/tick-circle-false.svg';

const AppointmentItemListComponent = ({ open, onDismiss, reload, appointmentValue, activeServices, activeCategoryId, choosedPage, handleActiveCategoryIdChange }: { open: boolean; onDismiss: any; reload: any; appointmentValue: AppointmentValueResponse | null; activeServices: { id: string, value: string; }[]; choosedPage: PagesDataResponseImpl; activeCategoryId: string; handleActiveCategoryIdChange: any }) => {
    // reload
    const [reloadState, setReloadState] = useState(1);

    // items
    const items = [{ id: "itemAddItem", value: "+" }];
    if (appointmentValue) for (const item of appointmentValue.items) items.push({ id: item.id, value: item.name });
    const [activeIds, setActiveIds] = useState<string[]>([]);
    useEffect(() => {
        setActiveIds([]);
        const calculateArray = [];
        for (const service of activeServices) {
            calculateArray.push(service.id);
        }
        setActiveIds(calculateArray);
    }, [activeServices]);
    function handleActiveIdsChange(id: string) {
        const calculateArray = activeIds;
        if (!calculateArray.includes(id)) {
            calculateArray.push(id);
        } else {
            const foundItemIndex = calculateArray.indexOf(id);
            if (foundItemIndex !== -1) calculateArray.splice(foundItemIndex, 1);
        }
        setActiveIds(calculateArray);
        setReloadState(reloadState + 1);
    }

    // edit
    const [itemMenuState, setItemMenuState] = useState(false);
    const [editActiveId, setEditActiveId] = useState('');
    const [updateItem, setUpdateItem] = useState<Item | null>(null);
    const [updateItemIndex, setUpdateItemIndex] = useState<number | null>(null);
    function editHandle() {
        setItemMenuState(false);
        if (!appointmentValue) return;
        const foundItem = appointmentValue.items.find((item) => item.id === editActiveId);
        if (!foundItem) return;
        const foundItemIndex = appointmentValue.items.indexOf(foundItem);
        if (foundItemIndex === -1) return;
        setUpdateItem(foundItem);
        setUpdateItemIndex(foundItemIndex);
        setReloadState(reloadState + 1);
        setItemAddState(true);
    }
    const [deleteWarningState, setDeleteWarningState] = useState(false);
    function deleteHandle() {
        setItemMenuState(false);
        setDeleteWarningState(false);
        if (!appointmentValue) return;
        const foundItem = appointmentValue.items.find((item) => item.id === editActiveId);
        if (!foundItem) return;
        AppointmentItemsUpdateService({
            handleActiveIdsChange: handleActiveIdsChange,
            activeCategoryId: activeCategoryId,
            reload: reload,
            action: 'delete',
            choosedPage: choosedPage,
            item: foundItem,
            images: []
        });
        const calculateArray = activeIds.filter(item => item !== editActiveId);
        setActiveIds(calculateArray);
    }

    // item add
    const [itemAddState, setItemAddState] = useState(false);

    return (
        <StandartBottomSheetComponent title="Услуги" open={open} onDismiss={onDismiss} onSubmit={() => {
            AppointmentLinksUpdateService({
                action: 'update',
                choosedPage: choosedPage,
                reload: reload,
                appointmentValue: appointmentValue,
                categoryId: activeCategoryId,
                updateServices: activeIds
            })
            handleActiveCategoryIdChange(activeCategoryId);
            onDismiss();
        }}>
            {
                items.map((field, index) => (
                    <div key={index}>
                        {
                            index === 0
                                ? <RaisedButtonComponent
                                    placeholder='Новая услуга'
                                    onClick={() => {
                                        setUpdateItem(null);
                                        setUpdateItemIndex(null);
                                        setReloadState(reloadState + 1);
                                        setItemAddState(true);
                                    }}
                                    icon={AddIcon}
                                />
                                : <Card variant="outlined" style={{ borderRadius: TemplateConfig.borderRadius, borderColor: activeIds.includes(field.id) ? TemplateConfig.purpleColor : TemplateConfig.greyBorderColor }}>
                                    <CardContent style={{ padding: '8.7px 16px 8.7px 16px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
                                            <div style={{ flex: 1 }}>
                                                <LinkWrapperComponent onClick={() => handleActiveIdsChange(field.id)}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={
                                                            activeIds.includes(field.id)
                                                                ? TickTrueIcon
                                                                : TickFalseIcon
                                                        } alt="service tick icon" />
                                                        <SimpleRetirementComponent size={16} />
                                                        <SimpleTextComponent type='title'>
                                                            {field.value}
                                                        </SimpleTextComponent>
                                                    </div>
                                                </LinkWrapperComponent>
                                            </div>
                                            <LinkWrapperComponent onClick={() => {
                                                setEditActiveId(field.id);
                                                setItemMenuState(true);
                                            }}>
                                                <img draggable={false} src={MoreIcon} alt="more icon" />
                                            </LinkWrapperComponent>
                                        </div>
                                    </CardContent>
                                </Card>
                        }
                        {
                            index === items.length - 1
                                ? null
                                : <SimpleRetirementComponent size={10} />
                        }
                    </div>
                ))
            }
            <AppointmentItemAddComponent handleActiveIdsChange={handleActiveIdsChange} activeCategoryId={activeCategoryId} state={reloadState} updateItem={updateItem} updateItemIndex={updateItemIndex} choosedPage={choosedPage} reload={reload} appointmentValue={appointmentValue} open={itemAddState} onDismiss={() => setItemAddState(false)} />
            <AppointmentEditMenuComponent open={itemMenuState} onDismiss={() => setItemMenuState(false)} onEdit={editHandle} onDelete={() => setDeleteWarningState(true)} />
            <ConfirmBottomSheetComponent open={deleteWarningState} onDismiss={() => setDeleteWarningState(false)} onSubmit={deleteHandle} title="Удалить услугу" description="Вы собираетесь удалить выбранную услугу." />
        </StandartBottomSheetComponent>
    );
}

export default AppointmentItemListComponent;