import ApplicationConfig from "../../../core/config/ApplicationConfig";
import SharedListComponent from "../../shared/ui/SharedListComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import LinkIcon from '../../../core/assets/module/configuration/link.svg';

const DomainComponent = ({ open, onDismiss, onSave }: { open: boolean; onDismiss: any; onSave: any }) => {

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Домен">
            <SharedListComponent items={
                ApplicationConfig.domains.map((item) => {
                    return {
                        label: item, onClick: () => {
                            onSave(item);
                            onDismiss();
                        },
                        icon: LinkIcon
                    }
                })
            } />
        </StandartBottomSheetComponent>
    );
}

export default DomainComponent;