export default class RouteNamespaces {
    static authenticate: string = '/';
    static cabinet: string = '/cabinet';
    static market: string = '/market';
    static configuration: string = '/configuration';
    static customizeWithoutNickname: string = '/customize';
    static customize: string = '/customize/:nickname';
    static leaf: string = ':nickname';
    static error: string = '/error';
    static another: string = '*';
}