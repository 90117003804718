import { memo } from "react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

const SwipeableComponent = memo(({ children }: { children: ReactNode }) => {
    //init
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    useEffect(() => {
        if (containerRef.current) {
            const width = containerRef.current.scrollWidth - containerRef.current.clientWidth;
            setContainerWidth(Math.max(0, width));
        }
    }, [children]);

    //swipeable
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleSwipe = (deltaX: number) => {
        if (containerRef.current) containerRef.current.scrollLeft = scrollPosition + -deltaX;
    };
    const handleSwipeEnd = (deltaX: number) => {
        const newPosition = scrollPosition + -deltaX;
        setScrollPosition(Math.max(0, Math.min(containerWidth, newPosition)));
    }
    const handlers = useSwipeable({
        onSwiping: (eventData) => {
            handleSwipe(eventData.deltaX);
            setIsScrolling(true);
        },
        onSwiped: (eventData) => {
            handleSwipeEnd(eventData.deltaX);
            setIsScrolling(false);
        },
        trackMouse: true
    });

    //widget
    return (
        <div {...handlers}>
            <div style={{ display: 'flex', overflow: 'hidden', userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', pointerEvents: isScrolling ? "none" : "auto" }} ref={containerRef}>
                {children}
            </div>
        </div>
    );
});

export default SwipeableComponent;