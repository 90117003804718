import { ImageAttributeDao } from "../model/ImageAttributeDao";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import TemplateConfig from "../../../core/config/TemplateConfig";
import Flickity from 'react-flickity-component'
import ClickableAttributeComponent from "./ClickableAttributeComponent";

export default function SliderAttributeComponent({ item, pageId, attributeId }: { item: ImageAttributeDao; pageId: string; attributeId: string }) {
    return (
        <Flickity static options={{ "draggable": true, "freeScroll": false, "imagesLoaded": false, "prevNextButtons": false, "friction": 0.8, "selectedAttraction": 0.3, "adaptiveHeight": true }}>
            {
                item.images.map((field, index) => (
                    <div
                        key={index}
                        style={{
                            width: '100%',
                            maxWidth: '100vw',
                            marginRight: '20px',
                        }}
                    >
                        <ClickableAttributeComponent url={field.url} key={index}>
                            <img
                                src={`${ApplicationConfig.thisProductBackendImagesPath}/${pageId}/${attributeId}/${field.image}`}
                                alt="Изображение"
                                style={{
                                    width: '100%',
                                    borderRadius: item.radius ? Number(item.radius) : TemplateConfig.borderRadius,
                                }}
                            />
                        </ClickableAttributeComponent>
                    </div>
                ))
            }
        </Flickity>

    );
}