import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import { Attribute } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function UpdateAudioAttributeService({ pageId, item, attributeValue, audio, attributePadding }: { pageId: string; item: Attribute; attributeValue: string; audio: File | null; attributePadding: string }) {
    // get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // create form
    const formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('pageId', pageId);
    formData.append('attributeValue', attributeValue);
    formData.append('attributeType', item.type);
    formData.append('attributePadding', attributePadding);
    formData.append('attributeId', item.id);
    if (audio) formData.append('audio', audio, audio.name);

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendAttributeUpdatePath,
            type: "post",
            formData: formData
        }
    );
    if (!result) return;

    // store
    pagesDataStore.updateAttribute(
        {
            id: item.id,
            mark: item.mark,
            type: item.type,
            padding: attributePadding,
            value: result.data.attributeValue
        },
        pageId
    );

    // notify
    toast("Блок успешно обновлен", { type: "success" });
}