import { useEffect, useState } from "react";
import TelephoneFormateUtil from "../../../core/utils/TelephoneFormateUtil";
import StandartTextField from "./StandartTextField";

const StandartTextFieldWrapper = ({ placeholder, rows = '1', maxLength = 50, endAdornment, startAdornment, handleTextFieldValueChange, filter = ``, mobileFilter = false, initText, multiline, password }: { placeholder: string; rows?: string; maxLength?: number; endAdornment?: any; startAdornment?: any; handleTextFieldValueChange: any; filter?: any; mobileFilter?: boolean; initText?: string | null; multiline?: boolean; password?: boolean }) => {
    useEffect(() => {
        if (initText) setText(initText);
    }, [initText]);

    const [text, setText] = useState('');
    function onTextChange(value: string) {
        const formattedValue = mobileFilter ? TelephoneFormateUtil({ value: value }) : value.replace(filter, '');
        setText(formattedValue);
        handleTextFieldValueChange(formattedValue);
    }
    return (
        <StandartTextField password={password} placeholder={placeholder} multiline={multiline} rows={rows} maxLength={maxLength} endAdornment={endAdornment} startAdornment={startAdornment} text={text} onChange={(event: any) => event ? onTextChange(event.target.value) : null} />
    );
}

export default StandartTextFieldWrapper;