import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { PagesDataResponseImpl } from "../model/PagesDataResponse";
import pagesDataStore from "../store/PagesDataStore";

export default async function PageTransferService({ page, recipientEmail }: { page: PagesDataResponseImpl; recipientEmail: string }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return false;

    const params = {
        "type": "page",
        "subType": "transferPage",
        "accessToken": accessToken,
        "pageId": page.page_id,
        "recipientEmail": recipientEmail
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: "Проверьте эл. почту или тариф получателя"
        }
    );
    if (!result) return false;

    // success
    pagesDataStore.deletePage(page);
    toast("Веб-сайт успешно передан", { type: "success" });
    return true;
}