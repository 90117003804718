import { action, makeObservable, observable } from 'mobx';
import { CustomerDataResponse } from '../model/CustomerDataResponse';

class CustomerDataStore {
    customerData: CustomerDataResponse | null = null;

    constructor() {
        makeObservable(this, {
            customerData: observable,
            saveCustomer: action,
            removeCustomer: action,
            updateCustomer: action
        });
    }

    saveCustomer(value: CustomerDataResponse) {
        this.customerData = value;
    }

    updateCustomer(forename: string, surname: string) {
        this.customerData!.customer.forename = forename;
        this.customerData!.customer.surname = surname;
    }

    removeCustomer() {
        this.customerData = null;
    }
}

const customerDataStore = new CustomerDataStore();
export default customerDataStore;
