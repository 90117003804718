import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import ConfigurationWrapperComponent from "../../configuration/ui/ConfigurationWrapperComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { SharedCardItemModel } from "../model/SharedCardItemModel";
import GetProductDataService from "../service/GetProductDataService";
import productDataStore from "../store/ProductDataStore";
import ProductItemDescriptionComponent from "./ProductItemDescriptionComponent";
import SharedCardItemComponent from "./SharedCardItemComponent";
import { ru } from 'date-fns/locale';
import { format } from "date-fns";
import CalculateDelay from "../../../core/utils/CalculateDelay";
import { Product } from "../model/ProductResponse";
import PurchaseComponent from "./PurchaseComponent";
import productPurchasedStore from "../../purchase/store/ProductPurchasedStore";
// icons
import TarifIcon from '../../../core/assets/module/configuration/tarif.svg';
import MarketIcon from '../../../core/assets/module/configuration/market.svg';
import TaskIcon from '../../../core/assets/module/configuration/task.svg';
import CardsIcon from '../../../core/assets/module/configuration/cards.svg';
import TimerIcon from '../../../core/assets/module/customize/timer.svg';


const MarketComponent = () => {
  //init market
  const [, setMarketLoaded] = useState(false);
  useEffect(() => {
    async function loadProduct() {
      if (!productDataStore.productData) await GetProductDataService();
      setMarketLoaded(true);
    }

    loadProduct();
  }, []);

  //description
  const [descriptionOpenState, setDescriptionOpenState] = useState(false);
  const [descriptionState, setDescriptionState] = useState('');
  function handleOnDescription(value: string) {
    setDescriptionState(value);
    setDescriptionOpenState(true);
  }

  // purchase
  const [activeProduct, setActiveProduct] = useState<Product | undefined>(undefined);
  const [purchaseWindow, setPurchaseWindow] = useState(false);

  if (!productDataStore.productData) return null;
  if (!productPurchasedStore.purchasedData) return null;

  function getCurrentTarif() {
    const prem = productPurchasedStore.purchasedData?.expiredAt.find(item => item.order === 'VWEB_VISITE_TARIF');
    const pro = productPurchasedStore.purchasedData?.expiredAt.find(item => item.order === 'VWEB_PREM_TARIF');

    return {
      name: prem ? "Premium" : pro ? "Pro" : "Basic",
      count: productPurchasedStore.purchasedData?.pageLimit,
      expiredAt: prem ? format(new Date(prem.expired_at), "dd MMMM yyyy", { locale: ru }) : pro ? format(new Date(pro.expired_at), "dd MMMM yyyy", { locale: ru }) : "Неограничено"
    };
  }
  return (
    <ConfigurationWrapperComponent>
      <Helmet title={'Тарифы'} />
      <SimpleRetirementComponent size={30} />
      <div style={{ textAlign: 'center' }}>
        <SimpleTextComponent type="header">Тарифы</SimpleTextComponent>
      </div>
      <SimpleRetirementComponent size={30} />
      <SharedCardItemComponent title="Текущий тариф">
        {
          [
            { label: "Тариф", icon: TarifIcon, value: getCurrentTarif().name },
            { label: "Кол. сайтов", icon: MarketIcon, value: getCurrentTarif().count },
            { label: "Детали", icon: TaskIcon, value: "Показать", clickable: true, onClick: () => handleOnDescription(getCurrentTarif().name), last: true },
            // { label: "Срок до", icon: TimerIcon, value: getCurrentTarif().expiredAt },
            // { label: "Цена", icon: CardsIcon, value: PriceFormatUtil(getCurrentTarif().name === 'Basic' ? 0 : getCurrentTarif().name === 'Pro' ? 15000 : 36000), last: true },
          ] as SharedCardItemModel[]
        }
      </SharedCardItemComponent>
      {/* <SimpleRetirementComponent size={10} />
      {
        productDataStore.productData.products.map((field, index) => (
          <div key={index}>
            <SharedCardItemComponent
              title={field.title}
              buttonText={field.amount === '0' ? undefined : "Оплатить"}
              buttonAction={field.amount === '0' ? undefined : () => {
                setActiveProduct(field);
                setPurchaseWindow(true);
              }}
            >
              {
                [
                  { label: "Кол. сайтов", icon: MarketIcon, value: field.title === 'Тариф "Premium"' ? '3' : '1' },
                  { label: "Детали", icon: TaskIcon, value: "Подробнее", clickable: true, onClick: () => handleOnDescription(field.title.split('"')[1].split('"')[0]) },
                  { label: "Срок действия", icon: TimerIcon, value: CalculateDelay(field.delay) },
                  { label: "Цена", icon: CardsIcon, value: PriceFormatUtil(Number(field.amount)), last: true },
                ] as SharedCardItemModel[]
              }
            </SharedCardItemComponent>
            {index !== (productDataStore.productData!.products.length - 1) && <SimpleRetirementComponent size={10} />}
          </div>
        ))
      } */}
      <ProductItemDescriptionComponent open={descriptionOpenState} onDismiss={() => setDescriptionOpenState(false)} description={descriptionState} />
      <PurchaseComponent open={purchaseWindow} onDismiss={() => setPurchaseWindow(false)} product={activeProduct} />
    </ConfigurationWrapperComponent>
  );
}

export default MarketComponent;