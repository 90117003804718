import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { OrderItem } from "../model/OrdersDataResponse";
import ordersDataStore from "../store/OrdersDataStore";

export default async function UpdateOrderStatusService({ pageId, orderItem, status, reload }: { pageId: string; orderItem: OrderItem, status: string, reload: any }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "type": "page",
        "subType": "updateOrderStatus",
        "accessToken": accessToken,
        "pageId": pageId,
        "orderId": orderItem.id,
        "status": status
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result) return;

    // change store status
    const foundItem = ordersDataStore.ordersData.elements.find((item) => item.pageId === pageId);
    if (!foundItem) return;

    foundItem.orders.new = foundItem.orders.new.filter((item) => item.id !== orderItem.id);
    foundItem.orders["in process"] = foundItem.orders["in process"].filter((item) => item.id !== orderItem.id);
    foundItem.orders.error = foundItem.orders.error.filter((item) => item.id !== orderItem.id);
    foundItem.orders.success = foundItem.orders.success.filter((item) => item.id !== orderItem.id);

    if (status === '0') foundItem.orders.new.unshift(orderItem);
    if (status === '1') foundItem.orders.error.unshift(orderItem);
    if (status === '2') foundItem.orders.success.unshift(orderItem);
    if (status === '3') foundItem.orders["in process"].unshift(orderItem);

    ordersDataStore.replaceData(pageId, foundItem);
    reload();
    toast("Статус успешно изменен", { type: "success" });
}