import { Container, styled } from "@mui/material";
import { ToastContainer } from "react-toastify";
import TemplateConfig from "../../../core/config/TemplateConfig";
import 'react-toastify/dist/ReactToastify.css';
import './custom.css';
import './bottomSheet.css';
import './datePicker.css';
import './textEditor.css';
import './slider.css';
import './youtube.css';
import './colorPicker.css';
import LoadingModalComponent from "./LoadingModalComponent";
import { observer } from "mobx-react-lite";
import TickCircle from '../../../core/assets/module/shared/tick-circle.svg';
import InfoCircle from '../../../core/assets/module/shared/info-circle.svg';

function SimpleWrapperComponent({ child, completelyWrap = true, background = false }: { child: React.ReactNode; completelyWrap?: boolean; background?: boolean }) {
    const Wrapper = styled('div')({
        backgroundColor: background ? TemplateConfig.darkPurpleColor : TemplateConfig.webBackgroundColor,
        minHeight: 'var(--doc-height)',
        display: 'flex',
        width: '100%'
    });

    const ContainerCompletelyWrap = styled(Container)(({ theme }) => ({
        backgroundColor: TemplateConfig.whiteColor,
        width: TemplateConfig.mobileMaxWidth,
        borderRadius: TemplateConfig.simpleWrapperBorderRadius,
        // boxShadow: TemplateConfig.boxShadow,
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            marginTop: '0vh',
            marginBottom: '0vh',
        },
        marginTop: '5vh',
        marginBottom: '5vh',
    }));

    const MiniContainer = styled(Container)(({ theme }) => ({
        backgroundColor: TemplateConfig.whiteColor,
        width: TemplateConfig.mobileMaxWidth,
        borderRadius: TemplateConfig.simpleWrapperBorderRadius,
        boxShadow: TemplateConfig.boxShadow,
        heigth: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        padding: TemplateConfig.longPadding,
    }));

    const MiniContainerWrap = styled(Container)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }));

    return (
        <div>
            <Wrapper>
                {
                    completelyWrap ? (
                        <ContainerCompletelyWrap disableGutters>
                            {child}
                        </ContainerCompletelyWrap >
                    ) : (
                        <MiniContainerWrap>
                            <MiniContainer>
                                {child}
                            </MiniContainer>
                        </MiniContainerWrap>
                    )
                }
            </Wrapper >
            <ToastContainer
                style={{ zIndex: 1000, borderRadius: TemplateConfig.borderRadius }}
                position="bottom-right"
                autoClose={1500}
                hideProgressBar={false}
                closeOnClick={false}
                pauseOnHover={false}
                theme="light"
                newestOnTop={true}
                closeButton={false}
                icon={({ type }) => {
                    if (type === "success") return <img src={TickCircle} alt="tick icon" />;
                    else return <img src={InfoCircle} alt="info icon" />;
                }}
            />
            <LoadingModalComponent />
        </div>
    );
}

export default observer(SimpleWrapperComponent);