import ApplicationConfig from "../../../core/config/ApplicationConfig";
import { DividerAttributeDao } from "../model/DividerAttributeDao";

export default function DividerAttributeComponent({ item, attributeId, pageId }: { item: DividerAttributeDao; pageId: string; attributeId: string; }) {
    return (
        <div>
            {item.image
                ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ borderTop: `1px solid ${item.color}`, width: '100%' }}></div>
                    <img src={`${ApplicationConfig.thisProductBackendImagesPath}/${pageId}/${attributeId}/${item.image}`} alt="divider icon" width={20} style={{ paddingLeft: 20, paddingRight: 20 }} />
                    <div style={{ borderTop: `1px solid ${item.color}`, width: '100%' }}></div>
                </div>
                : <div style={{ borderTop: `1px solid ${item.color}` }}></div>
            }
        </div>
    );
}