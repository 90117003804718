import { Switch } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ApplicationConfig from '../../../../core/config/ApplicationConfig';
import { MenuAttributeDao } from '../../../leaf/model/MenuAttributeDao';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import SimpleTextComponent from '../../../shared/ui/SimpleTextComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import PaddingComponent from './PaddingComponent';

export default function MenuBlockBuildBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, updateValue, updatePadding }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; updateValue?: MenuAttributeDao; updatePadding?: PaddingDao; }) {
    // update value
    useEffect(() => {
        if (updateValue) {
            link1Change(updateValue.link1 ?? '');
            link2Change(updateValue.link2 ?? '');
            name1Change(updateValue.name1 ?? '');
            name2Change(updateValue.name2 ?? '');
            setLocale(updateValue.locale ?? false);
            kzChange(updateValue.kz ?? '');
            ruChange(updateValue.ru ?? '');
            enChange(updateValue.en ?? '');
            setColor(updateValue.color ?? '#000000');
        }
    }, [updateValue]);

    // fields
    const link1 = useRef('');
    const link1Change = (v: string) => { link1.current = v; };
    const name1 = useRef('');
    const name1Change = (v: string) => { name1.current = v; };
    const link2 = useRef('');
    const link2Change = (v: string) => { link2.current = v; };
    const name2 = useRef('');
    const name2Change = (v: string) => { name2.current = v; };
    const [locale, setLocale] = useState(false);
    const kz = useRef('');
    const kzChange = (v: string) => { kz.current = v; };
    const ru = useRef('');
    const ruChange = (v: string) => { ru.current = v; };
    const en = useRef('');
    const enChange = (v: string) => { en.current = v; };

    // color
    const [color, setColor] = useState('#000000');

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    // widget
    const InputBlock = (init: any, onChange: any, label: string, start?: boolean) => {
        return (
            <StandartTextFieldWrapper
                placeholder={label}
                handleTextFieldValueChange={onChange}
                initText={init}
                startAdornment={start ? <SimpleTextComponent type='lightTitle'>{ApplicationConfig.applicationLink}/</SimpleTextComponent> : undefined}
            />
        );
    }
    const SwitchBlock = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <SimpleTextComponent>Языковая панель </SimpleTextComponent>
                <Switch
                    color="secondary"
                    checked={locale}
                    onChange={(_event, checked) => setLocale(checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    style={{

                    }}
                />
            </div>
        );
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            saveDirectlyLoading('menu', JSON.stringify({
                "link1": link1.current,
                "link2": link2.current,
                "name1": name1.current,
                "name2": name2.current,
                "locale": locale,
                "kz": kz.current,
                "ru": ru.current,
                "en": en.current,
                "color": color
            }), paddingField.current);
        }} title={'Меню'}>
            {InputBlock(name1.current, name1Change, "Введите название №1", false)}
            <SimpleRetirementComponent size={10} />
            {InputBlock(link1.current, link1Change, "Введите ссылку №1", false)}
            <SimpleRetirementComponent size={10} />
            {InputBlock(name2.current, name2Change, "Введите название №2", false)}
            <SimpleRetirementComponent size={10} />
            {InputBlock(link2.current, link2Change, "Введите ссылку №2", false)}
            <SimpleRetirementComponent size={10} />
            {SwitchBlock()}
            <SimpleRetirementComponent size={10} />
            {InputBlock(kz.current, kzChange, "никнейм (KZ)", true)}
            <SimpleRetirementComponent size={10} />
            {InputBlock(ru.current, ruChange, "никнейм (RU)", true)}
            <SimpleRetirementComponent size={10} />
            {InputBlock(en.current, enChange, "никнейм (EN)", true)}
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Меню" color={color} onSave={setColor} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};
