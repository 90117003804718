import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../../shared/ui/StandartTextFieldWrapper";

const RadiusEditComponent = ({ open, onDismiss, onSave, init }: { open: boolean; onDismiss: any; onSave: any; init?: string }) => {
    // init
    useEffect(() => {
        if (open) {
            const newValuevalue = init ?? "16";
            onChangeValue(newValuevalue);
            setReload(!reload);
        }
        // eslint-disable-next-line 
    }, [init, open]);
    const [reload, setReload] = useState(false);
    const value = useRef('');
    const onChangeValue = (v: string) => { value.current = v; };

    // function
    function valueIsCorrect(value: string | undefined) {
        if (!value || value.length < 1 || Number(value) < 0 || Number(value) > 1000) return false;
        return true;
    }

    // widget
    const InputBlock = (init: string | undefined, onChange: any, title: string) => {
        return (
            <StandartTextFieldWrapper
                initText={init}
                handleTextFieldValueChange={onChange}
                placeholder={title}
                filter={/[^0-9]/g}
            />
        );
    }
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!valueIsCorrect(value.current)) {
                toast("Введите корректные значения", { type: "error" });
                return;
            }

            onSave(value.current);
            onChangeValue('');
            onDismiss();
        }} title="Изменить радиус">
            {InputBlock(value.current, onChangeValue, "Введите значение")}
        </StandartBottomSheetComponent>
    );
}

export default RadiusEditComponent;