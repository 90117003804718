import { useEffect, useState } from "react";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import ParseFileUtil from "../../../core/utils/ParseFileUtil";
import { PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import CustomMuiFileInputComponent from "../../shared/ui/CustomMuiFileInputComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const BackgroundImageComponent = ({ open, onDismiss, onSave, updateValue, choosedPage }: { open: boolean; onDismiss: any; onSave: any; updateValue?: string | null; choosedPage: PagesDataResponseImpl; }) => {
    // update value
    useEffect(() => {
        async function fetchData() {
            if (updateValue) {
                const imageLink = `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage.page_id}/background/${updateValue}`;
                setBackground(await ParseFileUtil(imageLink));
            }
            else setBackground(null);
        }

        fetchData();
    }, [updateValue, choosedPage])

    // init
    const [background, setBackground] = useState<File | null>(null);
    function saveBackground(file: File, inputKey: string) { setBackground(file); }

    return (
        <StandartBottomSheetComponent title="Изображение фона" open={open} onDismiss={onDismiss} onSubmit={() => {
            onSave(background);
            onDismiss();
        }}>
            <CustomMuiFileInputComponent
                value={background}
                placeholder="Загрузите картинку"
                onChange={saveBackground}
                inputKey={'1'}
                initValue={background ? background.name : ''}
            />
        </StandartBottomSheetComponent>
    );
}

export default BackgroundImageComponent;