import { useEffect, useState } from "react";
import { PaddingDao } from "../../../leaf/model/PaddingDao";
import LinkWrapperComponent from "../../../shared/ui/LinkWrapperComponent";
import SimpleTextComponent from "../../../shared/ui/SimpleTextComponent";
import PaddingEditComponent from "./PaddingEditComponent";
import PaddingIcon from '../../../../core/assets/module/attribute/padding.svg';

const PaddingComponent = ({ init, onSave }: { init?: PaddingDao; onSave: any }) => {
    // init
    useEffect(() => {
        onSave(JSON.stringify(init ?? {
            top: "8",
            bottom: "8",
            left: "24",
            right: "24"
        }));
        // eslint-disable-next-line 
    }, [init]);

    // edit
    const [inputWindow, setInputWindow] = useState(false);

    return (
        <div>
            <LinkWrapperComponent onClick={() => setInputWindow(true)}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={PaddingIcon} alt="padding icon" width={20} />
                    <SimpleTextComponent type='paragraph'>
                        Отступ
                    </SimpleTextComponent>
                </div>
            </LinkWrapperComponent>
            <PaddingEditComponent open={inputWindow} onDismiss={() => setInputWindow(false)} onSave={onSave} init={init} />
        </div>
    );
}

export default PaddingComponent;