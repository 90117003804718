import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { PagesDataResponseImpl } from '../../../configuration/model/PagesDataResponse';
import RaisedButtonComponent from '../../../shared/ui/RaisedButtonComponent';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import SwipeableComponent from '../../../shared/ui/SwipeableComponent';
import AddIcon from '../../../../core/assets/module/customize/add.svg';
import DeleteIcon from '../../../../core/assets/module/configuration/delete.svg';
import AddCircleIcon from '../../../../core/assets/module/customize/add-circle.svg';
import MoreIcon from '../../../../core/assets/module/customize/more.svg';
import LinkWrapperComponent from '../../../shared/ui/LinkWrapperComponent';
import { Card, CardContent } from '@mui/material';
import TemplateConfig from '../../../../core/config/TemplateConfig';
import SimpleTextComponent from '../../../shared/ui/SimpleTextComponent';
import AppointmentCategoryAddComponent from './AppointmentCategoryAddComponent';
import AppointmentItemListComponent from './AppointmentItemListComponent';
import AppointmentEditMenuComponent from './AppointmentEditMenuComponent';
import { AppointmentValueResponse, Category, Link } from '../../model/AppointmentValueResponse';
import AppointmentCategoryUpdateService from '../../service/AppointmentCategoryUpdateService';
import ConfirmBottomSheetComponent from '../../../shared/ui/ConfirmModalComponent';
import AppointmentLinksUpdateService from '../../service/AppointmentLinksUpdateService';
import pagesDataStore from '../../../configuration/store/PagesDataStore';
import { AppointmentAttributeDao } from '../../../leaf/model/AppointmentAttributeDao';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';

export default function AppointmentBlockBuilderBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, choosedPage, updateValue }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; choosedPage: PagesDataResponseImpl; updateValue?: AppointmentAttributeDao }) {
    // update value
    useEffect(() => {
        if (updateValue) {
            setColorState(updateValue.color ?? '#ffffff');
            setBackgroundState(updateValue.background ?? '#000000');
        } else {
            setColorState('#ffffff');
            setBackgroundState('#000000');
        }

        handleTextFieldValueChange(updateValue ? updateValue.placeholder : '');
        if (!pagesDataStore.pagesData) return;
        const foundPage = pagesDataStore.pagesData.find(page => page.page_id === choosedPage.page_id);
        if (!foundPage) return;
        const appointment = foundPage.activities.find((activity) => activity.type === 'appointment');
        if (appointment) setAppointmentValue(JSON.parse(appointment.value));
        if (appointment) setActiveCategoryId('');

    }, [updateValue, choosedPage, open]);

    // RELOAD
    function reload(newValue: string) {
        setAppointmentValue(JSON.parse(newValue) as AppointmentValueResponse);
    }

    // INIT APPOINTMENT
    const appointment = choosedPage.activities.find((activity) => activity.type === 'appointment');
    const [appointmentValue, setAppointmentValue] = useState<AppointmentValueResponse | null>(appointment ? JSON.parse(appointment.value) as AppointmentValueResponse : null);

    // BUTTON TEXT
    const textFieldRef = useRef('');
    const handleTextFieldValueChange = (newValue: string) => { textFieldRef.current = newValue; };

    // CATEGORIES
    const [reloadState, setReloadState] = useState(0);
    const categories = [{ id: "categoryAddItem", value: "+" }];
    const [activeCategoryId, setActiveCategoryId] = useState('');
    const [editActiveCategoryId, setEditActiveCategoryId] = useState('');
    const [categoryMenuState, setCategoryMenuState] = useState(false);
    const [updateItem, setUpdateItem] = useState<Category | null>(null);
    const [updateItemIndex, setUpdateItemIndex] = useState<number | null>(null);
    function editHandle() {
        setCategoryMenuState(false);
        if (!appointmentValue) return;
        const foundItem = appointmentValue.category.find((item) => item.id === editActiveCategoryId);
        if (!foundItem) return;
        const foundItemIndex = appointmentValue.category.indexOf(foundItem);
        if (foundItemIndex === -1) return;
        setUpdateItem(foundItem);
        setUpdateItemIndex(foundItemIndex);
        setReloadState(reloadState + 1);
        setCategoryAddState(true);
    }
    const [deleteWarningState, setDeleteWarningState] = useState(false);
    function deleteHandle() {
        setCategoryMenuState(false);
        setDeleteWarningState(false);
        if (!appointmentValue) return;
        const foundItem = appointmentValue.category.find((item) => item.id === editActiveCategoryId);
        if (!foundItem) return;
        AppointmentCategoryUpdateService({
            appointmentValue: appointmentValue,
            reload: reload,
            action: 'delete',
            choosedPage: choosedPage,
            category: foundItem,
        });
        setActiveCategoryId('');
        setServices([]);
    }
    // init load category
    if (appointmentValue) {
        appointmentValue.category.forEach((category: Category) => {
            categories.push({ id: category.id, value: category.name });
        });
    }
    function handleActiveCategoryIdChange(id: string) {
        if (!appointmentValue) return;
        const newServices: { id: string, value: string }[] = [];
        appointmentValue.links.forEach((link: Link) => {
            if (id === link.category) {
                const foundItem = appointmentValue!.items.find((item) => item.id === link.item);
                if (foundItem) {
                    newServices.push({ id: foundItem.id, value: foundItem.name });
                }
            }
        })
        setActiveCategoryId(id);
        setServices(newServices);
    }

    // SERVICES
    const [services, setServices] = useState<{ id: string, value: string }[]>([]);
    function deleteLinkHandle(serviceId: string) {
        if (!appointmentValue) return;
        const foundItem = appointmentValue?.links.find((item) => item.item === serviceId && activeCategoryId === item.category);
        if (!foundItem) return;
        AppointmentLinksUpdateService({
            action: 'delete',
            choosedPage: choosedPage,
            link: foundItem,
            reload: reload,
            appointmentValue: appointmentValue
        });
    }
    const ServiceBlock = () => {
        return (
            activeCategoryId === ''
                ? null
                : <div>
                    <SimpleRetirementComponent />
                    <Card variant="outlined" style={{ borderRadius: TemplateConfig.borderRadius, borderColor: TemplateConfig.greyBorderColor }}>
                        <CardContent style={{ padding: 16 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <SimpleTextComponent type='title'>
                                    Услуги
                                </SimpleTextComponent>
                                <LinkWrapperComponent onClick={() => setItemAddState(true)}>
                                    <img width="24px" src={AddCircleIcon} alt="add circle icon" />
                                </LinkWrapperComponent>
                            </div>
                            {
                                services.length === 0
                                    ? null
                                    : <div>
                                        <SimpleRetirementComponent size={10} />
                                        {
                                            services.map((field, index) => (
                                                <div key={index}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <SimpleTextComponent>
                                                            {field.value}
                                                        </SimpleTextComponent>
                                                        <LinkWrapperComponent onClick={() => { deleteLinkHandle(field.id); handleActiveCategoryIdChange(activeCategoryId); }}>
                                                            <img width="16px" src={DeleteIcon} alt="delete icon" />
                                                        </LinkWrapperComponent>
                                                    </div>
                                                    {
                                                        index === services.length - 1
                                                            ? null
                                                            : <SimpleRetirementComponent size={2.5} />
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                            }
                        </CardContent>
                    </Card>
                </div>
        );
    }

    // BOTTOM SHEETS
    const [categoryAddState, setCategoryAddState] = useState(false);
    const [itemAddState, setItemAddState] = useState(false);

    // color, background
    const [colorState, setColorState] = useState('#ffffff');
    const [backgroundState, setBackgroundState] = useState('#000000');

    // WIDGET
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!textFieldRef.current || textFieldRef.current.length === 0) {
                toast("Заполните поле", { type: "error" });
                return;
            }

            if (!appointmentValue || !appointmentValue.links || appointmentValue.links.length < 1) {
                toast("Добавьте категорию и услугу", { type: "error" });
                return;
            }

            saveDirectlyLoading('appointment', JSON.stringify({ "placeholder": textFieldRef.current, "color": colorState, "background": backgroundState }));
            handleTextFieldValueChange('');
            setColorState('#ffffff');
            setBackgroundState('#000000');
        }} title={'Запись'}>
            <SwipeableComponent>
                {
                    categories.map((field, index) => (
                        <div key={index} style={{ marginRight: 10, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                            <RaisedButtonComponent
                                backgroundColor={index === 0 || activeCategoryId === field.id ? TemplateConfig.purpleColor : TemplateConfig.greyBorderColor}
                                placeholder={field.value}
                                onClick={() => {
                                    if (index === 0) {
                                        setUpdateItem(null);
                                        setUpdateItemIndex(null);
                                        setReloadState(reloadState + 1);
                                        setCategoryAddState(true);
                                    } else {
                                        handleActiveCategoryIdChange(field.id);
                                    }
                                }}
                                icon={index === 0 ? AddIcon : null}
                                customWidth={index === 0 ? 48 : null}
                            />
                            {
                                index === 0
                                    ? null
                                    : <LinkWrapperComponent onClick={() => { setEditActiveCategoryId(field.id); setCategoryMenuState(true); }}>
                                        <img draggable={false} src={MoreIcon} alt="more icon" />
                                    </LinkWrapperComponent>
                            }
                        </div>
                    ))
                }
            </SwipeableComponent>
            <ServiceBlock />
            <SimpleRetirementComponent />
            <StandartTextFieldWrapper
                placeholder="Введите надпись на кнопке"
                handleTextFieldValueChange={handleTextFieldValueChange}
                initText={textFieldRef.current && textFieldRef.current.length > 0 ? textFieldRef.current : null}
            />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Фон" color={backgroundState} onSave={setBackgroundState} />
                <ColorPickerButtonComponent text="Текст" color={colorState} onSave={setColorState} />
            </div>
            <AppointmentCategoryAddComponent state={reloadState} updateItem={updateItem} updateItemIndex={updateItemIndex} appointmentValue={appointmentValue} reload={reload} open={categoryAddState} onDismiss={() => setCategoryAddState(false)} choosedPage={choosedPage} />
            <AppointmentEditMenuComponent open={categoryMenuState} onDismiss={() => setCategoryMenuState(false)} onEdit={editHandle} onDelete={() => setDeleteWarningState(true)} />
            <AppointmentItemListComponent handleActiveCategoryIdChange={handleActiveCategoryIdChange} activeCategoryId={activeCategoryId} choosedPage={choosedPage} activeServices={services} open={itemAddState} onDismiss={() => setItemAddState(false)} appointmentValue={appointmentValue} reload={reload} />
            <ConfirmBottomSheetComponent open={deleteWarningState} onDismiss={() => setDeleteWarningState(false)} onSubmit={deleteHandle} title="Удалить категорию" description="Вы собираетесь удалить выбранную категорию." />
        </StandartBottomSheetComponent>
    );
};
