import { useRef } from "react";
import { toast } from "react-toastify";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../shared/ui/StandartTextFieldWrapper";
import GetPromoDataService from "../service/GetPromoDataService";

const PromoCodeComponent = ({ open, onDismiss, setCalculatedPrice, setPromo, price }: { open: boolean; onDismiss: any; setCalculatedPrice: any; setPromo: any; price: string }) => {
    // init
    const field = useRef('');
    const onChange = (v: string) => { field.current = v };

    // function
    async function savePromo() {
        if (field.current.length < 1) {
            toast("Промокод не найден. Попробуйте другой", { type: "error" });
            return;
        }

        const promoCodeProcent = await GetPromoDataService(field.current);
        if (promoCodeProcent) {
            setPromo(field.current);
            setCalculatedPrice((Number(price) - (Number(price) * (Number(promoCodeProcent) / 100))).toString());
            toast("Промокод успешно применён", { type: "success" });
            onDismiss();
        }
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Применить промокод" onSubmit={savePromo}>
            <StandartTextFieldWrapper
                initText={field.current}
                placeholder="Введите промокод"
                handleTextFieldValueChange={onChange}
            />
        </StandartBottomSheetComponent>
    );
}

export default PromoCodeComponent;