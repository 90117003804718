import { styled } from "@mui/material";
import { Container } from "@mui/system";
import { Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import { PublicDataResponse } from "../model/PublicDataResponse";
import GetPublicDataService from "../service/GetPublicDataService";
import AttributeWrapperComponent from "./AttributeWrapperComponent";
import ButtonAttributeComponent from "./ButtonAttributeComponent";
import DividerAttributeComponent from "./DividerAttributeComponent";
import FormAttributeComponent from "./FormAttributeComponent";
import ImageAttributeComponent from "./ImageAttributeComponent";
import TextAttributeComponent from "./TextAttributeComponent";
import ProfileAttributeComponent from "./ProfileAttributeComponent";
import SocialAttributeComponent from "./SocialAttributeComponent";
import TimerAttributeComponent from "./TimerAttributeComponent";
import SliderAttributeComponent from "./SliderAttributeComponent";
import MapAttributeComponent from "./MapAttributeComponent";
import YoutubeAttributeComponent from "./YoutubeAttributeComponent";
import AppointmentAttributeComponent from "./AppointmentAttributeComponent";
import TemplateConfig from "../../../core/config/TemplateConfig";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import QuestionAttributeComponent from "./QuestionAttributeComponent";
import RowAttributeComponent from "./RowAttributeComponent";
import PricelistAttributeComponent from "./PricelistAttributeComponent";
import AudioAttributeComponent from "./AudioAttributeComponent";
import { Helmet } from "react-helmet-async";
import { PageSeoResponse } from "../../customize/model/PageSettingsResponse";
import MenuAttributeComponent from "./MenuAttributeComponent";
import VWEBIcon from "../../../core/assets/module/configuration/icon-vweb.svg";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import UrlLauncherUtil from "../../../core/utils/UrlLauncherUtil";

export default function LeafComponent() {
    //init
    const [items, setItems] = useState<null | PublicDataResponse>(null);
    let { nickname } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const result = await GetPublicDataService({ nickname: nickname });
                if (result === null || result.status === 'error') {
                    navigate(RouteNamespaces.error);
                } else {
                    setItems(result.data as PublicDataResponse);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                navigate(RouteNamespaces.error);
            }
        }

        if (nickname) {
            fetchData();
        }
    }, [nickname, navigate]);

    //style
    const CustomizeComponentContainerWrapper = styled(Container)(({ theme }) => ({
        // height: '90vh',
        borderRadius: TemplateConfig.simpleWrapperBorderRadius,
        [theme.breakpoints.down('sm')]: {
            // height: '100vh',
            borderRadius: 0,
        },
        display: 'grid',
        gridTemplateRows: '0.5px 1fr',
        height: '100%',
        backgroundColor: items && items.settings && JSON.parse(items.settings).color ? JSON.parse(items.settings).color : 'none',
        backgroundImage: items && items.settings && JSON.parse(items.settings).background ? `url(${ApplicationConfig.thisProductBackendImagesPath}${items.pageId}/background/${JSON.parse(items.settings).background})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }));
    const MiddleBlock = styled('div')({
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        position: 'absolute',
        width: '100%',
        height: '100%',

    });
    if (items === null || items?.attributes.length === 0) return null;
    const appointmentData = items?.activities.find((item) => item.type === 'appointment');
    return (
        <Suspense fallback={null}>
            <>
                {
                    items && items.settings && JSON.parse(items.settings).seo
                        ?
                        <Helmet
                            title={JSON.parse(items.settings).seo.title ?? ''}
                            meta={[
                                { name: "description", content: JSON.parse(items.settings).seo.description ?? '' },
                                { name: "keywords", content: JSON.parse(items.settings).seo.keywords ?? '' },
                                { name: "description", content: JSON.parse(items.settings).seo.description ?? '' },
                                { name: "keywords", content: JSON.parse(items.settings).seo.keywords ?? '' },
                                { property: "og:title", content: JSON.parse(items.settings).seo.title ?? '' },
                                { property: "og:description", content: JSON.parse(items.settings).seo.description ?? '' },
                                { property: "og:url", content: window.location.href },
                                { property: "og:type", content: "website" },
                                { property: "og:image", content: `${ApplicationConfig.thisProductBackendImagesPath}${items.pageId}/favicon/${(JSON.parse(items.settings).seo as PageSeoResponse).favicon}` }
                            ]}
                            link={[
                                {
                                    "rel": "icon",
                                    "type": "image/png",
                                    "href": `${ApplicationConfig.thisProductBackendImagesPath}${items.pageId}/favicon/${(JSON.parse(items.settings).seo as PageSeoResponse).favicon}`
                                }
                            ]}
                        />
                        : null
                }
                <CustomizeComponentContainerWrapper disableGutters>
                    <div style={{ visibility: 'hidden' }} />
                    <div style={{ position: 'relative' }}>
                        <MiddleBlock>
                            {
                                items.attributes.map((item, index) => (
                                    <AttributeWrapperComponent padding={item.padding ? JSON.parse(item.padding) : undefined} key={index}>
                                        {(() => {
                                            switch (item.type) {
                                                case 'text':
                                                    return <TextAttributeComponent item={JSON.parse(item.value!)} />;
                                                case 'divider':
                                                    return <DividerAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={items.pageId} />;
                                                case 'button':
                                                    return <ButtonAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={items.pageId} />;
                                                case 'image':
                                                    return <ImageAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={items.pageId} />;
                                                case 'profile':
                                                    return <ProfileAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={items.pageId} />;
                                                case 'social':
                                                    return <SocialAttributeComponent item={JSON.parse(item.value!)} pageId={items.pageId} />;
                                                case 'timer':
                                                    return <TimerAttributeComponent item={JSON.parse(item.value!)} />;
                                                case 'form':
                                                    return <FormAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} />;
                                                case 'slider':
                                                    return <SliderAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={items.pageId} />;
                                                case 'map':
                                                    return <MapAttributeComponent item={JSON.parse(item.value!)} />;
                                                case 'youtube':
                                                    return <YoutubeAttributeComponent item={JSON.parse(item.value!)} />;
                                                case 'appointment':
                                                    return <AppointmentAttributeComponent item={JSON.parse(item.value!)} pageId={items.pageId} appointmentData={appointmentData ?? null} />;
                                                case 'question':
                                                    return <QuestionAttributeComponent item={JSON.parse(item.value!)} />;
                                                case 'row':
                                                    return <RowAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={items.pageId} />;
                                                case 'pricelist':
                                                    return <PricelistAttributeComponent item={JSON.parse(item.value!)} />;
                                                case 'audio':
                                                    return <AudioAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={items.pageId} />;
                                                case 'banner':
                                                    return <ImageAttributeComponent item={JSON.parse(item.value!)} attributeId={item.id} pageId={items.pageId} />;
                                                case 'menu':
                                                    return <MenuAttributeComponent item={JSON.parse(item.value!)} />;
                                                default:
                                                    return <h3>{item.type}</h3>;
                                            }
                                        })()}
                                    </AttributeWrapperComponent>
                                ))
                            }
                            {items.watermark.toString() === '1' && <SimpleRetirementComponent size={56} />}
                            {
                                items.watermark.toString() === '1' && <>
                                    <div style={{
                                        backgroundColor: 'white',
                                        borderEndEndRadius: window.innerWidth > TemplateConfig.mobileMaxWidth ? 24 : 0,
                                        borderEndStartRadius: window.innerWidth > TemplateConfig.mobileMaxWidth ? 24 : 0,
                                        position: 'fixed',
                                        bottom: window.innerWidth > TemplateConfig.mobileMaxWidth ? '5vh' : 0,
                                        width: window.innerWidth > TemplateConfig.mobileMaxWidth ? 520 : window.innerWidth,
                                        zIndex: 5,
                                        padding: window.innerWidth > 960 ? '15px 0px' : '10px 0px'
                                    }}>
                                        <LinkWrapperComponent onClick={() => UrlLauncherUtil({ url: `${ApplicationConfig.applicationInfoLink}` })}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 24
                                            }}>
                                                <SimpleTextComponent type='lightTitle' stronglyInter>
                                                    Made on
                                                </SimpleTextComponent>
                                                <SimpleRetirementComponent size={5} />

                                                <img src={VWEBIcon} alt="vweb icon" width={24} style={{}} />
                                            </div>
                                        </LinkWrapperComponent>
                                    </div>
                                </>
                            }
                        </MiddleBlock>
                    </div>
                </CustomizeComponentContainerWrapper>
                <style>
                    {
                        `
                            body,
                            *:not(mark)
                            *:not(img)
                            *:not(.rhap_main *)  {
                                font-family: '${items.font}', sans-serif !important;
                            }
                        `
                    }
                </style>
            </>
        </Suspense>
    )
}
