import StandartBottomSheetComponent from "./StandartBottomSheetComponent";
import { BlockPicker, HuePicker } from 'react-color'
import SimpleRetirementComponent from "./SimpleRetirementComponent";
import { useState } from "react";

const ColorPickerComponent = ({ open, onDismiss, colorState, onSave }: { open: boolean; onDismiss: any; colorState: string; onSave: any }) => {
    // init
    const [localColor, setLocalColor] = useState(colorState);

    return (
        <StandartBottomSheetComponent title="Цвет" open={open} onDismiss={onDismiss} onSubmit={() => {
            onSave(localColor);
            onDismiss();
        }}>
            <BlockPicker
                color={localColor}
                onChange={(color) => color ? setLocalColor(color.hex) : null}
                triangle='hide'
                colors={['#eb3b5a', '#fc5c65', '#fa8231', '#fd9644', '#f7b731', '#fed330', '#20bf6b', '#26de81', '#0fb9b1', '#2bcbba', '#2d98da', '#45aaf2', '#3867d6', '#4b7bec', '#8854d0', '#a55eea', '#a5b1c2', '#d1d8e0', '#000000', '#333333', '#6f6f6f', '#d2d2d2', '#ffffff']}
            />
            <SimpleRetirementComponent size={10} />
            <HuePicker
                color={localColor}
                onChange={(color) => color ? setLocalColor(color.hex) : null}
            />
        </StandartBottomSheetComponent>
    );
}

export default ColorPickerComponent;