import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import { PlaceholderAttributeDao } from '../../../leaf/model/PlaceholderAttributeDao';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import { Editor, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import Color from '@tiptap/extension-color'
// import FontFamily from '@tiptap/extension-font-family'
import ListKeymap from '@tiptap/extension-list-keymap'
import FontSize from 'tiptap-extension-font-size'
import TemplateConfig from '../../../../core/config/TemplateConfig';
import LinkWrapperComponent from '../../../shared/ui/LinkWrapperComponent';
import TextBlockListElementsComponent from './TextBlockListElementsComponent';
import ColorPickerComponent from '../../../shared/ui/ColorPickerComponent';
// icons
// import FontFamilyIcon from '../../../../core/assets/module/customize/editor/FontFamilyIcon';
// import OrderedListIcon from '../../../../core/assets/module/customize/editor/OrderedListIcon';
// import BulletListIcon from '../../../../core/assets/module/customize/editor/BulletListIcon';
import TextAlignJustifyIcon from '../../../../core/assets/module/customize/editor/TextAlignJustifyIcon';
import TextAlignRightIcon from '../../../../core/assets/module/customize/editor/TextAlignRightIcon';
import TextAlignCenterIcon from '../../../../core/assets/module/customize/editor/TextAlignCenterIcon';
import TextAlignLeftIcon from '../../../../core/assets/module/customize/editor/TextAlignLeftIcon';
import BackgroundIcon from '../../../../core/assets/module/customize/editor/BackgroundIcon';
import RemoveBackgroundIcon from '../../../../core/assets/module/customize/editor/RemoveBackgroundIcon';
import ColorIcon from '../../../../core/assets/module/customize/editor/ColorIcon';
import UnderlineIcon from '../../../../core/assets/module/customize/editor/UnderlineIcon';
import ItalicIcon from '../../../../core/assets/module/customize/editor/ItalicIcon';
import BoldIcon from '../../../../core/assets/module/customize/editor/BoldIcon';
import FontSizeIcon from '../../../../core/assets/module/customize/editor/FontSizeIcon';
import PaddingComponent from './PaddingComponent';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import { Grid } from '@mui/material';

export default function TextBlockBuildBottomSheetComponent({ open, onDismiss, saveDirectlyLoading, updateValue, updatePadding, font }: { open: boolean; onDismiss: any; saveDirectlyLoading: any; updateValue?: PlaceholderAttributeDao, updatePadding?: PaddingDao; font: string }) {
    // set update
    useEffect(() => {
        if (updateValue) {
            const value = decodeURIComponent(escape(atob(updateValue.placeholder)));
            if (editor) editor.commands.setContent(value);
            setHtml(value);
        }
        else setHtml('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, updateValue]);

    // editor
    const [html, setHtml] = useState('');

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    // TIPTAP
    const editor = useEditor({
        autofocus: false,
        extensions: [
            StarterKit,
            Underline,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            TextStyle,
            FontSize,
            Highlight.configure({
                multicolor: true,
            }),
            Color,
            ListKeymap
        ],
        content: html,
        onUpdate({ editor }) { setHtml(editor.getHTML()); }
    });

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={() => {
            if (!html || html.length === 0) {
                toast("Заполните поле", { type: "error" });
                return;
            }
            saveDirectlyLoading(
                'text',
                JSON.stringify({ "placeholder": btoa(unescape(encodeURIComponent(html.toString()))) }),
                paddingField.current
            );
            setHtml('');
        }} title={'Текст'}>
            <EditorContent editor={editor} />
            <SimpleRetirementComponent size={10} />
            <Toolbar editor={editor} font={font} />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};

const Toolbar = ({ editor, font }: { editor: Editor | null; font: string }) => {
    // font size
    const [fontSizeState, setFontSizeState] = useState(false);
    function handleFontSizeSave(size: string) {
        editor!.commands.setFontSize(size);
        setFontSizeState(false);
    }

    // font family
    // const [fontFamilyState, setFontFamilyState] = useState(false);
    // function handleFontFamilySave(family: string) {
    //     editor!.commands.setFontFamily(family);
    //     setFontFamilyState(false);
    // }

    // color
    const [colorState, setColorState] = useState(false);
    function handleColorSave(color: string) {
        editor!.commands.setColor(color);
        setColorState(false);
    }

    // highlight
    const [highlightState, setHighlightState] = useState(false);
    function handleHighlightSave(highlight: string) {
        editor!.commands.toggleHighlight({ color: highlight });
        setHighlightState(false);
    }

    // condition
    if (!editor) return null;

    // elements
    const elements = [
        // {
        //     icon: <FontFamilyIcon color={TemplateConfig.blackColor} />,
        //     onClick: () => setFontFamilyState(true)
        // },
        {
            icon: <FontSizeIcon color={TemplateConfig.blackColor} />,
            onClick: () => setFontSizeState(true)
        },
        {
            icon: editor.isActive('bold')
                ? <BoldIcon color={TemplateConfig.purpleColor} />
                : <BoldIcon color={TemplateConfig.blackColor} />,
            onClick: () => editor.commands.toggleBold()
        },
        {
            icon: editor.isActive('italic')
                ? <ItalicIcon color={TemplateConfig.purpleColor} />
                : <ItalicIcon color={TemplateConfig.blackColor} />,
            onClick: () => editor.commands.toggleItalic()
        },
        {
            icon: editor.isActive('underline')
                ? <UnderlineIcon color={TemplateConfig.purpleColor} />
                : <UnderlineIcon color={TemplateConfig.blackColor} />,
            onClick: () => editor.commands.toggleUnderline()
        },
        {
            icon: <ColorIcon color={TemplateConfig.blackColor} />,
            onClick: () => setColorState(true)
        },
        {
            icon: <BackgroundIcon color={TemplateConfig.blackColor} />,
            onClick: () => setHighlightState(true)
        },
        {
            icon: <RemoveBackgroundIcon color={TemplateConfig.blackColor} />,
            onClick: () => editor.commands.unsetHighlight()
        },
        {
            icon: editor.isActive({ textAlign: 'left' })
                ? <TextAlignLeftIcon color={TemplateConfig.purpleColor} />
                : <TextAlignLeftIcon color={TemplateConfig.blackColor} />,
            onClick: () => editor.commands.setTextAlign('left')
        },
        {
            icon: editor.isActive({ textAlign: 'center' })
                ? <TextAlignCenterIcon color={TemplateConfig.purpleColor} />
                : <TextAlignCenterIcon color={TemplateConfig.blackColor} />,
            onClick: () => editor.commands.setTextAlign('center')
        },
        {
            icon: editor.isActive({ textAlign: 'right' })
                ? <TextAlignRightIcon color={TemplateConfig.purpleColor} />
                : <TextAlignRightIcon color={TemplateConfig.blackColor} />,
            onClick: () => editor.commands.setTextAlign('right')
        },
        {
            icon: editor.isActive({ textAlign: 'justify' })
                ? <TextAlignJustifyIcon color={TemplateConfig.purpleColor} />
                : <TextAlignJustifyIcon color={TemplateConfig.blackColor} />,
            onClick: () => editor.commands.setTextAlign('justify')
        },
        // {
        //     icon: editor.isActive('bulletList')
        //         ? <BulletListIcon color={TemplateConfig.purpleColor} />
        //         : <BulletListIcon color={TemplateConfig.blackColor} />,
        //     onClick: () => editor.commands.toggleBulletList()
        // },
        // {
        //     icon: editor.isActive('orderedList')
        //         ? <OrderedListIcon color={TemplateConfig.purpleColor} />
        //         : <OrderedListIcon color={TemplateConfig.blackColor} />,
        //     onClick: () => editor.commands.toggleOrderedList()
        // }
    ];

    return (
        <div>
            <Grid container rowSpacing={1}>
                {
                    elements.map((field, index) => (
                        <Grid item key={index}>
                            <div style={{ paddingRight: 15 }}>
                                <LinkWrapperComponent onClick={field.onClick} >
                                    {field.icon}
                                </LinkWrapperComponent>
                            </div>
                        </Grid>
                    ))
                }
            </Grid>
            <TextBlockListElementsComponent
                title='Размер'
                items={['14pt', '17pt', '20pt', '24pt', '28pt', '34px']}
                activeItem={editor.getAttributes('textStyle').fontSize}
                open={fontSizeState}
                onDismiss={() => setFontSizeState(false)}
                onSave={handleFontSizeSave}
            />
            {/* <TextBlockListElementsComponent
                title='Шрифт'
                items={ApplicationConfig.fonts}
                activeItem={editor.getAttributes('textStyle').fontFamily}
                open={fontFamilyState}
                onDismiss={() => setFontFamilyState(false)}
                onSave={handleFontFamilySave}
            /> */}
            <ColorPickerComponent
                open={colorState}
                onDismiss={() => setColorState(false)}
                colorState={editor.getAttributes('textStyle').color ?? TemplateConfig.blackColor}
                onSave={handleColorSave}
            />
            <ColorPickerComponent
                open={highlightState}
                onDismiss={() => setHighlightState(false)}
                colorState={editor.getAttributes('textStyle').highlight ?? TemplateConfig.whiteColor}
                onSave={handleHighlightSave}
            />
            <style>
                {
                    `
                        .tiptap * {
                            font-family: ${font}, sana-serif !important;
                        }
                    `
                }
            </style>
        </div>
    );
}
