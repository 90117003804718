import ApplicationConfig from "../../../core/config/ApplicationConfig";
import SharedListComponent from "../../shared/ui/SharedListComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const FontComponent = ({ open, onDismiss, onSave }: { open: boolean; onDismiss: any; onSave: any }) => {

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Шрифт">
            <SharedListComponent items={
                ApplicationConfig.fonts.map((item) => {
                    return {
                        label: item, onClick: () => {
                            onSave(item);
                            onDismiss();
                        }
                    }
                })
            } />
        </StandartBottomSheetComponent>
    );
}

export default FontComponent;