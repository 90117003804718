import LinkWrapperComponent from "./LinkWrapperComponent";
import SimpleTextComponent from "./SimpleTextComponent";

const SimpleTextButtonComponent = ({ placeholder, onClick, type = "default" }: { placeholder: string; onClick: any; type?: string }) => {
    return (
        <SimpleTextComponent type={type}>
            <LinkWrapperComponent onClick={onClick}>
                <span style={{ textDecoration: 'underline', textDecorationThickness: 1, textUnderlineOffset: 3 }}>{placeholder}</span>
            </LinkWrapperComponent>
        </SimpleTextComponent>
    )
}

export default SimpleTextButtonComponent;