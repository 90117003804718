import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../shared/ui/StandartTextFieldWrapper";

const AppointmentContactOptionComponent = ({ open, onDismiss, onSubmit, onName, onTelephone }: { open: boolean; onDismiss: any; onSubmit: any; onName: any; onTelephone: any }) => {
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={onSubmit} title="Контактные данные">
            <StandartTextFieldWrapper
                placeholder="Имя"
                handleTextFieldValueChange={onName}
                maxLength={100}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Телефон"
                handleTextFieldValueChange={onTelephone}
                mobileFilter
            />
        </StandartBottomSheetComponent>
    );
}

export default AppointmentContactOptionComponent;