import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../shared/ui/StandartTextFieldWrapper";

const PasswordComponent = ({ open, onDismiss, onSubmit, savePassword, saveRePassword }: { open: boolean; onDismiss: any; onSubmit: any; savePassword: any; saveRePassword: any }) => {
    return (
        <StandartBottomSheetComponent open={open} onSubmit={onSubmit} onDismiss={onDismiss} title="Изменить пароль">
            <StandartTextFieldWrapper
                placeholder="Введите новый пароль"
                handleTextFieldValueChange={savePassword}
                password
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                placeholder="Повторите новый пароль"
                handleTextFieldValueChange={saveRePassword}
                password
            />
        </StandartBottomSheetComponent>
    );
}

export default PasswordComponent;