import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import { Attribute } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { DirectlyAttributeResponse } from "../model/DirectlyAttributeResponse";

export default async function InsertDirectlyLoadingBlockService({ pageId, attributeType, attributeValue, attributePadding }: { pageId: string; attributeType: string; attributeValue: string; attributePadding: string }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    //create form
    const formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('pageId', pageId);
    formData.append('attributeType', attributeType);
    formData.append('attributeValue', attributeValue);
    formData.append('attributePadding', attributePadding);

    //request
    var result = await GlobalRequestTemplate(
        {
            type: "post",
            link: ApplicationConfig.thisProductBackendAttributeCreatePath,
            formData: formData,
        }
    );

    //if error
    if (result === null) return;

    //notify
    toast("Блок успешно добавлен", {
        type: "success"
    });

    //store
    pagesDataStore.insertAttribute({
        id: (result.data as DirectlyAttributeResponse).attributeId,
        mark: (result.data as DirectlyAttributeResponse).mark,
        type: attributeType,
        padding: attributePadding,
        value: attributeValue,
    } as Attribute, pageId);
}