import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import AccessTokenVerifyService from "./AccessTokenVerifyService";
export default async function LogoutRequestService() {
    //check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    //create params
    const params = {
        "type": "account",
        "subType": "logout",
        "accessToken": accessToken
    };

    //request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    return result;
}