import TemplateConfig from "../../../../config/TemplateConfig";

const ColorIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.01012 18.0301L3.00012 14.0201C1.66012 12.6801 1.66012 11.3501 3.00012 10.0101L9.68012 3.33008L17.0301 10.6801C17.4001 11.0501 17.4001 11.6501 17.0301 12.0201L11.0101 18.0401C9.69012 19.3601 8.35012 19.3601 7.01012 18.0301Z" stroke={color} stroke-width="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.3501 3.00024L10.6901 4.34021" stroke={color} stroke-width="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.85 16.0002C18.85 16.0002 17 17.9747 17 19.183C17 20.1849 17.83 21.0002 18.85 21.0002C19.87 21.0002 20.7 20.1849 20.7 19.183C20.7 17.9747 18.85 16.0002 18.85 16.0002Z" stroke={color} stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.2943 10.1401L28.8802 13.5542C28.477 13.9574 27.8173 13.9574 27.4141 13.5542L24 10.1401" stroke={color} stroke-width="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 11.1401V10.6723C7 10.3766 7.19936 10.1401 7.44373 10.1401H10.5563C10.8028 10.1401 11 10.3792 11 10.6723V11.1401" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 14.1401V10.1401" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 14.1401H10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default ColorIcon;