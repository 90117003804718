import TemplateConfig from "../../../config/TemplateConfig";

const ArrowDownIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.47 10.74L12 14.26L15.53 10.74" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default ArrowDownIcon;