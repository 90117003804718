import { Card, CardContent, styled } from "@mui/material";
import { useEffect, useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import { PagesDataResponseImpl } from "../model/PagesDataResponse";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import { useNavigate } from "react-router-dom";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import ArrowUp from '../../../core/assets/module/configuration/arrow-up.svg';
import ArrowDown from '../../../core/assets/module/configuration/arrow-down.svg';
import ArrowRightIcon from '../../../core/assets/module/configuration/arrow-right.svg';
import EditIcon from '../../../core/assets/module/configuration/edit.svg';
import DeleteIcon from '../../../core/assets/module/configuration/delete.svg';
import AnalyticsIcon from '../../../core/assets/module/configuration/analytics.svg';
import OrderIcon from '../../../core/assets/module/configuration/orders.svg';
import DuplicateIcon from '../../../core/assets/module/configuration/duplicate.svg';
import ShareIcon from '../../../core/assets/module/configuration/share.svg';
import LinkIcon from '../../../core/assets/module/configuration/link.svg';
import SettingsIcon from '../../../core/assets/module/configuration/settings.svg';
import VerifyIcon from '../../../core/assets/module/configuration/verify.svg';
import AnalyticsBottomSheetComponent from "./AnalyticsBottomSheetComponent";
import OrdersBottomSheetComponent from "./OrdersBottomSheetComponent";
import NewBraendBottomSheetComponent from "./NewBraendBottomSheetComponent";
import { toast } from "react-toastify";
import UpdateNicknameService from "../service/UpdateNicknameService";
import PageTransferComponent from "./PageTransferComponent";
import PageDuplicateService from "../service/PageDuplicateService";
import PageSettingsComponent from "./PageSettingsComponent";
import SeoIcon from '../../../core/assets/module/customize/seo.svg';
import WebsiteSettingsService from "../../customize/service/WebsiteSettingsService";
import SeoEditComponent from "../../customize/ui/SeoEditComponent";
import WatermarkComponent from "./WatermarkComponent";
import customerDataStore from "../../authenticate/store/CustomerDataStore";

export default function PagesListElementComponent({ element, onDeletePage, isOpen = false, checkNotFreeAccount, freeAccount }: { element: PagesDataResponseImpl, onDeletePage: any; isOpen?: boolean; checkNotFreeAccount: any; freeAccount: boolean }) {
    // is open state
    useEffect(() => {
        if (isOpen) setExpanded(isOpen);
    }, [isOpen]);

    //init
    const navigate = useNavigate();
    const [isExpanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!isExpanded);
    };

    //delete
    const [deleteConfirmState, setDeleteConfirmState] = useState(false);
    async function deletePage() {
        setDeleteConfirmState(false);
        await onDeletePage({ element: element });
    }

    //analytics
    const [analyticsState, setAnalyticsState] = useState(false);

    //orders
    const [ordersState, setOrdersState] = useState(false);

    // transfer
    const [watermarkState, setWatermarkState] = useState(false);

    //nickname
    const [nicknameState, setNicknameState] = useState(false);
    async function onNicknameUpdate(value: string, domain: string) {
        if (element.nickname === value && element.domain === domain) return;
        if (value.length < 3) {
            toast("Никнейм должен содержать мин. 3 символа", { type: "error" });
            return;
        }

        await UpdateNicknameService({ pageId: element.page_id, nickname: value, domain: domain });
    }

    // transfer
    const [transferState, setTransferState] = useState(false);

    // duplicate
    const [duplicateState, setDuplicateState] = useState(false);
    async function duplicatePage() {
        setDuplicateState(false);
        await PageDuplicateService({ page: element });
    }

    //style
    const BraendHeaderWrapper = styled('div')({
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        overflow: 'hidden',
        alignItems: 'center',
    })

    //elements
    const elements = [
        {
            onClick: () => navigate(`${RouteNamespaces.customizeWithoutNickname}/${element.nickname}`),
            placeholder: "Редактировать",
            icon: EditIcon,
            alt: "edit icon"
        },
        {
            onClick: () => setAnalyticsState(true),
            placeholder: "Аналитика",
            icon: AnalyticsIcon,
            alt: "analytics icon"
        },
        {
            onClick: () => setOrdersState(true),
            placeholder: "Заявки",
            icon: OrderIcon,
            alt: "order icon",
            tip: element.client_orders_count
        },
        {
            onClick: () => setSettingsState(true),
            placeholder: "Настройки",
            icon: SettingsIcon,
            alt: "settings icon"
        }
    ]

    // seo
    const [seoWindowState, setSeoWindowState] = useState(false);
    async function onSaveSeo(favicon: File, title: string, description: string, keywords: string) {
        setSeoWindowState(false);
        await WebsiteSettingsService({
            pageId: element.page_id, type: 'seo', seo: {
                favicon: favicon,
                title: title,
                description: description,
                keywords: keywords
            }
        });
    }

    // settings
    const [settingsState, setSettingsState] = useState(false);
    const settingsElements = [
        {
            onClick: () => {
                setSettingsState(false);
                setSeoWindowState(true);
            },
            label: "SEO",
            icon: SeoIcon
        },
        {
            onClick: () => {
                if (freeAccount && customerDataStore.customerData?.customer.email !== 'zheke.site@gmail.com') {
                    checkNotFreeAccount();
                    return;
                }

                setSettingsState(false);
                setWatermarkState(true);
            },
            label: "Брендинг",
            icon: VerifyIcon
        },
        {
            onClick: () => {
                setSettingsState(false);
                setNicknameState(true);
            },
            label: "URL-адрес",
            icon: LinkIcon
        },
        {
            onClick: () => {
                setSettingsState(false);
                setDuplicateState(true);
            },
            label: "Дублировать",
            icon: DuplicateIcon
        },
        {
            onClick: () => {
                setSettingsState(false);
                setTransferState(true);
            },
            label: "Передать",
            icon: ShareIcon
        },
        {
            onClick: () => {
                setSettingsState(false);
                setDeleteConfirmState(true);
            },
            label: "Удалить",
            icon: DeleteIcon
        }
    ];

    return (
        <div>
            <Card variant="outlined" style={{ borderRadius: TemplateConfig.borderRadius, borderColor: TemplateConfig.greyBorderColor }}>
                <CardContent style={{ padding: 16 }}>
                    <BraendHeaderWrapper>
                        <SimpleTextComponent type='title'>{element.domain}/{element.nickname}</SimpleTextComponent>
                        <LinkWrapperComponent onClick={handleExpandClick}>
                            {
                                isExpanded
                                    ? <img src={ArrowUp} alt="arrow up icon" />
                                    : <img src={ArrowDown} alt="arrow down icon" />
                            }
                        </LinkWrapperComponent>
                    </BraendHeaderWrapper>
                    {isExpanded && (
                        elements.map((field, index) => (
                            <div key={index}>
                                <SimpleRetirementComponent size={10} />
                                <LinkWrapperComponent onClick={field.onClick}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img width={20} src={field.icon} style={{ paddingRight: 16 }} alt={field.alt} />
                                            <SimpleTextComponent type='default'>
                                                {field.placeholder}
                                            </SimpleTextComponent>
                                            {field.tip && <>
                                                <SimpleRetirementComponent size={8} />
                                                <div style={{
                                                    padding: "0px 4px",
                                                    backgroundColor: TemplateConfig.purpleColor,
                                                    borderRadius: 4,
                                                }}>
                                                    <SimpleTextComponent type='mediumSmallWhite'>
                                                        {field.tip}
                                                    </SimpleTextComponent>
                                                </div>
                                            </>}
                                        </div>
                                        <img width={20} src={ArrowRightIcon} alt="arrow left icon" />
                                    </div>
                                </LinkWrapperComponent>
                            </div>
                        ))
                    )}
                </CardContent>
            </Card>
            <SimpleRetirementComponent size={10} />
            <ConfirmBottomSheetComponent
                open={deleteConfirmState}
                onDismiss={() => setDeleteConfirmState(false)}
                onSubmit={deletePage}
                description="Вы собираетесь удалить веб-сайт."
                title="Удалить"
            />
            <AnalyticsBottomSheetComponent pageId={element.page_id} open={analyticsState} onDismiss={() => setAnalyticsState(false)} />
            <OrdersBottomSheetComponent pageId={element.page_id} open={ordersState} onDismiss={() => setOrdersState(false)} />
            <NewBraendBottomSheetComponent open={nicknameState} onDismiss={() => setNicknameState(false)} onSave={onNicknameUpdate} init={element.nickname} title="Изменить никнейм" initDomain={element.domain} />
            <PageTransferComponent open={transferState} onDismiss={() => setTransferState(false)} page={element} />
            <ConfirmBottomSheetComponent
                open={duplicateState}
                onDismiss={() => setDuplicateState(false)}
                onSubmit={duplicatePage}
                description="Вы собираетесь дублировать веб-сайт."
                title="Дублировать"
            />
            <PageSettingsComponent open={settingsState} onDismiss={() => setSettingsState(false)} elements={settingsElements} />
            <SeoEditComponent open={seoWindowState} onDismiss={() => setSeoWindowState(false)} updateValue={element.settings && JSON.parse(element.settings).seo ? JSON.parse(element.settings).seo : null} choosedPage={element} onSave={onSaveSeo} />
            <WatermarkComponent open={watermarkState} onDismiss={() => setWatermarkState(false)} init={element.watermark!} pageId={element.page_id} />
        </div>
    );
}