import { useRef } from "react";
import { toast } from "react-toastify";
import SimpleRetirementComponent from "../../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../../shared/ui/StandartBottomSheetComponent";
import StandartTextFieldWrapper from "../../../shared/ui/StandartTextFieldWrapper";

const FormCheckboxFieldComponent = ({ open, onDismiss, onSave }: { open: boolean; onDismiss: any; onSave: any }) => {
    // title
    const titleFieldRef = useRef('');
    const handleTitleFieldValueChange = (newValue: string) => { titleFieldRef.current = newValue; };

    // value
    const valueFieldRef = useRef('');
    const handleValueFieldValueChange = (newValue: string) => { valueFieldRef.current = newValue; };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Поле формы" description="Нужно ввести каждую опцию с новой строки" onSubmit={() => {
            if (!titleFieldRef.current || titleFieldRef.current.length === 0) {
                toast("Заполните название", { type: "error" });
                return;
            }

            if (!valueFieldRef.current || valueFieldRef.current.length === 0) {
                toast("Заполните опции", { type: "error" });
                return;
            }

            onSave(titleFieldRef.current, valueFieldRef.current);
            handleTitleFieldValueChange('');
            handleValueFieldValueChange('');
        }}>
            <StandartTextFieldWrapper
                placeholder="Введите название"
                handleTextFieldValueChange={handleTitleFieldValueChange}
                initText={titleFieldRef.current && titleFieldRef.current.length > 0 ? titleFieldRef.current : null}
            />
            <SimpleRetirementComponent size={10} />
            <StandartTextFieldWrapper
                rows="6"
                multiline
                maxLength={400}
                placeholder="Введите опции"
                handleTextFieldValueChange={handleValueFieldValueChange}
                initText={valueFieldRef.current && valueFieldRef.current.length > 0 ? valueFieldRef.current : null}
            />
        </StandartBottomSheetComponent>
    );
}

export default FormCheckboxFieldComponent;