import TemplateConfig from "../../../config/TemplateConfig";

const PauseIcon = ({ color = TemplateConfig.whiteColor }: { color?: string }) => {
    return (
        <svg width="38" height="38" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9702 2C6.45021 2 1.97021 6.48 1.97021 12C1.97021 17.52 6.45021 22 11.9702 22C17.4902 22 21.9702 17.52 21.9702 12C21.9702 6.48 17.5002 2 11.9702 2ZM10.7202 15.03C10.7202 15.51 10.5202 15.7 10.0102 15.7H8.71021C8.20021 15.7 8.00021 15.51 8.00021 15.03V8.97C8.00021 8.49 8.20021 8.3 8.71021 8.3H10.0002C10.5102 8.3 10.7102 8.49 10.7102 8.97V15.03H10.7202ZM16.0002 15.03C16.0002 15.51 15.8002 15.7 15.2902 15.7H14.0002C13.4902 15.7 13.2902 15.51 13.2902 15.03V8.97C13.2902 8.49 13.4902 8.3 14.0002 8.3H15.2902C15.8002 8.3 16.0002 8.49 16.0002 8.97V15.03Z" fill={color} />
        </svg>
    );
}

export default PauseIcon;