import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function SetTemplateService({ pageId, type }: { pageId: string; type: string }) {
    // check access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // init params
    const params = {
        "type": "page",
        "subType": "setTemplate",
        "accessToken": accessToken,
        "pageId": pageId,
        "templateType": type
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result || !result.data || !result.data.page) return;

    const newPage = {
        page_id: pageId,
        nickname: result.data.page.nickname,
        settings: result.data.page.settings,
        attributes: result.data.page.attributes,
        activities: [],
        client_orders_count: '0',
        domain: result.data.page.domain,
        watermark: result.data.page.watermark,
        font: result.data.page.font
    };

    // store
    pagesDataStore.updatePage(newPage);

    // notify
    toast("Успешно обновлено", { type: "success" });

    return newPage;
}