import { Activity, PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import { AppointmentValueResponse, Category } from "../model/AppointmentValueResponse";
import AppointmentUpdateService from "./AppointmentUpdateService";

export default async function AppointmentCategoryUpdateService({ action, choosedPage, category, index = 0, reload, appointmentValue }: { action: string; choosedPage: PagesDataResponseImpl; category: Category; index?: number; reload: any; appointmentValue?: AppointmentValueResponse }) {
    if (!appointmentValue) appointmentValue = { category: [], items: [], links: [] };

    if (action === 'insert') appointmentValue.category.unshift(category);

    if (action === 'delete') {
        var indexToRemove = appointmentValue.category.indexOf(category);
        if (indexToRemove !== -1) appointmentValue.category.splice(indexToRemove, 1);
        appointmentValue.links = appointmentValue.links.filter(item => item.category !== category.id);
    }

    if (action === 'update') appointmentValue.category[index] = category;

    const newAppointmentValue = JSON.stringify({
        category: appointmentValue.category,
        items: appointmentValue.items,
        links: appointmentValue.links
    });

    //request
    const result = await AppointmentUpdateService({ pageId: choosedPage.page_id, appointmentValue: newAppointmentValue });
    if (!result) return;

    pagesDataStore.insertActivites(
        choosedPage.page_id,
        {
            id: (result.data.activity as Activity).id,
            type: (result.data.activity as Activity).type,
            value: (result.data.activity as Activity).value
        }
    );

    reload(newAppointmentValue);
}