
import { IconButton, styled } from "@mui/material";
import { PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import { useNavigate } from "react-router-dom";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import ArrowDownIcon from '../../../core/assets/module/customize/arrow-down-black.svg';
import BackIcon from '../../../core/assets/module/customize/back.svg';
import ParamsIcon from '../../../core/assets/module/customize/params.svg';
import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import TemplateConfig from "../../../core/config/TemplateConfig";
import { useState } from "react";
import SettingsEditComponent from "./SettingsEditComponent";
import ParamsComponent from "./ParamsComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";

export default function CustomizeHeaderComponent({ choosedPage, reloadWeb }: { choosedPage: PagesDataResponseImpl; reloadWeb: any }) {
    // init
    const navigate = useNavigate();

    // style edit
    const [styleEditState, setStyleEditState] = useState(false);

    // params
    const [paramsState, setParamsState] = useState(false);

    // // reload
    // const [reloadState, setReloadState] = useState(false);
    // function reloadWeb(newPage?: PagesDataResponseImpl) {
    //     if (newPage) setChoosedPage(newPage);
    //     if (newPage) setItems(newPage.attributes)
    //     setReloadState(!reloadState);
    // }

    // style
    const TopBlock = styled('div')({
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        paddingLeft: TemplateConfig.longPadding,
        paddingRight: TemplateConfig.longPadding,
        paddingBottom: 20,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${TemplateConfig.greyBorderColor}`,
        height: 24
    });
    const BraendNicknameRow = styled('div')({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        paddingLeft: 30,
        paddingRight: 30,
    })

    return (
        <div>
            <SimpleRetirementComponent size={24} />
            <TopBlock>
                <LinkWrapperComponent onClick={() => navigate(RouteNamespaces.configuration)}>
                    <img src={BackIcon} alt="back icon" />
                </LinkWrapperComponent>
                <BraendNicknameRow>
                    <SimpleTextComponent type='titleBold'>
                        {choosedPage.domain}/{choosedPage.nickname}
                    </SimpleTextComponent>
                    <IconButton onClick={() => setParamsState(true)} >
                        <img src={ArrowDownIcon} alt="arrow down icon" />
                    </IconButton>
                </BraendNicknameRow>
                <LinkWrapperComponent onClick={() => setStyleEditState(true)}>
                    <img src={ParamsIcon} alt="params icon" />
                </LinkWrapperComponent>
            </TopBlock>
            <SettingsEditComponent reloadWeb={reloadWeb} choosedPage={choosedPage!} open={styleEditState} onDismiss={() => setStyleEditState(false)} navigate={navigate} />
            <ParamsComponent open={paramsState} onDismiss={() => setParamsState(false)} page={choosedPage} />
        </div>
    );
}