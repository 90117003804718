import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import pagesDataStore from "../store/PagesDataStore";

export default async function UpdateNicknameService({ pageId, nickname, domain }: { pageId: string; nickname: string; domain: string }) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return false;

    const params = {
        "type": "page",
        "subType": "updateNickname",
        "accessToken": accessToken,
        "pageId": pageId,
        "nickname": nickname,
        "domain": domain
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: "Никнейм занят"
        }
    );
    if (!result) return false;

    // success
    pagesDataStore.updateNickname(nickname, domain, pageId);
    toast("Никнейм успешно обновлен", { type: "success" });
    return true;
}