import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import analyticsDataStore from "../store/AnalyticsDataStore";

export default async function GetAnalyticsDataService(startDate: string, endDate: string, pageId: string) {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "type": "page",
        "subType": "getAnalytics",
        "accessToken": accessToken,
        "pageId": pageId,
        "startDate": startDate,
        "endDate": endDate,
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
        }
    );
    if (!result || !result.data) return;

    //save data
    const newData = {
        pageId: pageId,
        dateStart: startDate,
        dateEnd: endDate,
        analytics: result.data.analytics
    };
    analyticsDataStore.pushData(newData)
    return newData;
}