import { Attribute, PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import { AudioAttributeDao } from "../../leaf/model/AudioAttributeDao";
import { ButtonAttributeDao } from "../../leaf/model/ButtonAttributeDao";
import { DividerAttributeDao } from "../../leaf/model/DividerAttributeDao";
import { FormAttributeDao } from "../../leaf/model/FormAttributeDao";
import { ImageAttributeDao } from "../../leaf/model/ImageAttributeDao";
import { MenuAttributeDao } from "../../leaf/model/MenuAttributeDao";
import { PaddingDao } from "../../leaf/model/PaddingDao";
import { PlaceholderAttributeDao } from "../../leaf/model/PlaceholderAttributeDao";
import { PricelistAttributeDao } from "../../leaf/model/PricelistAttributeDao";
import { ProfileAttributeDao } from "../../leaf/model/ProfileAttributeDao";
import { QuestionAttributeDao } from "../../leaf/model/QuestionAttributeDao";
import { RowAttributeDao } from "../../leaf/model/RowAttributeDao";
import { SocialAttributeDao } from "../../leaf/model/SocialAttributeDao";
import { TimerAttributeDao } from "../../leaf/model/TimeAttributeDao";
import { ImageElement, ImageElementWithNullableElements } from "../model/ImageAttributeRequest";
import { MapAttributeResponse } from "../model/MapAttributeResponse";
import UpdateAttributeService from "../service/UpdateAttributeService";
import UpdateAudioAttributeService from "../service/UpdateAudioAttributeService";
import UpdateButtonAttributeService from "../service/UpdateButtonAttributeService";
import UpdateImageAttributeService from "../service/UpdateImageAttributeService";
import UpdateProfileAttributeService from "../service/UpdateProfileAttributeService";
import UpdateRowAttributeService from "../service/UpdateRowAttributeService";
import AudioBlockBuildBottomSheetComponent from "./attributes/AudioBlockBuilderBottomSheetComponent";
import ButtonBlockBuildBottomSheetComponent from "./attributes/ButtonBlockBuilderBottomSheetComponent";
import DividerBlockBuildBottomSheetComponent from "./attributes/DividerBlockBuilderBottomSheetComponent";
import FormBlockBuildBottomSheetComponent from "./attributes/FormBlockBuilderBottomSheetComponent";
import ImageBlockBuildBottomSheetComponent from "./attributes/ImageBlockBuilderBottomSheetComponent";
import MapBlockBuildBottomSheetComponent from "./attributes/MapBlockBuilderBottomSheetComponent";
import MenuBlockBuildBottomSheetComponent from "./attributes/MenuBlockBuilderBottomSheetComponent copy";
import PricelistBlockBuildBottomSheetComponent from "./attributes/PricelistBlockBuilderBottomSheetComponent";
import ProfilelockBuildBottomSheetComponent from "./attributes/ProfileBlockBuilderBottomSheetComponent";
import QuestionBlockBuildBottomSheetComponent from "./attributes/QuestionBlockBuilderBottomSheetComponent";
import RowBlockBuildBottomSheetComponent from "./attributes/RowBlockBuilderBottomSheetComponent";
import SocialBlockBuildBottomSheetComponent from "./attributes/SocialBlockBuilderBottomSheetComponent";
import TextBlockBuildBottomSheetComponent from "./attributes/TextBlockBuilderBottomSheetComponent";
import TimerlockBuildBottomSheetComponent from "./attributes/TimerBlockBuilderBottomSheetComponent";
import VideoBlockBuildBottomSheetComponent from "./attributes/VideoBlockBuilderBottomSheetComponent";

const UpdateAttributeComponent = ({ open, item, choosedPage, onDismiss, onUpdate }: { open: boolean; item: Attribute; choosedPage: PagesDataResponseImpl; onDismiss: any; onUpdate: any }) => {

    async function saveDirectlyLoading(type: string, value: string, padding: string) {
        await UpdateAttributeService({ pageId: choosedPage.page_id, item: item, attributeValue: value, attributePadding: padding });
        onUpdate();
    }

    async function saveProfile(title: string, description: string, avatar: File, background: File, color: string, padding: string, radius: string) {
        const profileValue = (JSON.parse(item.value!) as ProfileAttributeDao);

        const attributeValue = JSON.stringify({ "title": title, "description": description, avatar: profileValue.avatar, background: profileValue.background, "color": color, "radius": radius });
        await UpdateProfileAttributeService({ pageId: choosedPage.page_id, item: item, attributeValue: attributeValue, background: background, avatar: avatar, attributePadding: padding });

        onUpdate();
    }

    async function saveRow(title: string, value: string, image: File, padding: string, color: string, radius: string) {
        const rowValue = (JSON.parse(item.value!) as RowAttributeDao);

        const attributeValue = JSON.stringify({
            "title": title,
            "value": value,
            "color": color,
            "radius": radius,
            "image": rowValue.image
        });
        await UpdateRowAttributeService({ pageId: choosedPage.page_id, item: item, attributeValue: attributeValue, image: image, attributePadding: padding });

        onUpdate();
    }

    async function saveButton(placeholder: string, url: string, color: string, background: string, image: File | null, padding: string, radius: string) {
        const buttonValue = (JSON.parse(item.value!) as ButtonAttributeDao);

        const attributeValue = JSON.stringify({
            "placeholder": placeholder,
            "url": url,
            "color": color,
            "radius": radius,
            "background": background,
            "image": buttonValue.image
        });
        await UpdateButtonAttributeService({ pageId: choosedPage.page_id, item: item, attributeValue: attributeValue, image: image, attributePadding: padding });

        onUpdate();
    }

    async function saveDivider(color: string, image: File | null, padding: string) {
        const dividerValue = (JSON.parse(item.value!) as DividerAttributeDao);

        const attributeValue = JSON.stringify({
            "color": color,
            "image": dividerValue.image
        });
        await UpdateButtonAttributeService({ pageId: choosedPage.page_id, item: item, attributeValue: attributeValue, image: image, attributePadding: padding });

        onUpdate();
    }

    async function saveImage(images: ImageElementWithNullableElements[], type: string, padding: string, radius: string) {
        const imageArrayToLoad = [];
        for (var imageItem of images) {
            if (!imageItem.image) continue;
            imageArrayToLoad.push({ image: imageItem.image, url: imageItem.url ?? '' } as ImageElement)
        }

        await UpdateImageAttributeService({ pageId: choosedPage.page_id, item: item, imageAttributeRequest: { images: imageArrayToLoad }, attributePadding: padding, radius: radius });
        onUpdate();
    }

    async function saveAudio(color: string, audio: File | null, padding: string) {
        const attributeValue = JSON.stringify({
            "color": color,
        });
        await UpdateAudioAttributeService({ pageId: choosedPage.page_id, item: item, attributeValue: attributeValue, audio: audio, attributePadding: padding });

        onUpdate();
    }

    const updatePadding = item.padding && item.padding.length > 3 ? JSON.parse(item.padding) as PaddingDao : undefined;
    switch (item.type) {
        case 'text':
            const textValue = (JSON.parse(item.value!) as PlaceholderAttributeDao);
            return (
                <TextBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveDirectlyLoading={saveDirectlyLoading}
                    updateValue={textValue}
                    updatePadding={updatePadding}
                    font={choosedPage.font}
                />
            );
        case 'button':
            const buttonValue = (JSON.parse(item.value!) as ButtonAttributeDao);
            return (
                <ButtonBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveButton={saveButton}
                    updateValue={buttonValue}
                    choosedPage={choosedPage}
                    item={item}
                    updatePadding={updatePadding}
                />
            );
        case 'social':
            const socialValue = (JSON.parse(item.value!) as SocialAttributeDao);
            return (
                <SocialBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveDirectlyLoading={saveDirectlyLoading}
                    updateValue={socialValue}
                    updatePadding={updatePadding}
                />
            );
        case 'timer':
            const timerValue = (JSON.parse(item.value!) as TimerAttributeDao);
            return (
                <TimerlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveDirectlyLoading={saveDirectlyLoading}
                    updateValue={timerValue}
                    updatePadding={updatePadding}
                />
            )
        case 'form':
            const formValue = (JSON.parse(item.value!) as FormAttributeDao);
            return (
                <FormBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveDirectlyLoading={saveDirectlyLoading}
                    updateValue={formValue}
                    updatePadding={updatePadding}
                />
            )
        case 'map':
            const mapValue = (JSON.parse(item.value!) as MapAttributeResponse);
            return (
                <MapBlockBuildBottomSheetComponent
                    pageId={choosedPage.page_id}
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveDirectlyLoading={saveDirectlyLoading}
                    updateValue={mapValue}
                    updatePadding={updatePadding}
                />
            );
        case 'youtube':
            const youtubeValue = (JSON.parse(item.value!) as PlaceholderAttributeDao);
            return (
                <VideoBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveDirectlyLoading={saveDirectlyLoading}
                    updateValue={youtubeValue}
                    updatePadding={updatePadding}
                    item={item}
                />
            );
        // case 'appointment':
        //     const appointmentValue = (JSON.parse(item.value!) as AppointmentAttributeDao);
        //     return (
        //         <AppointmentBlockBuilderBottomSheetComponent
        //             choosedPage={choosedPage}
        //             open={open}
        //             onDismiss={() => onDismiss()}
        //             saveDirectlyLoading={saveDirectlyLoading}
        //             updateValue={appointmentValue}
        //             updatePadding={updatePadding}
        //         />
        //     );
        case 'profile':
            const profileValue = (JSON.parse(item.value!) as ProfileAttributeDao);
            return (
                <ProfilelockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveProfile={saveProfile}
                    updateValue={profileValue}
                    updatePadding={updatePadding}
                    choosedPage={choosedPage}
                    item={item}
                />
            );
        case 'image':
            const imageValue = (JSON.parse(item.value!) as ImageAttributeDao);
            return (
                <ImageBlockBuildBottomSheetComponent
                    type="image"
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveImage={saveImage}
                    updateValue={imageValue}
                    updatePadding={updatePadding}
                    choosedPage={choosedPage}
                    item={item}
                />
            );
        case 'slider':
            const sliderValue = (JSON.parse(item.value!) as ImageAttributeDao);
            return (
                <ImageBlockBuildBottomSheetComponent
                    type="slider"
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveImage={saveImage}
                    updateValue={sliderValue}
                    updatePadding={updatePadding}
                    choosedPage={choosedPage}
                    item={item}
                />
            );
        case 'question':
            const questionValue = (JSON.parse(item.value!) as QuestionAttributeDao);
            return (
                <QuestionBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    updateValue={questionValue}
                    updatePadding={updatePadding}
                    saveDirectlyLoading={saveDirectlyLoading}
                />
            );
        case 'row':
            const rowValue = (JSON.parse(item.value!) as RowAttributeDao);
            return (
                <RowBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveRow={saveRow}
                    updateValue={rowValue}
                    updatePadding={updatePadding}
                    choosedPage={choosedPage}
                    item={item}
                />
            );
        case 'pricelist':
            const pricelistValue = (JSON.parse(item.value!) as PricelistAttributeDao);
            return (
                <PricelistBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    updateValue={pricelistValue}
                    updatePadding={updatePadding}
                    saveDirectlyLoading={saveDirectlyLoading}
                />
            );
        case 'menu':
            const menuValue = (JSON.parse(item.value!) as MenuAttributeDao);
            return (
                <MenuBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    updateValue={menuValue}
                    updatePadding={updatePadding}
                    saveDirectlyLoading={saveDirectlyLoading}
                />
            );
        case 'audio':
            const audioValue = (JSON.parse(item.value!) as AudioAttributeDao);
            return (
                <AudioBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveAudio={saveAudio}
                    updateValue={audioValue}
                    updatePadding={updatePadding}
                    choosedPage={choosedPage}
                    item={item}
                />
            );
        case 'banner':
            const bannerValue = (JSON.parse(item.value!) as ImageAttributeDao);
            return (
                <ImageBlockBuildBottomSheetComponent
                    type="banner"
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveImage={saveImage}
                    updateValue={bannerValue}
                    updatePadding={updatePadding}
                    choosedPage={choosedPage}
                    item={item}
                />
            );
        default:
            const dividerValue = (JSON.parse(item.value!) as DividerAttributeDao);
            return (
                <DividerBlockBuildBottomSheetComponent
                    open={open}
                    onDismiss={() => onDismiss()}
                    saveDivider={saveDivider}
                    updateValue={dividerValue}
                    updatePadding={updatePadding}
                    choosedPage={choosedPage}
                    item={item}
                />
            );
    }
}

export default UpdateAttributeComponent;