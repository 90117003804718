import QrSvg from "@wojtekmaj/react-qr-svg";
import { PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const PageQRCodeComponent = ({ open, onDismiss, choosedPage }: { open: boolean; onDismiss: any; choosedPage: PagesDataResponseImpl; }) => {
    const downloadQRCode = () => {
        const svgElement = document.querySelector('.svgplaceholder svg');

        if (svgElement) {
            const svgCode = new XMLSerializer().serializeToString(svgElement);
            const blob = new Blob([svgCode], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = url;
            tempLink.setAttribute('download', 'qrcode.svg');
            tempLink.click();
        }
    };

    return (
        <StandartBottomSheetComponent
            open={open}
            onDismiss={onDismiss}
            title="QR Код"
        >
            <div
                className="svgplaceholder"
                style={{
                    textAlign: 'center'
                }}
            >
                <QrSvg
                    width='50%'
                    value={`https://${choosedPage.domain}/${choosedPage.nickname}`}
                />
            </div>
            <SimpleRetirementComponent />
            <RaisedButtonComponent
                placeholder="Скачать QR-код"
                onClick={downloadQRCode}
            />
        </StandartBottomSheetComponent>
    );
}

export default PageQRCodeComponent;
