import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { OrderItem } from "../model/OrdersDataResponse";
import OrderAddIcon from '../../../core/assets/module/configuration/order-add.svg';
import OrderDeleteIcon from '../../../core/assets/module/configuration/order-delete.svg';
import OrderTickIcon from '../../../core/assets/module/configuration/order-tick.svg';
import OrderMinusIcon from '../../../core/assets/module/configuration/order-minus.svg';
import SharedListComponent from "../../shared/ui/SharedListComponent";


const OrdersModerateComponent = ({ open, onDismiss, onChangeStatus, order, tabs }: { open: boolean; onDismiss: any; onChangeStatus: any; order?: OrderItem; tabs: string[] }) => {
    const orderIcons = [OrderMinusIcon, OrderAddIcon, OrderTickIcon, OrderDeleteIcon];
    const items = [];
    for (let i = 0; i < tabs.length; i++) {
        items.push({ label: tabs[i], icon: orderIcons[i], onClick: () => onChangeStatus(order, tabs[i]) });
    }

    // init
    return (
        <StandartBottomSheetComponent title="Статус" open={open} onDismiss={onDismiss}>
            <SharedListComponent items={items} />
        </StandartBottomSheetComponent>
    )
}

export default OrdersModerateComponent;