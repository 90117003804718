import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import { Attribute } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import loaderStore from "../../shared/store/LoaderStore";

export default async function UpdateProfileAttributeService({ pageId, item, attributeValue, background, avatar, attributePadding }: { pageId: string; item: Attribute; attributeValue: string; background: File | null, avatar: File | null; attributePadding: string }) {
    //check access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    //open loader
    loaderStore.showStore();

    // create images
    const compressedAvatar = avatar ? await imageCompression(avatar, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }) : null;
    const compressedBackground = background ? await imageCompression(background, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }) : null;

    // create form
    const formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('pageId', pageId);
    formData.append('attributePadding', attributePadding);
    formData.append('attributeValue', attributeValue);
    formData.append('attributeType', item.type);
    formData.append('attributeId', item.id);
    if (compressedAvatar) formData.append('avatar', compressedAvatar, compressedAvatar.name);
    if (compressedBackground) formData.append('background', compressedBackground, compressedBackground.name);

    //close loader
    loaderStore.hideStore();

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendAttributeUpdatePath,
            type: "post",
            formData: formData
        }
    );
    if (!result) return;

    // store
    pagesDataStore.updateAttribute(
        {
            id: item.id,
            mark: item.mark,
            type: item.type,
            padding: attributePadding,
            value: result.data.attributeValue
        },
        pageId
    );

    // notify
    toast("Блок успешно обновлен", { type: "success" });
}