import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import { Attribute } from "../../leaf/model/PublicDataResponse";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function CloneAttributeService({ attributeId, pageId }: { attributeId: string; pageId: string }) {
    // params
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;
    const params = {
        "type": "page",
        "subType": "cloneAttribute",
        "accessToken": accessToken,
        "pageId": pageId,
        "attributeId": attributeId,
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: "Не удалось дублировать"
        }
    );
    if (!result || !result.data || !result.data.attribute) return;

    // success
    pagesDataStore.insertAttribute(result.data.attribute as Attribute, pageId);
    toast("Блок успешно дублирован", { type: "success" });
}