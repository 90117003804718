import axios from "axios";
import { toast } from "react-toastify";
import loaderStore from "../store/LoaderStore";

export default async function GlobalRequestTemplate({ type = 'get', link, params, formData, loader = true, errorText }: { type?: string; link: string; params?: any; formData?: FormData; loader?: boolean; errorText?: string }) {
    if (loader) loaderStore.showStore();
    try {
        const response = type === 'get' ? await axios.get(
            link,
            {
                params: params,
                headers: {
                    Accept: 'application/json',
                },
            },
        ) : await axios.post(
            link,
            formData,
            {
                headers: {
                    Accept: 'multipart/form-data',
                },
            },
        );

        if (response.status === 200 && response.data.status === 'succes') {
            if (loader) loaderStore.hideStore();
            return response.data;
        }

        if (response.status === 200) {
            if (errorText) toast(errorText, { type: "error" });
            else {
                switch (response.data.data.message) {
                    case 'params':
                        toast("Неправильные параметры", { type: "error" });
                        break;
                    case 'access':
                        toast("Вы вышли из аккаунта. Войдите заново", { type: "error" });
                        break;
                    case 'exists':
                        toast("Запись существует", { type: "error" });
                        break;
                    case 'error':
                        toast("Неизвестная ошибка", { type: "error" });
                        break;
                    case 'limit':
                        toast("Превышен лимит тарифа", { type: "error" });
                        break;
                    case 'interval':
                        toast("Нет свободных окошек", { type: "error" });
                        break;
                }
            }
            if (loader) loaderStore.hideStore();
            return null;
        }

        toast("Неуспешный запрос", {
            type: "error"
        });
        if (loader) loaderStore.hideStore();
        return null;
    } catch (error) {
        toast("Неуспешный запрос", {
            type: "error"
        });
        if (loader) loaderStore.hideStore();
        return null;
    }
}