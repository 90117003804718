import { styled } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";

export default function SimpleTextComponent({ children, uppercase = false, multiline = false, lines = 3, type = "default", lineThrough = false, anotherColor, stronglyInter }: { children: any; uppercase?: boolean; multiline?: boolean; lines?: number; type?: string; lineThrough?: boolean, anotherColor?: string; stronglyInter?: boolean }) {

    //init
    let size = 0;
    let weight = 0;
    let color = TemplateConfig.blackColor;

    //get style
    switch (type) {
        case 'header':
            size = TemplateConfig.largeFontSize;
            weight = TemplateConfig.heavyText;
            break;
        case 'semiboldHeader':
            size = TemplateConfig.largeFontSize;
            weight = TemplateConfig.semiboldText;
            break;
        case 'whiteHeader':
            size = TemplateConfig.largeFontSize;
            weight = TemplateConfig.heavyText;
            color = TemplateConfig.whiteColor;
            break;
        case 'heavyWhiteTitle':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.heavyText;
            color = TemplateConfig.whiteColor;
            break;
        case 'titleBold':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.heavyText;
            break;
        case 'title':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.semiboldText;
            break;
        case 'whiteTitle':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.semiboldText;
            color = TemplateConfig.whiteColor;
            break;
        case 'lightTitle':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.regularText;
            break;
        case 'lightTitleWithColorBlackOpacity':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.regularText;
            color = TemplateConfig.blackColorWithOpacity;
            break;
        case 'hardMedium':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.mediumText;
            break;
        case 'hardMediumPurple':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.mediumText;
            color = TemplateConfig.purpleColor
            break;
        case 'hardMediumWhite':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.mediumText;
            color = TemplateConfig.whiteColor;
            break;
        case 'hardMediumLink':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.mediumText;
            color = TemplateConfig.greyBorderColor;
            break;
        case 'default':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.lightweightText;
            break;
        case 'whiteDefault':
            size = TemplateConfig.mediumFontSize;
            weight = TemplateConfig.lightweightText;
            color = TemplateConfig.whiteColor;
            break;
        case 'mediumSmallWhite':
            size = TemplateConfig.smallFontSize;
            weight = TemplateConfig.mediumText;
            color = TemplateConfig.whiteColor
            break;
        case 'boldParagraph':
            size = TemplateConfig.smallFontSize;
            weight = TemplateConfig.semiboldText;
            break;
        case 'regularParagraph':
            size = TemplateConfig.smallFontSize;
            weight = TemplateConfig.regularText;
            break;
        case 'paragraph':
            size = TemplateConfig.smallFontSize;
            weight = TemplateConfig.lightweightText;
            break;
    }

    //init div
    const TextBlockWithRowLimit = styled('div')({
        display: "-webkit-box",
        lineClamp: lines,
        WebkitLineClamp: lines,
        WebkitBoxOrient: "vertical",
        fontSize: size,
        fontWeight: weight,
        color: anotherColor ?? color,
        textTransform: uppercase ? "uppercase" : "none",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        textDecoration: lineThrough ? 'line-through' : 'none',
        fontFamily: stronglyInter ? "'Inter', sana-serif !important" : undefined
    });
    const DefaultTextBlock = styled('div')({
        fontSize: size,
        fontWeight: weight,
        color: anotherColor ?? color,
        textTransform: uppercase ? "uppercase" : "none",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        textDecoration: lineThrough ? 'line-through' : 'none',
        fontFamily: stronglyInter ? "'Inter', sana-serif !important" : undefined
    });

    //widget
    return (
        multiline
            ? <TextBlockWithRowLimit>{children}</TextBlockWithRowLimit>
            : <DefaultTextBlock>{children}</DefaultTextBlock>
    );
}