import imageCompression from "browser-image-compression";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import { Activity, PagesDataResponseImpl } from "../../configuration/model/PagesDataResponse";
import pagesDataStore from "../../configuration/store/PagesDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import loaderStore from "../../shared/store/LoaderStore";
import { AppointmentValueResponse, Item } from "../model/AppointmentValueResponse";

export default async function AppointmentItemsUpdateService({ action, choosedPage, item, reload, activeCategoryId, handleActiveIdsChange, images }: { action: string; choosedPage: PagesDataResponseImpl; item: Item; reload: any; activeCategoryId: string; handleActiveIdsChange: any; images: (File | null)[] }) {
    //request
    const result = await request(
        choosedPage.page_id,
        action,
        JSON.stringify(item),
        images,
        activeCategoryId
    );
    if (!result) return;

    pagesDataStore.insertActivites(
        choosedPage.page_id,
        {
            id: (result.data.activity as Activity).id,
            type: (result.data.activity as Activity).type,
            value: (result.data.activity as Activity).value
        }
    );
    const appointmentValue = JSON.parse(result.data.activity.value) as AppointmentValueResponse;

    const newAppointmentValue = JSON.stringify({
        category: appointmentValue.category,
        items: appointmentValue.items,
        links: appointmentValue.links
    });

    if (action === 'insert') handleActiveIdsChange(item.id);
    reload(newAppointmentValue);
}

async function request(pageId: string, operation: string, activityItemValue: string, images: (File | null)[], activeCategoryId: string) {
    // auth check
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // open loader
    loaderStore.showStore();

    // compress all images
    const compressedImages = [];
    for (var imageElement of images) {
        if (imageElement)
            compressedImages.push(await imageCompression(imageElement, {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }));
    }

    //create form data
    const formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('pageId', pageId);
    formData.append('operation', operation);
    formData.append('activeCategoryId', activeCategoryId);

    //add images to form data
    var counter = 1;
    const imagesToLoadJson = [];
    for (var compressedImageElement of compressedImages) {
        const imageName = `img${counter}`;
        formData.append(imageName, compressedImageElement, compressedImageElement.name);
        imagesToLoadJson.push(imageName);
        counter++;
    }
    const activityItemValueDecoded = JSON.parse(activityItemValue);
    activityItemValueDecoded.images = imagesToLoadJson;
    formData.append('activityItemValue', JSON.stringify(activityItemValueDecoded));

    //close loader
    loaderStore.hideStore();

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendActivityUpdateItemPath,
            type: "post",
            formData: formData
        }
    );
    return result;
}