import { useEffect, useState } from "react";
import LinkWrapperComponent from "../../../shared/ui/LinkWrapperComponent";
import SimpleTextComponent from "../../../shared/ui/SimpleTextComponent";
import RadiusIcon from '../../../../core/assets/module/attribute/radius.svg';
import RadiusEditComponent from "./RadiusEditComponent";

const RadiusComponent = ({ init, onSave }: { init?: string; onSave: any }) => {
    // init
    useEffect(() => {
        onSave(init ?? "16");
        // eslint-disable-next-line 
    }, [init]);

    // edit
    const [inputWindow, setInputWindow] = useState(false);

    return (
        <div>
            <LinkWrapperComponent onClick={() => setInputWindow(true)}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={RadiusIcon} alt="radius icon" width={20} />
                    <SimpleTextComponent type='paragraph'>
                        Радиус
                    </SimpleTextComponent>
                </div>
            </LinkWrapperComponent>
            <RadiusEditComponent open={inputWindow} onDismiss={() => setInputWindow(false)} onSave={onSave} init={init} />
        </div>
    );
}

export default RadiusComponent;