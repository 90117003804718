import { toast } from "react-toastify";

export default function UrlLauncherUtil({ url, newTab = false }: { url: string, newTab?: boolean }) {
    try {
        const fullURL = /^(https?:\/\/)/i.test(url) ? url : `https://${url}`;
        const windowName = newTab ? '_blank' : '_self';
        window.open(fullURL, windowName, "noopener noreferrer");
    } catch (error) {
        toast("Не удалось открыть ссылку", {
            type: "error"
        });
    }
}