import { useEffect, useRef, useState } from 'react';
import ApplicationConfig from '../../../../core/config/ApplicationConfig';
import SimpleRetirementComponent from '../../../shared/ui/SimpleRetirementComponent';
import StandartBottomSheetComponent from '../../../shared/ui/StandartBottomSheetComponent';
import CustomMuiFileInputComponent from '../../../shared/ui/CustomMuiFileInputComponent';
import StandartTextFieldWrapper from '../../../shared/ui/StandartTextFieldWrapper';
import { ProfileAttributeDao } from '../../../leaf/model/ProfileAttributeDao';
import ParseFileUtil from '../../../../core/utils/ParseFileUtil';
import { Attribute, PagesDataResponseImpl } from '../../../configuration/model/PagesDataResponse';
import ColorPickerButtonComponent from '../ColorPickerButtonComponent';
import { PaddingDao } from '../../../leaf/model/PaddingDao';
import PaddingComponent from './PaddingComponent';
import RadiusComponent from './RadiusComponent';

export default function ProfilelockBuildBottomSheetComponent({ open, onDismiss, saveProfile, updateValue, item, choosedPage, updatePadding }: { open: boolean; onDismiss: any; saveProfile: any; updateValue?: ProfileAttributeDao; item?: Attribute; choosedPage?: PagesDataResponseImpl; updatePadding?: PaddingDao }) {
    // update value
    useEffect(() => {
        async function fetchData() {
            if (updateValue) {
                const imageLink = `${ApplicationConfig.thisProductBackendImagesPath}${choosedPage?.page_id}/${item?.id}/`;
                setAvatar(updateValue.avatar && updateValue.avatar.length > 1 ? await ParseFileUtil(`${imageLink}${updateValue.avatar}`) : null);
                setBackground(updateValue.background && updateValue.background.length > 1 ? await ParseFileUtil(`${imageLink}${updateValue.background}`) : null);
                handleTitleTextFieldValueChange(updateValue.title ?? '');
                handleDescriptionTextFieldValueChange(updateValue.description ?? '');
                setProfileColor(updateValue.color ?? '#ffffff');
            } else {
                setAvatar(null);
                setBackground(null);
                handleTitleTextFieldValueChange('');
                handleDescriptionTextFieldValueChange('');
                setProfileColor('#ffffff');
            }
        }

        fetchData();
    }, [updateValue, choosedPage, item]);

    // reset
    function reset() {
        setAvatar(null);
        setBackground(null);
        handleTitleTextFieldValueChange('');
        handleDescriptionTextFieldValueChange('');
        setProfileColor('#ffffff');
    }

    // count
    const number = [1, 1];

    // color
    const [profileColor, setProfileColor] = useState('#ffffff');

    // radius
    const radiusField = useRef('');
    const onRadiusChange = (v: string) => { radiusField.current = v };

    // image
    const [avatar, setAvatar] = useState<File | null>(null);
    const [background, setBackground] = useState<File | null>(null);
    const handleImage = (file: File | null, avatar: boolean) => {
        if (avatar) setAvatar(file);
        else setBackground(file);
    };
    const handleAvatar = (file: File | null, inputKey: string) => handleImage(file, true);
    const handleBackground = (file: File | null, inputKey: string) => handleImage(file, false);

    // text
    // init title text field
    const titleTextFieldRef = useRef('');
    const handleTitleTextFieldValueChange = (newValue: string) => { titleTextFieldRef.current = newValue; };
    // init description text field
    const descriptionTextFieldRef = useRef('');
    const handleDescriptionTextFieldValueChange = (newValue: string) => { descriptionTextFieldRef.current = newValue; };

    // padding
    const paddingField = useRef('');
    const onPaddingChange = (v: string) => { paddingField.current = v };

    return (
        <StandartBottomSheetComponent
            open={open}
            onDismiss={onDismiss}
            onSubmit={() => {
                async function loadSave() {
                    await saveProfile(titleTextFieldRef.current ?? '', descriptionTextFieldRef.current ?? '', avatar, background, profileColor, paddingField.current, radiusField.current);
                    reset();
                }

                loadSave();
            }}
            title={'Профиль'}>
            {
                number.map((item, index) => (
                    <div key={index}>
                        <StandartTextFieldWrapper
                            placeholder={index === 0 ? "Введите заголовок" : "Введите подпись"}
                            handleTextFieldValueChange={index === 0 ? handleTitleTextFieldValueChange : handleDescriptionTextFieldValueChange}
                            maxLength={index === 0 ? 50 : 200}
                            initText={
                                index === 0
                                    ? titleTextFieldRef.current && titleTextFieldRef.current.length > 0
                                        ? titleTextFieldRef.current
                                        : null
                                    : descriptionTextFieldRef.current && descriptionTextFieldRef.current.length > 0
                                        ? descriptionTextFieldRef.current
                                        : null
                            }
                        />
                        {
                            index === 1
                                ? null
                                : <SimpleRetirementComponent size={10} />
                        }
                    </div>
                ))
            }
            <SimpleRetirementComponent size={10} />
            {
                number.map((item, index) => (
                    <div key={index}>
                        <CustomMuiFileInputComponent
                            value={index === 0 ? avatar : background}
                            placeholder={index === 0 ? "Загрузите фото" : "Загрузите обложку"}
                            onChange={index === 0 ? handleAvatar : handleBackground}
                            aspectRatio={index === 0 ? 1 : 16 / 9}
                            inputKey={index.toString()}
                            initValue={index === 0 ? avatar && avatar.name.length > 0 ? avatar.name : '' : background ? background.name : ''}
                        />

                        {
                            index === 1
                                ? null
                                : <SimpleRetirementComponent size={10} />
                        }
                    </div>
                ))
            }
            <SimpleRetirementComponent size={10} />
            <SimpleRetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ColorPickerButtonComponent text="Цвет" color={profileColor} onSave={setProfileColor} />
                <PaddingComponent init={updatePadding} onSave={onPaddingChange} />
                <RadiusComponent init={item ? (JSON.parse(item.value!) as ProfileAttributeDao).radius ?? undefined : undefined} onSave={onRadiusChange} />
            </div>
        </StandartBottomSheetComponent>
    );
};